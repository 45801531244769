import React from 'react';
import { Button, Row, Col, Input, message } from 'antd';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import apiClient from 'utils/feathersClient';

import { ValidationErrorsObject } from 'types/global';
import FieldWithValidation from 'components/FieldWithValidation';

const Security = () => {
  const [t] = useTranslation();

  const changePassword = async (values: {
    oldPassword: string;
    newPassword: string;
    repeatPassword: string;
  }) => {
    try {
      await apiClient.service('new-password').create(values, { query: { $setByOld: true } });
      message.success('Successfully updated password', 2);
    } catch (e) {
      message.error(
        <div>
          <h3>Error while uploading image</h3>
          <span>{e.message}</span>
        </div>,
        4
      );
    }
  };

  return (
    <Form
      onSubmit={changePassword}
      validate={validate}
      render={({ handleSubmit }) => (
        <div className="settings-form">
          <div className="settings-form__head">
            <h1 className="settings-form__title">{t('security')}</h1>
            <Button type="primary" onClick={handleSubmit}>
              Change
            </Button>
          </div>

          <div className="settings-form__card">
            <Row gutter={24} className="settings-form__row">
              <Col span={12} className="settings-form__item-name">
                <p>{t('lastPassword')}</p>
              </Col>
              <Col span={12} className="settings-form__item-name">
                <Field name="oldPassword">
                  {({ input, meta }) => (
                    <FieldWithValidation meta={meta} errorText={t(meta.error)}>
                      <Input {...input} type="password" />
                    </FieldWithValidation>
                  )}
                </Field>
              </Col>
            </Row>
            <div className="settings-form__group">
              <Row gutter={24} className="settings-form__row">
                <Col span={12} className="settings-form__item-name">
                  <p>{t('newPassword')}</p>
                </Col>
                <Col span={12} className="settings-form__item-name">
                  <Field name="newPassword">
                    {({ input, meta }) => (
                      <FieldWithValidation meta={meta} errorText={t(meta.error)}>
                        <Input {...input} type="password" />
                      </FieldWithValidation>
                    )}
                  </Field>
                </Col>
              </Row>
              <Row gutter={24} className="settings-form__row">
                <Col span={12} className="settings-form__item-name">
                  <p>{t('repeat')}</p>
                </Col>
                <Col span={12} className="settings-form__item-name">
                  <Field name="repeatPassword">
                    {({ input, meta }) => (
                      <FieldWithValidation meta={meta} errorText={t(meta.error)}>
                        <Input {...input} type="password" />
                      </FieldWithValidation>
                    )}
                  </Field>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )}
    />
  );
};

type FormState = {
  oldPassword: string;
  newPassword: string;
  repeatPassword: string;
};

const validate = (values: Partial<FormState>) => {
  const errors: ValidationErrorsObject<FormState> = {};

  if (!values.oldPassword) {
    errors.oldPassword = 'Required';
  }
  if (!values.newPassword) {
    errors.newPassword = 'Required';
  }
  if (!values.repeatPassword) {
    errors.repeatPassword = 'Required';
  }
  if (values.newPassword && values.newPassword !== values.repeatPassword) {
    errors.repeatPassword = 'Your password and confirmation password do not match';
  }

  return errors;
};

export default Security;
