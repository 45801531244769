import { combineReducers } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TasksItem } from 'types/global';

const tasksSlice = createSlice({
  name: 'allTasks',
  initialState: [] as TasksItem[],
  reducers: {
    setTasks: (state, { payload }: PayloadAction<TasksItem[]>) => {
      return payload;
    },
    updateTask: (state, { payload }: PayloadAction<TasksItem>) => {
      return state.map(task => {
        if (task._id === payload._id) {
          return payload;
        }
        return task;
      });
    },
    addTask: (state, { payload }: PayloadAction<TasksItem>) => {
      state.push(payload);
    },
    deleteTask: (state, { payload }: PayloadAction<string>) => {
      return state.filter(task => task._id !== payload);
    },
  },
});

const holdedTasksSlice = createSlice({
  name: 'holdedTasks',
  initialState: [] as TasksItem[],
  reducers: {
    setHoldedTasks: (state, { payload }: PayloadAction<TasksItem[]>) => {
      return payload;
    },
    updateHoldedTask: (state, { payload }: PayloadAction<TasksItem>) => {
      return state.map(task => {
        if (task._id === payload._id) {
          return payload;
        }
        return task;
      });
    },
    addHoldedTask: (state, { payload }: PayloadAction<TasksItem>) => {
      state.push(payload);
    },
    deleteHoldedTask: (state, { payload }: PayloadAction<string>) => {
      return state.filter(task => task._id !== payload);
    },
  },
});

export const { setTasks, updateTask, addTask, deleteTask } = tasksSlice.actions;
export const {
  setHoldedTasks,
  updateHoldedTask,
  addHoldedTask,
  deleteHoldedTask,
} = holdedTasksSlice.actions;

export default combineReducers({ allTasks: tasksSlice.reducer, holdedTasks: holdedTasksSlice.reducer });
