import React from 'react';

const Invoicing = () => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.liqpay.ua/api/3/checkout?data=eyJ2ZXJzaW9uIjozLCJhY3Rpb24iOiJwYXlkb25hdGUiLCJwdWJsaWNfa2V5IjoiaTMzNDI3OTAxNzY4IiwiYW1vdW50IjoiNSIsImN1cnJlbmN5IjoiVVNEIiwiZGVzY3JpcHRpb24iOiLQnNC%2B0Lkg0YLQvtCy0LDRgCIsInR5cGUiOiJkb25hdGUiLCJsYW5ndWFnZSI6ImVuIn0%3D&signature=q2Z88IMKdRDnJTHQ6WouiipxEbI%3D"
    >
      Donate
    </a>
  );
};

export default Invoicing;
