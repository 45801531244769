import React from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';
import { useTypedDispatch, useTypedSelector } from 'store';
import clsx from 'clsx';

import Tags from './Tags';
import HoldedTasks from './HoldedTasks';

import { setIsSidebarCollapsed } from 'store/core';

import { ReactComponent as CaretUpIcon } from 'assets/icons/caret-up.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';

const SideBar = () => {
  const [t] = useTranslation();
  const dispatch = useTypedDispatch();

  const isSidebarCollapsed = useTypedSelector(state => state.core.isSidebarCollapsed);

  return (
    <div className={clsx('sidebar', { 'sidebar--collapsed': isSidebarCollapsed })}>
      <div
        className={clsx('sidebar__expander', { 'sidebar__expander--visible': isSidebarCollapsed })}
        onClick={() => dispatch(setIsSidebarCollapsed(!isSidebarCollapsed))}
      >
        <div className="sidebar__expander-wrapper">
          <ArrowLeftIcon
            className="sidebar__expander-icon"
            style={{
              transform: `rotate(${isSidebarCollapsed ? 180 : 0}deg)`,
              marginRight: isSidebarCollapsed ? '-2px' : '2px',
              transition: '0.2s',
            }}
          />
        </div>
      </div>

      <div className="sidebar__body">
        <Collapse
          ghost
          expandIconPosition="right"
          defaultActiveKey="2"
          expandIcon={({ isActive }) => (
            <CaretUpIcon style={{ transform: `rotate(${isActive ? 0 : 180}deg)`, transition: '0.2s' }} />
          )}
        >
          {/*           <Collapse.Panel header={t('projects')} key="1" className="sidebar__collapse" forceRender>
            <Tags />
          </Collapse.Panel> */}
          <Collapse.Panel header={t('backlog')} collapsible={'disabled'} key="2" className="sidebar__collapse" forceRender>
            <HoldedTasks />
          </Collapse.Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default SideBar;
