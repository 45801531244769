import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useTypedSelector } from 'store';

import { TIMER_SECONDS } from 'utils';

import { getIsMobileScreen } from 'store/core/selectors';

const AppHelmet = () => {
  const timer = useTypedSelector(state => state.timer);
  const isMobileScreen = useTypedSelector(state => getIsMobileScreen(state));

  useEffect(() => {
    if (!isMobileScreen) {
      if (timer.isStarted) {
        const favicon = document.querySelector<HTMLLinkElement>('[rel="icon"]');
        const faviconSize = 16;

        const canvas = document.createElement('canvas');
        canvas.width = faviconSize;
        canvas.height = faviconSize;

        const context = canvas.getContext('2d');

        // context.beginPath();

        const x = 8;
        const y = 8;
        const startAngle = (Math.PI * 3) / 2;
        const endAngle =
          ((TIMER_SECONDS - timer.timeInSeconds) / TIMER_SECONDS) * 2 * Math.PI + startAngle + 0.1;
        context.lineWidth = 1;
        context.strokeStyle = '#99a8b1';

        // full circle
        context.arc(x, y, 7, 0, 2 * Math.PI);
        context.stroke();

        // filled arc
        context.beginPath();
        context.moveTo(x, y);
        context.arc(x, y, 8, startAngle, endAngle);
        context.closePath();
        context.fillStyle = '#69c262';
        context.fill();

        // Replace favicon
        favicon.href = canvas.toDataURL('image/png');
      } else {
        const favicon = document.querySelector<HTMLLinkElement>('[rel="icon"]');
        favicon.href = require('assets/img/favicon.ico');
      }
    }
  }, [timer, isMobileScreen]);

  return (
    <Helmet>
      <link rel="icon" href={require('assets/img/favicon.ico')} />
    </Helmet>
  );
};

export default AppHelmet;
