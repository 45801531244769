import React from 'react';
import { Avatar, Dropdown, Menu, message } from 'antd';
import { useTypedDispatch, useTypedSelector } from 'store';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { logOut } from 'store/user/thunkActions';

import { MenuInfo } from 'rc-menu/lib/interface';

const UserAvatar = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useTypedDispatch();

  const user = useTypedSelector(state => state.authUser.user);

  const onLogout = async () => {
    try {
      await dispatch(logOut());
    } catch (e) {
      message.error(
        <div>
          <h3>Error while logging out</h3>
          <p>{e.message}</p>
        </div>,
        4
      );
    }
  };

  const onItemClick = (event: MenuInfo) => {
    switch (event.key) {
      case 'settings':
        history.push('/settings/account-details');
        break;
      case 'projects':
        history.push('/settings/projects');
        break;
      case 'goals':
        history.push('/settings/goals');
        break;
      case 'logout':
        onLogout();
        break;
      default:
        break;
    }
  };

  const overlay = (
    <div className="user-avatar">
      <div className="user-avatar__body">
        <Menu onClick={onItemClick}>
          <Menu.Item key="settings" className="user-avatar__item">
            {t('settings')}
          </Menu.Item>
          <Menu.Item key="projects" className="user-avatar__item">
            {t('projects')}
          </Menu.Item>
          <Menu.Item key="goals" className="user-avatar__item">
            {t('goals')}
          </Menu.Item>
          <Menu.Item key="logout" className="user-avatar__item user-avatar__item--logout">
            {t('logout')}
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  return (
    <Dropdown trigger={['click']} overlay={overlay}>
      <Avatar src={user.logo?.path} size="large" />
    </Dropdown>
  );
};

export default UserAvatar;
