import React from 'react';
import { useTranslation } from 'react-i18next';

import { toDayFormat } from 'utils/date';

import { TaskHistoryItem } from 'types/global';

type Props = {
  items: TaskHistoryItem[];
};

const TaskHistory: React.FC<Props> = ({ items }) => {
  const [t] = useTranslation();

  return (
    <div className="task-view__history">
      <h1>{t('actions')}</h1>
      {items &&
        items.map(action => (
          <div className="task-view__history__item" key={action._id}>
            {action.type === 'created' && (
              <div className="task-view__history__item-text">
                <em>{toDayFormat(action.createdAt)}</em>
                <span>- {t('taskCreated')}</span>
              </div>
            )}
            {action.type === 'rescheduled' && (
              <div className="task-view__history__item-text">
                <span>{t('rescheduled')}</span>
                <span>{t('from')}</span>
                <strong>{action.rescheduled.from}</strong>
                <span>{t('to')}</span>
                <strong>{action.rescheduled.to}</strong>
                <span>{t('at')}</span>
                <em>{toDayFormat(action.createdAt)}</em>
              </div>
            )}
            {action.type === 'changed' &&
              Object.keys(action.changed).map(el => (
                <div key={el} className="task-view__history__item-text">
                  <span>{t('changed')}</span>
                  <em>{t(el)}</em>
                  <span>{t('from')}</span>
                  <strong>{action.changed[el].from}</strong>
                  <span>{t('to')}</span>
                  <strong>{action.changed[el].to}</strong>
                  <span>{t('at')}</span>
                  <em>{toDayFormat(action.createdAt)}</em>
                </div>
              ))}
          </div>
        ))}
    </div>
  );
};

export default TaskHistory;
