import React from 'react';
import { Progress } from 'antd';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from 'store';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import Stats from './Stats';
import Search from './Search';
import Notifications from './Notifications';
import UserAvatar from 'components/UserAvatar';

import { TIMER_SECONDS } from 'utils';
import { getWeek } from 'utils/date';

import { setTasks } from 'store/tasks';
import { setNotes } from 'store/notes';

const Header = () => {
  const {
    params: { dayInMs },
  } = useRouteMatch<{ dayInMs: string }>();
  const { pathname } = useLocation();
  const dispatch = useTypedDispatch();
  const [t] = useTranslation();
  const history = useHistory();

  const timer = useTypedSelector(state => state.timer);

  const currentPage = pathname.split('/')[1];
  const timerProgress = Math.round(((TIMER_SECONDS - timer.timeInSeconds) / TIMER_SECONDS) * 100);

  const openWeekPage = () => {
    if (currentPage === 'week') return;

    dispatch(setTasks([]));
    dispatch(setNotes([]));
    const dayMilliseconds = dayInMs ? +dayInMs : new Date().getTime();
    const day = getWeek('current', dayMilliseconds)[0].valueOf();
    history.push(`/week/${day}`);
  };

  const openDayPage = (): string => {
    if (currentPage === 'day') return;
    dispatch(setTasks([]));
    dispatch(setNotes([]));

    history.push(`/day/${new Date().getTime()}`);
  };

  return (
    <div className="header">
      <div className="header__logo">
        {timer.isStarted ? (
          <div className="header__progress">
            <div className="header__progress-text">
              <img src={require('assets/img/pomodizer-logo-only-pomidor.png')} alt="logo" />
              <span>{timer.taskName}</span>
            </div>
            <Progress
              percent={timerProgress}
              showInfo={false}
              trailColor="#CDD4D9"
              strokeColor="#69C262"
            />
          </div>
        ) : (
          <div onClick={openWeekPage} className={clsx('header__link')}>
            <img src={require('assets/img/pomodizer-logo.png')} alt="logo" height="100%" />
          </div>
        )}
      </div>

      <div className="header__main">
        <Stats />

        <div className="header__links">
          <span
            onClick={openWeekPage}
            className={clsx('header__link', { 'header__link--active': currentPage === 'week' })}
          >
            {t('weeklyTasks')}
          </span>
          <span
            onClick={openDayPage}
            className={clsx('header__link', { 'header__link--active': currentPage === 'day' })}
          >
            {t('dailyTasks')}
          </span>
        </div>

        <div className="header__right-contents">
          <div className="header__search-wrapper">
            <Search />
          </div>
          <div className="header__notifications">
            <Notifications />
          </div>
          <div className="header__avatar">
            <UserAvatar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
