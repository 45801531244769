import { getWeek } from 'utils/date';
import apiClient from 'utils/feathersClient';

import { setStats } from 'store/stats';
import { AppThunk, PromiseThunk } from 'types/store/common';
import { StatsState } from 'types/store/stats';

export const fetchStats = (currentWeek: number): PromiseThunk<StatsState> => async dispatch => {
  const week = getWeek('current', currentWeek);

  const stats: StatsState = await apiClient
    .service('stats/total')
    .find({ query: { dueDate: { $in: week } } });
  dispatch(setStats(stats));

  return stats;
};

export const patchStats = (values: StatsState): AppThunk => dispatch => {
  dispatch(setStats(values));
};
