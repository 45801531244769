import React from 'react';
import { Progress } from 'antd';
import { useTranslation } from 'react-i18next';

import { useTypedSelector } from 'store';
import { getWeekStats } from 'store/stats/selectors';

type Props = {
  onlyPlanning?: boolean;
};

const Stats: React.FC<Props> = ({ onlyPlanning = false }) => {
  const [t] = useTranslation();

  const { planningQuality, doneTasks, tomatoFacts } = useTypedSelector(state => getWeekStats(state));

  return (
    <div className="header-stats">
      <div className="header-stats__planning">
        <div className="header-stats__planning-info">
          <span className="header-stats__planning-text">{t('planningQuality')}</span>
          <span className="header-stats__planning-percentage">{planningQuality}%</span>
        </div>
        <div className="header-stats__planning-progress">
          <Progress
            percent={planningQuality}
            showInfo={false}
            strokeColor="#69c262"
            trailColor="#CDD4D9"
          />
        </div>
      </div>

      {!onlyPlanning && (
        <div className="header-stats__right">
          <div className="header-stats__right-item">
            {t('completedTasks')}
            <span>{doneTasks}</span>
          </div>
          <div className="header-stats__right-item">
            {t('earnedPomidors')}
            <span>{tomatoFacts}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Stats;
