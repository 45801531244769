import queryString from 'query-string';

import apiClient from 'utils/feathersClient';

import { setAuthData, resetAuthData, setUser } from 'store/user';

import { PromiseThunk } from 'types/store/common';
import { User } from 'types/global';
import { fetchTags } from 'store/tags/thunkActions';

export const checkIsUserAuth = (): PromiseThunk => async dispatch => {
  // :: is from outside
  const parsedQuery = queryString.parse(window.location.search);

  if (parsedQuery.token) {
    localStorage.setItem('userToken', parsedQuery.token as string);
  }

  const { accessToken, user: currentUser } = await apiClient.reAuthenticate();


  if (!currentUser.isVerified) {
    dispatch(
      setAuthData({
        isAuth: false,
        token: accessToken,
        user: currentUser,
      })
    );
    return;
  }

  dispatch(
    setAuthData({
      isAuth: true,
      token: accessToken,
      user: currentUser,
    })
  );
  dispatch(bootstrapData());
};

export const logIn =
  (values: { email: string; password: string }): PromiseThunk =>
  async dispatch => {
    const { accessToken, user: currentUser } = await apiClient.authenticate({
      email: values.email,
      password: values.password,
      strategy: 'local',
    });


    if (!currentUser.isVerified) {
      dispatch(
        setAuthData({
          isAuth: false,
          token: accessToken,
          user: currentUser,
        })
      );
      return;
    }

    dispatch(
      setAuthData({
        isAuth: true,
        token: accessToken,
        user: currentUser,
      })
    );
    dispatch(bootstrapData());
  };

export const logOut = (): PromiseThunk<any> => async dispatch => {
  await apiClient.logout();
  dispatch(resetAuthData());
};

export const patchUser =
  (values: Partial<User>): PromiseThunk<User> =>
  async (dispatch, getState) => {
    const userId = getState().authUser.user;

    const response: User = await apiClient.service('users').patch(userId, values);
    dispatch(setUser(response));

    return response;
  };

export const bootstrapData = (): PromiseThunk<any> => async dispatch => {
  try {
    await dispatch(fetchTags());
  } catch (e) {
    console.error('Bootstrap data', e);
  }
};
