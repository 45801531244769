import React from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';

import AppRoute from './AppRouteHOC';
// Layouts
import MainLayout from 'layouts/Main';
import AuthLayout from 'layouts/Auth';
import SettingsLayout from 'layouts/Settings';
// Main
import WeekPage from 'pages/Week';
import DayPage from 'pages/Day';
// Auth
import Login from 'pages/Auth/Login';
import Signup from 'pages/Auth/Signup';
import ChangePassword from 'pages/Auth/ChangePassword';
import ForgotPassword from 'pages/Auth/ForgotPassword';
import ResendVerify from 'pages/Auth/ResendVerify';
import CheckVerify from 'pages/Auth/CheckVerify';
import Verify from 'pages/Auth/Verify';
// Settings
import AccountDetails from 'pages/Settings/AccountDetails';
import Security from 'pages/Settings/Security';
import AppSettings from 'pages/Settings/AppSettings';
import Projects from 'pages/Settings/Projects';
import GoalsSettings from 'pages/Settings/GoalsSettings';
// Other
import PageNotFound from 'pages/404';

const Routes = () => {
  const getDefaultRedirect = () => {
    if (window.innerWidth < 576) {
      return `/day/${new Date().getTime()}`;
    } else {
      return `/week/${new Date().getTime()}`;
    }
  };

  return (
    <Router>
      <Switch>
        <Redirect exact path="/" to={getDefaultRedirect()} />

        {/* Auth */}
        <AppRoute exact path="/login" layout={AuthLayout} component={Login} />
        <AppRoute exact path="/signup" layout={AuthLayout} component={Signup} />
        <AppRoute exact path="/forgot-password" layout={AuthLayout} component={ForgotPassword} />
        <AppRoute exact path="/change-password" layout={AuthLayout} component={ChangePassword} />
        <AppRoute exact path="/check-verify" layout={AuthLayout} component={CheckVerify} />
        <AppRoute exact path="/resend-verify" layout={AuthLayout} component={ResendVerify} isProtected />
        <AppRoute exact path="/verify" layout={AuthLayout} component={Verify} />
        {/* Main */}
        <AppRoute exact path="/week/:dayInMs" layout={MainLayout} component={WeekPage} isProtected />
        <AppRoute exact path="/day/:dayInMs" layout={MainLayout} component={DayPage} isProtected />
        {/* Settings */}
        <AppRoute
          exact
          path="/settings/account-details"
          layout={SettingsLayout}
          component={AccountDetails}
          isProtected
        />
        <AppRoute
          exact
          path="/settings/security"
          layout={SettingsLayout}
          component={Security}
          isProtected
        />
        <AppRoute
          exact
          path="/settings/app-settings"
          layout={SettingsLayout}
          component={AppSettings}
          isProtected
        />
        <AppRoute
          exact
          path="/settings/projects"
          layout={SettingsLayout}
          component={Projects}
          isProtected
        />
          <AppRoute
          exact
          path="/settings/goals"
          layout={SettingsLayout}
          component={GoalsSettings}
          isProtected
        />

        <Route component={PageNotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;
