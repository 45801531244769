import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useTypedDispatch, useTypedSelector } from 'store';
import moment from 'moment';
import clsx from 'clsx';

import { changeFormat, getDay } from 'utils/date';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import { setTasks } from 'store/tasks';

const Schedule = () => {
  const {
    params: { dayInMs },
  } = useRouteMatch<{ dayInMs: string }>();
  const history = useHistory();
  const dispatch = useTypedDispatch();

  const currentWeek = useTypedSelector(state => state.dateData.currentWeek);
  const currentDay = useTypedSelector(state => state.dateData.currentDay);

  const setDay = (day: string) => {
    dispatch(setTasks([]));
    const val = moment(day, 'DD.MM.YYYY').valueOf();
    history.push(`/day/${val}`);
  };

  const goByStep = (type: 'previous' | 'next') => {
    dispatch(setTasks([]));
    const newDay = getDay(type, +dayInMs).valueOf();
    history.push(`/day/${newDay}`);
  };

  return (
    <div className="schedule">
      <div className="schedule__arrow" onClick={() => goByStep('previous')}>
        <ArrowLeftIcon />
      </div>
      <div className="schedule__body">
        {currentWeek.map(date => (
          <div
            key={date}
            className={clsx('schedule__day', {
              'schedule__day--today': date === moment().format('DD.MM.YYYY'),
              'schedule__day--active': date === currentDay,
            })}
            onClick={() => setDay(date)}
          >
            <span className="schedule__week-day">{changeFormat(date, 'DD.MM.YYYY', 'ddd')}</span>
            <span className="schedule__digit-day">{date.slice(0, 2)}</span>
          </div>
        ))}
      </div>
      <div className="schedule__arrow" onClick={() => goByStep('next')}>
        <ArrowRightIcon />
      </div>
    </div>
  );
};

export default Schedule;
