import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { useTypedDispatch } from 'store';
import { useHistory } from 'react-router';
import { Input, Button } from 'antd';
import { FORM_ERROR } from 'final-form';

import FieldWithValidation from 'components/FieldWithValidation';

import { isEmail } from 'utils';
import apiClient from 'utils/feathersClient';

import { logOut } from 'store/user/thunkActions';

import { ValidationErrorsObject } from 'types/global';

const ResendVerify: React.FC<{}> = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useTypedDispatch();

  const onSubmit = async (values: { email: string }) => {
    try {
      await apiClient.service('resend-verify').create(values);
      history.push('/check-verify');
    } catch (e) {
      return { [FORM_ERROR]: e.message };
    }
  };

  const toLogin = async () => {
    await dispatch(logOut());
    history.push('/login');
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, submitError, submitting }) => (
        <form className="auth-form auth-form--resend-verify" onSubmit={handleSubmit}>
          <div className="auth-form__title">{t('verify')}</div>

          <div className="auth-form__info">{t('youCanResend')}.</div>

          {submitError && <div className="auth-form__error">{submitError}</div>}

          <div className="auth-form__fields">
            <Field name="email">
              {({ input, meta }) => (
                <FieldWithValidation meta={meta} errorText={t(meta.error)}>
                  <Input {...input} type="email" placeholder="Email" />
                </FieldWithValidation>
              )}
            </Field>
          </div>

          <Button
            className="auth-form__button mb10"
            type="primary"
            htmlType="submit"
            loading={submitting}
          >
            {t('resendLink')}
          </Button>
          <Button className="auth-form__button" onClick={toLogin}>
            {t('backToLogin')}
          </Button>
        </form>
      )}
    />
  );
};

type FormState = {
  email: string;
};

const validate = (values: FormState) => {
  const errors: ValidationErrorsObject<FormState> = {};

  if (!values.email) {
    errors.email = 'required';
  }
  if (values.email && !isEmail(values.email)) {
    errors.email = 'invalidEmail';
  }

  return errors;
};

export default ResendVerify;
