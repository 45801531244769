import React from 'react';
import { Droppable, DroppableProps } from 'react-beautiful-dnd';

type Props = Omit<DroppableProps, 'children'> &
  React.HTMLAttributes<HTMLDivElement> & { children: React.ReactNode };

const DroppableWrapper: React.FC<Props> = ({ className, style, id, children, ...wrapperProps }) => {
  return (
    <Droppable {...wrapperProps}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          id={id}
          className={className}
          style={style}
        >
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default DroppableWrapper;
