import React from 'react';
import { Checkbox } from 'antd';
import clsx from 'clsx';

import { ChecklistItem } from 'types/global';

import { ReactComponent as XIcon } from 'assets/icons/close.svg';

type Props = {
  item: Partial<ChecklistItem>;
  onUpdateItem: () => void;
  onDeleteItem: () => void;
};

const CheckListItems: React.FC<Props> = ({ item, onUpdateItem, onDeleteItem }) => {
  return (
    <div className={clsx('checklist__item', { 'checklist__item--checked': item.isChecked })}>
      <Checkbox
        className="checklist__item-checkbox"
        checked={item.isChecked}
        onChange={() => onUpdateItem()}
        onClick={e => e.stopPropagation()}
      >
        <span className="checklist__item-label" onClick={e => e.stopPropagation()}>
          {item.title}
        </span>
      </Checkbox>

      <XIcon
        className="checklist__item-remove"
        onClick={e => {
          e.stopPropagation();
          onDeleteItem();
        }}
      />
    </div>
  );
};

export default CheckListItems;
