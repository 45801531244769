import React from 'react';

import Navigation from './Navigation';
import DayColumns from './Tasks';
import Notes from 'components/Notes';
import Goals from 'components/Goals';

const WeekContainer = () => {
  return (
    <div className="week">
      <div className="week__head">
        <div className="week__navigation">
          <Navigation />
        </div>
        <div className="week__goals">
          <Goals className="week__goals-wrapper" />
        </div>
      </div>
      <div className="week__tasks">
        <DayColumns />
      </div>
{/*       <div className="week__notes">
        <Notes />
      </div> */}
    </div>
  );
};

export default WeekContainer;
