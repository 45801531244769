import moment from 'moment';
import { setNotes, updateNote, addNote, deleteNote } from 'store/notes';

import apiClient from 'utils/feathersClient';
import { getWeek, getDay } from 'utils/date';

import { NotesItem } from 'types/global';
import { PromiseThunk } from 'types/store/common';
import { Paginated } from '@feathersjs/feathers';
import { closeNote } from 'store/core';

export const fetchWeekNotes = (currentWeek: number): PromiseThunk<NotesItem[]> => async dispatch => {
  const [startOfWeek, , , , , , endOfWeek] = getWeek('current', currentWeek);

  const query = {
    $limit: 100,
    $or: [
      { status: 'active' },
      { isReminded: true, dueDate: { $gte: startOfWeek, $lte: endOfWeek } },
      { status: 'done', doneAt: { $gte: startOfWeek, $lte: endOfWeek } },
    ],
  };
  const { data: notes }: Paginated<NotesItem> = await apiClient.service('notes').find({ query });
  dispatch(setNotes(notes));

  return notes;
};

export const fetchDayNotes = (currentDay: number): PromiseThunk<NotesItem[]> => async dispatch => {
  const day = getDay('current', currentDay);

  const query = {
    $limit: 100,
    $or: [
      { status: 'active' },
      { isReminded: true, dueDate: { $gte: day, $lte: day } },
      { status: 'done', doneAt: { $gte: day, $lte: day } },
    ],
  };
  const { data: notes }: Paginated<NotesItem> = await apiClient.service('notes').find({ query });

  dispatch(setNotes(notes));

  return notes;
};

export const patchNote = (
  id: string,
  values: Partial<NotesItem>,
  closeOnDone: boolean = true
): PromiseThunk<NotesItem> => async dispatch => {
  const note: NotesItem = await apiClient.service('notes').patch(id, values);
  dispatch(updateNote(note));

  if (closeOnDone) dispatch(closeNote());

  return note;
};

export const createNote = (
  values: Partial<NotesItem>,
  closeOnDone: boolean = true
): PromiseThunk<NotesItem> => async dispatch => {
  // schedule notification on task if dueTime is present
  const query =
    values.isReminded && values.dueTime && values.dueDate
      ? { $scheduleDate: moment(`${values.dueDate} ${values.dueTime}`, 'DD.MM.YYYY HH:mm') }
      : {};

  const note: NotesItem = await apiClient.service('notes').create(values, { query });
  dispatch(addNote(note));

  if (closeOnDone) dispatch(closeNote());

  return note;
};

export const removeNote = (
  id: string,
  closeOnDone: boolean = true
): PromiseThunk<NotesItem> => async dispatch => {
  const note: NotesItem = await apiClient.service('notes').remove(id);
  dispatch(deleteNote(note._id));

  if (closeOnDone) dispatch(closeNote());

  return note;
};
