import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import FloatingLabel from 'floating-label-react';
import { useTranslation } from 'react-i18next';

import apiClient from 'utils/feathersClient';

import { ValidationErrorsObject } from 'types/global';

import 'floating-label-react/styles.css';

const UserForm = () => {
  const [t] = useTranslation();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const submit = async (values: {
    oldPassword: string;
    newPassword: string;
    repeatPassword: string;
  }) => {
    try {
      await apiClient.service('new-password').create(values, { query: { $setByOld: true } });
      return {};
    } catch (e) {
      setErrorMessage(e.message);
      return e.errors;
    }
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="settings update-password">
          <div className="settings__row settings__row_error">
            <div className="settings__error">
              <p>{errorMessage}</p>
            </div>
          </div>
          <div className="settings__row">
            <div className="settings__box settings__box_right-border">
              <div className="settings__field settings__field_padding">
                <Field name="oldPassword">
                  {({ input, meta }) => (
                    <FloatingLabel
                      {...input}
                      type="password"
                      placeholder={t('lastPassword')}
                      className={(meta.error || meta.submitError) && meta.touched ? 'field_error' : ''}
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className="settings__box">
              <div className="settings__field settings__field_padding">
                <Field name="newPassword">
                  {({ input, meta }) => (
                    <FloatingLabel
                      {...input}
                      type="password"
                      placeholder={t('newPassword')}
                      className={(meta.error || meta.submitError) && meta.touched ? 'field_error' : ''}
                    />
                  )}
                </Field>
              </div>
              <div className="settings__field">
                <Field name="repeatPassword">
                  {({ input, meta }) => (
                    <FloatingLabel
                      {...input}
                      type="password"
                      placeholder={t('repeat')}
                      className={(meta.error || meta.submitError) && meta.touched ? 'field_error' : ''}
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className="settings__box">
              <div className="settings__field settings__field_full">
                <button className="btn btn_green" type="submit">
                  {t('changePassword')}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
};

type FormState = {
  oldPassword: string;
  newPassword: string;
  repeatPassword: string;
};

const validate = (values: Partial<FormState>) => {
  const errors: ValidationErrorsObject<FormState> = {};

  if (!values.oldPassword) {
    errors.oldPassword = 'Required';
  }
  if (!values.newPassword) {
    errors.newPassword = 'Required';
  }
  if (!values.repeatPassword) {
    errors.repeatPassword = 'Required';
  }
  if (values.newPassword && values.newPassword !== values.repeatPassword) {
    errors.repeatPassword = 'Your password and confirmation password do not match';
  }

  return errors;
};

export default UserForm;
