import { combineReducers } from 'redux';

import core from 'store/core';
import dateData from 'store/date';
import authUser from 'store/user';
import tasks from 'store/tasks';
import timer from 'store/timer';
import goals from 'store/goals';
import notes from 'store/notes';
import tags from 'store/tags';
import stats from 'store/stats';

export default combineReducers({
  dateData,
  authUser,
  tasks,
  core,
  notes,
  timer,
  tags,
  goals,
  stats,
});
