import apiClient from './feathersClient';

/**
 * checks if Push notification and service workers are supported by your browser
 */
function isPushNotificationSupported() {
  return 'serviceWorker' in navigator && 'PushManager' in window;
}

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
function askUserPermission() {
  if (isPushNotificationSupported()) {
    return Notification.requestPermission();
  }
  return Promise.reject('Notifications are not supported on this browser');
}

/**
 * returns the subscription if present or nothing
 */
async function getUserSubscription() {
  //wait for service worker installation to be ready, and then
  const serviceWorker = await navigator.serviceWorker.ready;
  const pushSubscription = await serviceWorker.pushManager.getSubscription();
  return pushSubscription;
}

/**
 *
 * using the registered service worker creates a push notification subscription and returns it
 *
 */
async function createNotificationSubscription() {
  //wait for service worker installation to be ready
  const serviceWorker = await navigator.serviceWorker.ready;

  // subscribe and return the subscription
  const subscription = await serviceWorker.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: process.env.REACT_APP_PUSH_PUBLIC_KEY,
  });
  // console.info('Notification subscription created', subscription);

  try {
    await apiClient.service('subscriptions').create(subscription);
  } catch (e) {
    console.error('Error while creating subscription', e);
  }

  return subscription;
}

/**
 * MAIN FUNCTION: registers service worker, then asks for permission to send notifications, then sends this information to api and that's all
 */
export const subscribeToNotifications = async () => {
  const permission = await askUserPermission();

  if (permission === 'granted') {
    const currentSubscription = await getUserSubscription();

    if (currentSubscription) {
      // console.info('User already subscribed to notifications', currentSubscription);
    } else {
      await createNotificationSubscription();
    }
  } else {
    console.error('Permission to send notifications denied or cancelled:', permission);
  }
};
