import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import { RootState } from 'types/store/common';

export const getTasksForColumn = createCachedSelector(
  (state: RootState) => state.tasks.allTasks,
  (state: RootState, dueDate: string) => dueDate,
  (tasks, dueDate) => {
    if (Array.isArray(tasks) && tasks.length === 0) return [];

    const { active, transferred } = tasks.reduce(
      (obj, item) => {
        const isActive = item.dueDate === dueDate;
        const isTransferred = item.dueDateHistory.length > 1 && item.dueDateHistory.includes(dueDate);

        if (isActive) {
          obj.active.push(item);
        } else if (isTransferred) {
          obj.transferred.push(item);
        }
        return obj;
      },
      { active: [], transferred: [] }
    );

    const sortedActiveTasks = active.sort((x, y) => x.position - y.position);
    const concatedTasks = sortedActiveTasks.concat(transferred);
    return concatedTasks;
  }
)(
  (state, dueDate) => dueDate // Cache selectors by state name
);

export const getTasksForDay = createCachedSelector(
  (state: RootState) => state.tasks.allTasks,
  (state: RootState, dueDate: string) => dueDate,
  (tasks, dueDate) => {
    if (Array.isArray(tasks) && tasks.length === 0) return [];

    const { active, transferred } = tasks.reduce(
      (obj, item) => {
        if (item.dueDate !== dueDate) {
          obj.transferred.push(item);
        } else {
          obj.active.push(item);
        }
        return obj;
      },
      { active: [], transferred: [] }
    );

    const sortedActiveTasks = active.sort((x, y) => y.position - x.position)
      .sort((x, y) => {
        if (x.status !== 'done') {
          return -1
        }

        // return -1
      })

    // const sortedActiveTasks = active.concat(transferred).sort((x, y) => x.position - y.position);
    return sortedActiveTasks.concat(transferred);
  }
)(
  (state, dueDate) => dueDate // Cache selectors by state name
);

export const getHoldedTasks = createSelector(
  (state: RootState) => state.tasks.holdedTasks,
  holdedTasks => holdedTasks.slice().sort((a, b) => a.position - b.position)
);
