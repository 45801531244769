import React, { useRef } from 'react';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { GoalsItem } from 'types/global';

interface CardItemProps {
  item: any;
  onClick: () => void;
  onClickExtra: () => void;
  projectColSpan?: number;
  onGoalRemove?: () => void;
  onGoalDelete?: () => void;
  onDeletePopupOpen?: (e: React.MouseEvent<HTMLElement>) => void;
  onPatchGoal?: (id: string, values: any) => void;
}

const GoalCard = ({ item, onClick, onPatchGoal, onDeletePopupOpen, projectColSpan }: CardItemProps) => {
  const [t] = useTranslation();
  const btnRef = useRef(null);

  const handleCardOpen = (e: any) => {
    const parentEl = e.target.closest('button');
    if (parentEl?.dataset.id) return;
    onClick();
  };

  return (
    <Col span={projectColSpan} key={item._id} onClick={handleCardOpen}>
      <div className="settings-form__project">
        <div className="settings-form__project__name">{item.title}</div>
        <Row className="settings-form__project__buttons">
          {item.status === 'active' ? (
            <Button
              type="primary"
              data-id="archive"
              onClick={e => onPatchGoal(item._id, { ...item, status: 'done' })}
            >
              {t('markGoalAsDone')}
            </Button>
          ) : (
            <Button
              type="primary"
              className="button-warn"
              data-id="unarchive"
              onClick={e => onPatchGoal(item._id, { ...item, status: 'active' })}
            >
              {t('unmarkGoalAsDone')}
            </Button>
          )}
          <Button type="primary" ref={btnRef} data-id="delete-btn" danger onClick={onDeletePopupOpen}>
            {t('delete')}
          </Button>
        </Row>
      </div>
    </Col>
  );
};

export default GoalCard;
