import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatsState } from 'types/store/stats';

const initialState: StatsState = {
  closed: 0,
  tomatoFact: 0,
  notClosed: 0,
  weekClosed: 0,
  totalExpired: 0,
};

const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    setStats: (state, { payload }: PayloadAction<StatsState>) => {
      return { ...state, ...payload };
    },
  },
});

export const { setStats } = statsSlice.actions;

export default statsSlice.reducer;
