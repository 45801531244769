import React, { ReactNode } from 'react';
import clsx from 'clsx';

type Props = { isActive?: boolean; children: ReactNode; className?: string; onClick: () => void };

const QuickSelectBadge: React.FC<Props> = ({ isActive = false, className, children, onClick }) => {
  return (
    <div
      className={clsx('quick-select-badge', { 'quick-select-badge--active': isActive }, className)}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default QuickSelectBadge;
