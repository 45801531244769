import React from 'react';
import { useTypedDispatch, useTypedSelector } from 'store';
import clsx from 'clsx';
import { CSSTransition } from 'react-transition-group';

import Schedule from './Schedule';
import Body from './Body';
import Goals from 'components/Goals';
import Notes from 'components/Notes';
import TaskForm from 'components/TaskForm';
import TaskView from 'components/TaskView';

import { openTask } from 'store/core';
import { getIsMobileScreen } from 'store/core/selectors';

import { OpenedTaskState } from 'types/store/core';

const Day = () => {
  const dispatch = useTypedDispatch();

  const { isActive: isTaskOpened, type, showInModal: taskShownInModal } = useTypedSelector(
    state => state.core.openedTask
  );
  const isMobileScreen = useTypedSelector(state => getIsMobileScreen(state));

  const onOpenTask = (payload: OpenedTaskState) => dispatch(openTask(payload));

  return (
    <div className="day">
      <div className="day__wrapper">
        <div
          className={clsx('day__main', { 'day__main--fullsize': !(isTaskOpened && !taskShownInModal) })}
        >
          <div className="day__schedule">
            <Schedule />
          </div>

          <div className="day__goals">
            <Goals />
          </div>

          <div className="day__body">
            <Body />
          </div>

{/*           <div className="day__notes">
            <Notes />
          </div> */}
        </div>

        <CSSTransition
          in={isTaskOpened && !taskShownInModal}
          timeout={300}
          classNames="side-transition"
          unmountOnExit
        >
          <div className="day__side">
            {type === 'new' ? (
              <TaskForm
                isAttachedToDaypage
                whenTaskCreated={createdTask =>
                  onOpenTask({ type: 'edit', _id: createdTask._id, showInModal: isMobileScreen })
                }
              />
            ) : type === 'edit' ? (
              <TaskView isAttachedToDaypage />
            ) : null}
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

export default Day;
