import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'antd';
import { FORM_ERROR } from 'final-form';

import FieldWithValidation from 'components/FieldWithValidation';

import { isEmail } from 'utils';
import apiClient from 'utils/feathersClient';

import { ValidationErrorsObject } from 'types/global';

const Login = () => {
  const [t] = useTranslation();
  const history = useHistory();

  const onSubmit = async (values: { email: string; password: string }) => {
    try {
      await apiClient.service('reset-password').create(values);
      history.push('/check-verify');
    } catch (e) {
      return { [FORM_ERROR]: e.message };
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, submitError, submitting }) => (
        <form className="auth-form auth-form--forgot-password" onSubmit={handleSubmit}>
          <div className="auth-form__title">{t('forgotPassword')}</div>

          <div className="auth-form__info">{t('linkWillBeSent')}.</div>

          {submitError && <div className="auth-form__error">{submitError}</div>}

          <div className="auth-form__fields">
            <Field name="email">
              {({ input, meta }) => (
                <FieldWithValidation meta={meta} errorText={t(meta.error)}>
                  <Input {...input} type="email" placeholder="Email" />
                </FieldWithValidation>
              )}
            </Field>
          </div>

          <Button className="auth-form__button" type="primary" htmlType="submit" loading={submitting}>
            {t('sendLink')}
          </Button>
        </form>
      )}
    />
  );
};

type FormState = {
  email: string;
};

const validate = (values: FormState) => {
  const errors: ValidationErrorsObject<FormState> = {};

  if (!values.email) {
    errors.email = 'required';
  }
  if (values.email && !isEmail(values.email)) {
    errors.email = 'invalidEmail';
  }

  return errors;
};

export default Login;
