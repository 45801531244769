import React, { useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useTypedDispatch, useTypedSelector } from 'store';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import DraggableWrapper from 'components/DndWrappers/Draggable';

import { openTask } from 'store/core';
import { patchTask } from 'store/tasks/thunkActions';
import { startTimer, stopTimer } from 'store/timer/thunkActions';

import { TasksItem } from 'types/global';
import { OpenedTaskState } from 'types/store/core';

import { ReactComponent as ThreeDotsIcon } from 'assets/icons/three-dots.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as TomatoIcon } from 'assets/icons/tomato.svg';
import { ReactComponent as StopButtonIcon } from 'assets/icons/stop-button.svg';
import { ReactComponent as PlayButtonIcon } from 'assets/icons/play-button.svg';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/check-mark.svg';
import { ReactComponent as DescriptionIcon } from 'assets/icons/description.svg';
import { ReactComponent as UploadsIcon } from 'assets/icons/uploads.svg';
import { ReactComponent as CommentsIcon } from 'assets/icons/comments.svg';
import Icon from 'components/UI/Icon';
import { ReactComponent as FlagIcon } from 'assets/icons/flag.svg';

type Props = {
  index: number;
  item: TasksItem;
  columnDate: string;
  isTransferred: boolean;
};

const TasksColumnItem: React.FC<Props> = ({ item, index, columnDate, isTransferred }) => {
  const dispatch = useTypedDispatch();
  const [t] = useTranslation();

  const timer = useTypedSelector(state => state.timer);

  const [isBodyShown, setIsBodyShown] = useState<boolean>(false);

  const isTaskStarted = timer.taskId === item._id && timer.isStarted;
  const isTaskDone = item.status === 'done';

  const onOpenTask = (e: React.MouseEvent<SVGSVGElement>, payload: OpenedTaskState) => {
    dispatch(openTask(payload));
  };
  const onStartTimer = () => dispatch(startTimer(item));
  const onStopTimer = () => dispatch(stopTimer());
  const onPressFinish = () => {
    dispatch(patchTask(item._id, { status: isTaskDone ? 'wait' : 'done' }, false));
  };

  const containerRef = useOnclickOutside(
    () => {
      if (isBodyShown && !isTaskStarted) {
        setIsBodyShown(false);
      }
    },
    { eventTypes: ['mouseup'] }
  );

  return (
    <DraggableWrapper
      index={index}
      draggableId={isTransferred ? `${item._id}_${columnDate}` : item._id}
      isDragDisabled={isTransferred}
      className={clsx('column-task', {
        'column-task--transferred': isTransferred,
        'column-task--opened': isBodyShown,
        'column-task--done': isTaskDone,
      })}
    >
      <div ref={containerRef}>
        <div className="column-task__color-borders">
          {item.tags
            ?.filter(el => el.color)
            .map(tag => (
              <div key={tag._id} style={{ backgroundColor: tag.color }} />
            ))}
        </div>

        <div className="column-task__head" onClick={() => setIsBodyShown(v => !v)}>
          <div className="column-task__urgent-wrapper">
            {item?.priority === 'urgent' && (
              <div className='task-view__flag'>
                <FlagIcon stroke="red" width="16px" />
              </div>
            )}
            <span className="column-task__title">{item.name}</span>
          </div>

          {isBodyShown && (
            <ThreeDotsIcon
              className="column-task__three-dots"
              onClick={e => onOpenTask(e, { type: 'edit', _id: item._id })}
            />
          )}
        </div>

        {!isBodyShown ? (
          <div className="column-task__folded-info">
            <div className="column-task__folded-left">
              {!!item.description && <DescriptionIcon className="column-task__folded-description" />}
              {item.uploadsIds?.length > 0 && <UploadsIcon className="column-task__folded-uploads" />}
              {item.commentsIds?.length > 0 && <CommentsIcon className="column-task__folded-comments" />}
              {!!item.dueTime && (
                <>
                  <ClockIcon className="column-task__folded-clock" />
                  <span className="column-task__folded-duetime">{item.dueTime}</span>
                </>
              )}
            </div>

            {item.status === 'done' && <div className="column-task__folded-right">{t('done')}</div>}
          </div>
        ) : (
          <div className="column-task__body">
            <div className="column-task__info">
              {!!item.dueTime && (
                <div className="column-task__time">
                  <ClockIcon />
                  {item.dueTime}
                </div>
              )}
              {!!item.tomatoFact && (
                <div className="column-task__tomatoes">
                  <TomatoIcon /> {item.tomatoFact}
                </div>
              )}
            </div>
            <div className="column-task__tags">
              {item.tags.map((tag, i) => (
                <span
                  key={i}
                  className="column-task__tags-item"
                  style={{ color: tag.color || '#485a63' }}
                >
                  {tag.name}
                </span>
              ))}
            </div>
            <div className="column-task__actions">
              {isTaskStarted ? (
                <StopButtonIcon className="column-task__stop-button" onClick={onStopTimer} />
              ) : (
                <PlayButtonIcon className="column-task__play-button" onClick={onStartTimer} />
              )}
              <CheckMarkIcon
                className={clsx('column-task__check-mark', {
                  'column-task__check-mark--done': isTaskDone,
                })}
                onClick={onPressFinish}
              />
            </div>
          </div>
        )}
      </div>
    </DraggableWrapper>
  );
};

export default TasksColumnItem;
