import React from 'react';
import { Draggable, DraggableProps } from 'react-beautiful-dnd';

type Props = Omit<DraggableProps, 'children'> &
  React.HTMLAttributes<HTMLDivElement> & { children?: React.ReactNode };

const DraggableWrapper: React.FC<Props> = ({
  draggableId,
  isDragDisabled,
  index,
  children,
  ...rest
}) => {
  return (
    <Draggable draggableId={draggableId} isDragDisabled={isDragDisabled} index={index}>
      {provided => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          {...rest}
          style={{ ...rest.style, ...provided.draggableProps.style }}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};

export default DraggableWrapper;
