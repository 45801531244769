import React, { useEffect } from 'react';
import { useTypedDispatch, useTypedSelector } from 'store';
import { CSSTransition } from 'react-transition-group';

import Modal from 'components/Modal';
import TaskForm from 'components/TaskForm';
import GoalForm from 'components/GoalForm';
import SettingsForm from 'components/Settings';
import NoteForm from 'components/NoteForm';
import TagForm from 'components/TagForm';
import BacklogForm from 'components/BacklogForm';
import TaskView from 'components/TaskView';
import NoteView from 'components/NoteView';

import { closeGoal, closeSettings, closeTask, closeNote, closeTag, closeBacklog } from 'store/core';

const Modals = () => {
  const dispatch = useTypedDispatch();

  const { isActive: isTaskActive, showInModal: taskShownInModal, type: taskType } = useTypedSelector(
    state => state.core.openedTask
  );
  const { isActive: isGoalActive } = useTypedSelector(state => state.core.openedGoal);
  const { isActive: isSettingsActive } = useTypedSelector(state => state.core.settingsModal);
  const { isActive: isNoteActive, type: noteType } = useTypedSelector(state => state.core.openedNote);
  const { isActive: isTagActive } = useTypedSelector(state => state.core.openedTag);
  const { isActive: isBacklogActive } = useTypedSelector(state => state.core.openedBacklog);

  const onCloseTask = () => dispatch(closeTask());
  const onCloseGoal = () => dispatch(closeGoal());
  const onCloseSettings = () => dispatch(closeSettings());
  const onCloseNote = () => dispatch(closeNote());
  const onCloseTag = () => dispatch(closeTag());
  const onCloseBacklog = () => dispatch(closeBacklog());

  const ifModalActive =
    isTaskActive || isGoalActive || isSettingsActive || isNoteActive || isTagActive || isBacklogActive;

  useEffect(() => {
    if (ifModalActive) {
      document.body.style.position = 'fixed';
      document.body.style.overflow = 'hidden';
      document.getElementById('root').style.overflow = 'hidden';
      // @ts-ignore
      document.body.style.overscrollBehavior = 'none';
    } else {
      document.body.style.position = 'initial';
      document.body.style.overflow = 'initial';
      document.getElementById('root').style.overflow = 'initial';
      // @ts-ignore
      document.body.style.overscrollBehavior = 'initial';
    }
  }, [ifModalActive]);

  const modals = [
    {
      component: <TaskForm />,
      isVisible: isTaskActive && taskShownInModal && taskType === 'new',
      onClose: onCloseTask,
    },
    {
      component: <TaskView />,
      isVisible: isTaskActive && taskShownInModal && taskType === 'edit',
      onClose: onCloseTask,
    },
    {
      component: <GoalForm />,
      isVisible: isGoalActive,
      onClose: onCloseGoal,
    },
    {
      component: <SettingsForm />,
      isVisible: isSettingsActive,
      onClose: onCloseSettings,
    },
    {
      component: <NoteForm />,
      isVisible: isNoteActive && noteType === 'new',
      onClose: onCloseNote,
    },
    {
      component: <NoteView />,
      isVisible: isNoteActive && noteType === 'edit',
      onClose: onCloseNote,
    },
    {
      component: <TagForm />,
      isVisible: isTagActive,
      onClose: onCloseTag,
    },
    {
      component: <BacklogForm />,
      isVisible: isBacklogActive,
      onClose: onCloseBacklog,
    },
  ];

  return (
    <>
      {modals.map(({ component, isVisible, onClose }, i) => (
        <CSSTransition
          key={i}
          in={isVisible}
          timeout={200}
          classNames="modal-transition"
          unmountOnExit
          mountOnEnter
        >
          <Modal key={i} component={component} onClose={onClose} />
        </CSSTransition>
      ))}
    </>
  );
};

export default Modals;
