import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TIMER_SECONDS } from 'utils';

import { TimerState } from 'types/store/timer';

const initialState: TimerState = {
  isStarted: false,
  isPaused: false,
  timeInSeconds: TIMER_SECONDS,
  taskId: '',
  tomatoPlan: 0,
  tomatoFact: 0,
  taskName: '',
};

const timerSlice = createSlice({
  name: 'timer',
  initialState,
  reducers: {
    setTimer: (state, { payload }: PayloadAction<TimerState>) => {
      return {
        isStarted: true,
        isPaused: false,
        timeInSeconds: TIMER_SECONDS,
        taskId: payload.taskId,
        tomatoPlan: payload.tomatoPlan,
        tomatoFact: payload.tomatoFact,
        taskName: payload.taskName,
      };
    },

    decreaseTimer: state => {
      state.timeInSeconds = state.timeInSeconds - 1;
    },

    setIsTimerPaused: (state, { payload }: PayloadAction<boolean>) => {
      state.isPaused = payload;
    },

    resetTimer: () => {
      return initialState;
    },
  },
});

export const { setTimer, decreaseTimer, setIsTimerPaused, resetTimer } = timerSlice.actions;

export default timerSlice.reducer;
