import React from 'react';
import { Tooltip } from 'antd';

import { GoalsItem } from 'types/global';
import DraggableWrapper from 'components/DndWrappers/Draggable';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as OkIcon } from 'assets/icons/ok.svg';
import { useTranslation } from 'react-i18next';

type Props = {
  item: GoalsItem;
  index: number;
  onClick: () => void;
  onClickExtra: () => void;
};

const GoalItem: React.FC<Props> = ({ item, index, onClick, onClickExtra }) => {
  const [t] = useTranslation();

  const onClickExtraIcon = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onClickExtra();
  };

  return (
    <DraggableWrapper index={index} draggableId={item._id} className="goals__item" onClick={onClick}>
      <div className="goals__item-title">
        <span className="goals__item-title__emoji">{item.emoji?.icon}</span>
        <div className="goals__item-title__wrapper">
          <span title={item.title} className="goals__item-title__text">
            {item.title}
          </span>
          {/* <span className="goals__item-title__monthly">{item.isMonthly ? t('month') : t('week')}</span> */}
        </div>
      </div>
      <div className="goals__item-due-date">{item.dueDate ? item.dueDate : ''}</div>
      {/*       <div className="goals__item-extra" onClick={onClickExtraIcon}>
        <Tooltip title={'help window, which will describe the feature'} placement="topLeft">
          <CalendarIcon className="goals__item-extra-icon" />
        </Tooltip>
      </div> */}
      {item.status === 'done' && (
        <div className="goals__item-done">
          <OkIcon />
        </div>
      )}
      
    </DraggableWrapper>
  );
};

export default GoalItem;
