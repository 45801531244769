import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'antd';
import { useHistory, useRouteMatch } from 'react-router-dom';
import moment from 'moment';

import { getWeek, isSameWeek } from 'utils/date';

import locale from 'antd/es/date-picker/locale/en_US';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import { useTypedDispatch } from 'store';
import { setTasks } from 'store/tasks';

const WeekNavigation = () => {
  const history = useHistory();
  const {
    params: { dayInMs },
  } = useRouteMatch<{ dayInMs: string }>();
  const [t] = useTranslation();
  const dispatch = useTypedDispatch();

  const weekInterval = useMemo(() => {
    const currentWeek = getWeek('current', +dayInMs);
    if (isSameWeek(currentWeek[0], new Date())) {
      return t('thisWeek');
    }
    return `${currentWeek[0].format('DD.MM')} - ${currentWeek[6].format('DD.MM')}`;
  }, [dayInMs, t]);

  const onPressPrev = () => {
    dispatch(setTasks([]));
    const previousMonday = getWeek('previous', +dayInMs)[0].valueOf();
    history.push(`/week/${previousMonday}`);
  };

  const onPressNext = () => {
    dispatch(setTasks([]));
    const nextMonday = getWeek('next', +dayInMs)[0].valueOf();
    history.push(`/week/${nextMonday}`);
  };

  const goToToday = () => {
    dispatch(setTasks([]));
    history.push(`/week/${new Date().getTime()}`);
  };

  const onCalendarClick = (date: moment.Moment) => {
    dispatch(setTasks([]));
    history.push(`/week/${date.valueOf()}`);
  };

  return (
    <div className="navigation">
      <div className="navigation__dates">
        <div className="navigation__arrow" onClick={onPressPrev}>
          <ArrowLeftIcon />
        </div>
        <div className="navigation__current-week">{weekInterval}</div>
        <div className="navigation__calendar">
          <DatePicker
            className="datepicker-calendar"
            dropdownClassName="calendar-picker"
            mode="date"
            popupStyle={{ zIndex: 20 }}
            showToday={false}
            locale={locale}
            value={moment(+dayInMs)}
            onChange={onCalendarClick}
          />
        </div>
        <div className="navigation__arrow" onClick={onPressNext}>
          <ArrowRightIcon />
        </div>
      </div>
      {!isSameWeek(getWeek('current', +dayInMs)[0], new Date()) && (
        <h2 className="navigation__today" onClick={goToToday}>
          {/* Today */}
          {t('today')}
        </h2>
      )}
    </div>
  );
};

export default WeekNavigation;
