import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTypedSelector } from 'store';

type Props = {
  children: React.ReactNode;
};

const AuthLoyout: React.FC<Props> = ({ children }) => {
  const history = useHistory();

  const { isAuth } = useTypedSelector(state => state.authUser);

  useEffect(() => {
    if (isAuth) {
      history.push('/');
    }
    // eslint-disable-next-line
  }, [isAuth]);

  return (
    <div className="auth-layout">
      <div className="auth-layout__container">{children}</div>
    </div>
  );
};

export default AuthLoyout;
