import React, { useEffect, useMemo, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from 'store';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import clsx from 'clsx';
// import ReactLoading from 'react-loading';

import LoadingMask from 'components/LoadingMask';
import DroppableWrapper from 'components/DndWrappers/Droppable';
import Item from './Item';

import { isPreviosDate } from 'utils/date';

import { getTasksForDay } from 'store/tasks/selectors';
import { fetchDayTasks } from 'store/tasks/thunkActions';
import { closeTask, openTask } from 'store/core';
import { getIsMobileScreen } from 'store/core/selectors';

import { OpenedTaskState } from 'types/store/core';

const DayBody = () => {
  const {
    params: { dayInMs },
  } = useRouteMatch<{ dayInMs: string }>();
  const dispatch = useTypedDispatch();
  const [t] = useTranslation();

  const currentDay = useTypedSelector(state => state.dateData.currentDay);
  const tasks = useTypedSelector(state => getTasksForDay(state, currentDay));
  const openedTask = useTypedSelector(state => state.core.openedTask);
  const isMobileScreen = useTypedSelector(state => getIsMobileScreen(state));

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isAbleToAddTasks = !isPreviosDate(currentDay);

  const doesOneOfTheTasksHaveTime = useMemo(() => {
    return tasks.some(el => el.dueTime);
  }, [tasks]);

  const onOpenTask = (payload: OpenedTaskState) => dispatch(openTask(payload));
  const onCloseTask = () => dispatch(closeTask());

  const onFetchTasks = async () => {
    setIsLoading(true);

    const fetchedTasks = await dispatch(fetchDayTasks(+dayInMs));

    if (fetchedTasks.length === 0) {
      onCloseTask();
    }
    setIsLoading(false);
  };

  const onClickAddButton = () => {
    onOpenTask({ type: 'new', dueDate: currentDay, showInModal: isMobileScreen });

    const dayContainer = document.querySelector<HTMLDivElement>('.day');
    dayContainer?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    onFetchTasks();
    // eslint-disable-next-line
  }, [dayInMs]);

  useEffect(() => {
    return () => {
      onCloseTask();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={clsx('day-body', {
        'day-body--without-time': !doesOneOfTheTasksHaveTime,
      })}
    >
      <DroppableWrapper
        droppableId={currentDay || 'day'}
        isDropDisabled={isPreviosDate(currentDay)}
        className="day-body__list"
      >
        {tasks.map((task, index) => (
          <Item
            key={task._id}
            item={task}
            index={index}
            columnDate={task.dueDate}
            isSelected={openedTask._id === task._id}
            isTransferred={currentDay !== task.dueDate}
          />
        ))}
      </DroppableWrapper>

      {isAbleToAddTasks ? (
        <div className="day-body__add">
          <Button type="primary" onClick={onClickAddButton}>
            + {t('addTask')}
          </Button>
        </div>
      ) : tasks.length === 0 ? (
        <p className="day-body__notasks">No tasks</p>
      ) : null}

      <LoadingMask isLoading={isLoading} />
    </div>
  );
};

export default DayBody;
