import React from 'react';
import { useTypedDispatch, useTypedSelector } from 'store';
import { Drawer } from 'antd';
import { MenuUnfoldOutlined } from '@ant-design/icons';

import MainSidebar from 'layouts/Main/Sidebar';
import SettingsSidebar from 'layouts/Settings/Sidebar';
import Stats from 'layouts/Main/Header/Stats';
import UserAvatar from 'components/UserAvatar';

import { setIsDrawerOpened } from 'store/core';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { useLocation, useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';

import { getWeek } from 'utils/date';
import { setTasks } from 'store/tasks';
import { setNotes } from 'store/notes';

const MobileHeader = () => {
  const {
    params: { dayInMs },
  } = useRouteMatch<{ dayInMs: string }>();
  const location = useLocation();
  const dispatch = useTypedDispatch();
  const history = useHistory();

  const { pathname } = useLocation();

  const isDrawerOpened = useTypedSelector(state => state.core.isDrawerOpened);

  const onSetisDrawerOpened = (payload: boolean) => dispatch(setIsDrawerOpened(payload));

  const currentPage = pathname.split('/')[1];

  const openWeekPage = () => {
    if (currentPage === 'week') return;

    dispatch(setTasks([]));
    dispatch(setNotes([]));
    const dayMilliseconds = dayInMs ? +dayInMs : new Date().getTime();
    const day = getWeek('current', dayMilliseconds)[0].valueOf();
    history.push(`/week/${day}`);
  };

  return (
    <div className="mobile-header">
      <MenuUnfoldOutlined className="mobile-header__unfold" onClick={() => onSetisDrawerOpened(true)} />

      <Stats onlyPlanning />
      <div className="mobile-header__avatar">
        <UserAvatar />
      </div>

      <Drawer
        forceRender
        className="drawer"
        title={
          <div onClick={openWeekPage}>
            <img
              className="mobile-header__logo"
              src={require('assets/img/pomodizer-logo.png')}
              alt="logo"
            />
          </div>
        }
        placement="left"
        width="80%"
        closable={false}
        closeIcon={<CloseIcon />}
        visible={isDrawerOpened}
        onClose={() => onSetisDrawerOpened(false)}
      >
        {location.pathname.includes('settings') ? (
          <SettingsSidebar withAdditionalLinks />
        ) : (
          <MainSidebar />
        )}
      </Drawer>
    </div>
  );
};

export default MobileHeader;
