import React from 'react';
import { Trans } from 'react-i18next';

import { getWeekStats } from 'store/stats/selectors';
import { useTypedSelector } from 'store';

const WeekStats = () => {
  const weekStats = useTypedSelector(state => getWeekStats(state));

  return (
    <div className="aside__user user">
      <div className="user__body">
        <div className="user__stat">
          <div className="user__item">
            <div className="user__number">{weekStats.doneTasks}</div>
            <div className="user__desc">
              <Trans i18nKey="completedTasks" />
            </div>
          </div>
          <div className="user__item">
            <div className="user__number">{weekStats.tomatoFacts}</div>
            <div className="user__desc">
              <Trans i18nKey="earnedPomidors" />
            </div>
          </div>
          <div className="user__item">
            <div className="user__number">{weekStats.planningQuality}</div>
            <div className="user__desc">
              <Trans i18nKey="planningQuality" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeekStats;
