import React from 'react';
import { Checkbox, Col, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useTypedDispatch, useTypedSelector } from 'store';
import moment from 'moment';
import i18next from 'i18next';

import { momentLocales } from 'utils';
import { setLanguage } from 'utils/userStorage';

import { patchUser } from 'store/user/thunkActions';

import { ReactComponent as CaretUpIcon } from 'assets/icons/caret-up.svg';

const AppSettings = () => {
  const [t] = useTranslation();
  const dispatch = useTypedDispatch();

  const { language: activeLanguage, soundSettings } = useTypedSelector(state => state.authUser.user);

  const onChangeLanguage = async (language: string) => {
    moment.locale(momentLocales[language]);
    await dispatch(patchUser({ language }));
    setLanguage(language);
    i18next.changeLanguage(language);
  };

  const onCheckboxClick = (field: 'start' | 'finish' | 'tick') => {
    dispatch(
      patchUser({
        soundSettings: { ...soundSettings, [field]: soundSettings ? !soundSettings[field] : true },
      })
    );
  };

  return (
    <div className="settings-form settings-form--app-settings">
      <div className="settings-form__head">
        <h1 className="settings-form__title">{t('appSettings')}</h1>
      </div>

      <div className="settings-form__card">
        <Row gutter={24} className="settings-form__row">
          <Col span={12} className="settings-form__item-name">
            <p>{t('language')}</p>
          </Col>
          <Col span={12}>
            <Select
              suffixIcon={<CaretUpIcon />}
              value={activeLanguage}
              onChange={value => onChangeLanguage(value)}
            >
              <Select.Option value="en">EN</Select.Option>
              <Select.Option value="ru">RU</Select.Option>
              <Select.Option value="ukr">UA</Select.Option>
            </Select>
          </Col>
        </Row>

        <Row gutter={24} className="settings-form__row">
          <Col span={12} className="settings-form__item-name">
            <p>{t('tickSound')}</p>
          </Col>
          <Col span={12}>
            <Checkbox
              className="checkbox"
              checked={soundSettings?.tick}
              onChange={() => onCheckboxClick('tick')}
            />
          </Col>
        </Row>
        <Row gutter={24} className="settings-form__row">
          <Col span={12} className="settings-form__item-name">
            <p>{t('startSound')}</p>
          </Col>
          <Col span={12}>
            <Checkbox
              className="checkbox"
              checked={soundSettings?.start}
              onChange={() => onCheckboxClick('start')}
            />
          </Col>
        </Row>
        <Row gutter={24} className="settings-form__row">
          <Col span={12} className="settings-form__item-name">
            <p>{t('finishSound')}</p>
          </Col>
          <Col span={12}>
            <Checkbox
              className="checkbox"
              checked={soundSettings?.finish}
              onChange={() => onCheckboxClick('finish')}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AppSettings;
