import React, { useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { Button, Input, Row, Modal } from 'antd';
import { useTypedDispatch, useTypedSelector } from 'store';
import { useTranslation } from 'react-i18next';

import ColorSelect from 'components/ColorSelect';
import LoadingMask from 'components/LoadingMask';
import FieldWithValidation from 'components/FieldWithValidation';

import apiClient from 'utils/feathersClient';

import { createTag, patchTag } from 'store/tags/thunkActions';
import { closeTag } from 'store/core';

import { TagsItem } from 'types/global';

const TagForm = () => {
  const dispatch = useTypedDispatch();
  const [t] = useTranslation();

  const { _id: id } = useTypedSelector(state => state.core.openedTag);

  const [tag, setTag] = useState<Partial<TagsItem>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isEdit: boolean = Boolean(id) || Boolean(tag._id);

  const onCreateTag = (values: Partial<TagsItem>) => dispatch(createTag(values));
  const onPatchTag = (values: Partial<TagsItem>) => dispatch(patchTag(id, values));
  const onCloseTag = () => dispatch(closeTag());

  const openDeletePopup = () => {
    Modal.confirm({
      title: 'Are you sure you want to archive this tag?',
      centered: true,
      okText: 'archive',
      cancelText: 'cancel',
      okButtonProps: { className: 'ant-btn-primary ant-btn-dangerous' },
      cancelButtonProps: { className: 'ant-btn-primary' },
      onOk: () => onPatchTag({ isArchived: true }),
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result: TagsItem = await apiClient.service('tags').get(id);
      setTag(result);
      setIsLoading(false);
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  return (
    <Form
      onSubmit={isEdit ? onPatchTag : onCreateTag}
      initialValues={tag}
      render={({ handleSubmit, valid }) => (
        <div className="tag-form">
          <LoadingMask isLoading={isLoading} zIndex={100} />

          <div className="tag-form__head">
            <h2 className="form-title">{isEdit ? t('editProject') : t('createProject')}</h2>
          </div>

          <div className="tag-form__body">
            <Row className="form-margin">
              <Field name="name" validate={val => !val}>
                {({ input, meta }) => (
                  <FieldWithValidation meta={meta}>
                    <Input {...input} placeholder={t('title')} />
                  </FieldWithValidation>
                )}
              </Field>
            </Row>

            <Row className="form-margin">
              <Field name="color">
                {({ input }) => <ColorSelect {...input} placeholder={t('color')} />}
              </Field>
            </Row>

            {isEdit ? (
              <Row justify="center" className="form-buttons-row">
                <Button className="mr20 form-button" danger type="primary" onClick={openDeletePopup}>
                  {t('archive')}
                </Button>
                <Button className="form-button" type="primary" disabled={!valid} onClick={handleSubmit}>
                  {t('edit')}
                </Button>
              </Row>
            ) : (
              <Row justify="center" className="form-buttons-row">
                <Button className="mr20 form-button" danger type="primary" onClick={onCloseTag}>
                  {t('cancel')}
                </Button>
                <Button className="form-button" type="primary" disabled={!valid} onClick={handleSubmit}>
                  {t('create')}
                </Button>
              </Row>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default TagForm;
