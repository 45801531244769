import { Input } from 'antd';
import React, { useRef, useState } from 'react';
import { CommentsItem, UploadsItem } from 'types/global';
import { SendOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import Item from './Item';

type Props = {
  items: CommentsItem[];
  onChangeItems: (comments: Partial<CommentsItem>[]) => void;
};

const TaskComments: React.FC<Props> = ({ items, onChangeItems }) => {
  const [t] = useTranslation();

  const addInputRef = useRef<Input>(null);

  const [addText, setAddText] = useState<string>('');

  const createItem = () => {
    if (addText === '') return;

    const newComment = {
      text: addText,
      uploads: [] as UploadsItem[],
      createdAt: new Date(),
    };
    onChangeItems([newComment, ...items]);
    setAddText('');
  };

  const patchItem = (index: number, item: Partial<CommentsItem>) => {
    const newItems = items.map((el, i) => {
      if (i === index) {
        return { ...el, ...item };
      }
      return el;
    });
    onChangeItems(newItems);
  };

  const removeItem = (index: number) => {
    const newItems = items.filter((_, i) => i !== index);
    onChangeItems(newItems);
  };

  return (
    <div className="task-comments">
      <h2 className="task-comments__title">{t('addComment')}</h2>
      <div className="task-comments__add-input">
        <Input
          ref={addInputRef}
          value={addText}
          onChange={e => setAddText(e.target.value)}
          onPressEnter={() => createItem()}
        />
        <SendOutlined onClick={() => createItem()} />
      </div>

      <div className="task-comments__body">
        {Array.isArray(items)
          ? items.map((comment, index) => (
              <Item
                key={index}
                item={comment}
                onPatchItem={item => patchItem(index, item)}
                onRemoveItem={() => removeItem(index)}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default TaskComments;
