import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useTypedDispatch, useTypedSelector } from 'store';

import { camelToPascal } from 'utils';

import { setIsDrawerOpened } from 'store/core';
import { getIsMobileScreen } from 'store/core/selectors';

const sidebarItems = ['accountDetails', 'security', 'appSettings', 'projects', 'goals'];

type Props = {
  withAdditionalLinks?: boolean;
};

const SettingsSidebar: React.FC<Props> = ({ withAdditionalLinks = false }) => {
  const [t] = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useTypedDispatch();

  const isMobileScreen = useTypedSelector(getIsMobileScreen);

  const isItemActive = (name: string): boolean => {
    const pascaled = camelToPascal(name);

    return location.pathname.includes(pascaled);
  };

  const onClickLink = (to: string) => {
    if (isMobileScreen) {
      dispatch(setIsDrawerOpened(false));
    }

    history.push(to);
  };

  return (
    <div className="settings-sidebar">
      <div className="settings-sidebar__body">
        {withAdditionalLinks && (
          <div className="settings-sidebar__block">
            <h2 className="settings-sidebar__title">PAGES</h2>

            <li
              className="settings-sidebar__item"
              onClick={() => onClickLink('/day/' + new Date().getTime())}
            >
              Home
            </li>
          </div>
        )}

        <div className="settings-sidebar__block">
          <h2 className="settings-sidebar__title">SETTINGS</h2>
          {sidebarItems.map(itemName => (
            <li
              key={itemName}
              className={clsx('settings-sidebar__item', {
                'settings-sidebar__item--active': isItemActive(itemName),
              })}
              onClick={() => onClickLink('/settings/' + camelToPascal(itemName))}
            >
              {t(itemName)}
            </li>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SettingsSidebar;
