import React from 'react';
import { RouteProps } from 'react-router';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useTypedSelector } from 'store';

type Props = RouteProps & {
  component: React.FC<any>;
  layout: React.FC<any>;
  isProtected?: boolean;
};

const AppRoute: React.FC<Props> = ({ component: Component, layout: Layout, isProtected, ...rest }) => {
  const location = useLocation();

  const { isAuth, user } = useTypedSelector(state => state.authUser);

  const renderRouter = () => (
    <Route
      {...rest}
      render={routeProps => (
        <Layout>
          <Component {...routeProps} />
        </Layout>
      )}
    />
  );

  if (isProtected) {
    if (isAuth && (!user || !user.isVerified) && !location.pathname.includes('resend-verify')) {
      return (
        <Redirect
          to={{
            pathname: '/resend-verify',
          }}
        />
      );
    }
    return isAuth ? (
      renderRouter()
    ) : (
      <Redirect
        to={{
          pathname: '/login',
        }}
      />
    );
  }

  return renderRouter();
};

export default AppRoute;
