import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'antd';
import { FORM_ERROR } from 'final-form';

import FieldWithValidation from 'components/FieldWithValidation';

import { isEmail, splitFullName } from 'utils';
import apiClient from 'utils/feathersClient';

import { ValidationErrorsObject } from 'types/global';

const Signup = () => {
  const [t] = useTranslation();
  const history = useHistory();

  const onSubmit = async (values: { email: string; password: string; name: string }) => {
    const [f, l] = splitFullName(values.name);
    const data = {
      email: values.email,
      password: values.password,
      firstName: f,
      lastName: l,
    };

    try {
      await apiClient.service('users').create(data);
      return history.push('/check-verify');
    } catch (e) {
      return { [FORM_ERROR]: e.message };
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, submitError, submitting }) => (
        <form className="auth-form auth-form--signup" onSubmit={handleSubmit}>
          <div className="auth-form__title">{t('signUp')}</div>

          {submitError && <div className="auth-form__error">{submitError}</div>}

          <div className="auth-form__fields">
            <Field name="name">
              {({ input, meta }) => (
                <FieldWithValidation meta={meta} errorText={t(meta.error)}>
                  <Input {...input} placeholder={t('name')} />
                </FieldWithValidation>
              )}
            </Field>
            <Field name="email">
              {({ input, meta }) => (
                <FieldWithValidation meta={meta} errorText={t(meta.error)}>
                  <Input {...input} type="email" placeholder="Email" />
                </FieldWithValidation>
              )}
            </Field>
            <Field name="password">
              {({ input, meta }) => (
                <FieldWithValidation meta={meta} errorText={t(meta.error)}>
                  <Input.Password {...input} placeholder={t('password')} />
                </FieldWithValidation>
              )}
            </Field>
          </div>

          <Button className="auth-form__button" type="primary" htmlType="submit" loading={submitting}>
            {t('signUpVerb')}
          </Button>
          <div className="auth-form__footer-text">
            {t('alreadyHaveAccount')} <Link to="/login">{t('logInVerb')}</Link>
          </div>
        </form>
      )}
    />
  );
};

type FormState = {
  email: string;
  password: string;
  name: string;
};

const validate = (values: Partial<FormState>) => {
  const errors: ValidationErrorsObject<FormState> = {};

  if (!values.email) {
    errors.email = 'required';
  }
  if (values.email && !isEmail(values.email)) {
    errors.email = 'invalidEmail';
  }
  if (!values.password || values.password.trim().length < 3) {
    errors.password = 'minLength';
  }
  if (!values.name || (values.name && values.name.trim().split(' ')[0].length < 3)) {
    errors.name = 'invalidFirstname';
  }

  return errors;
};

export default Signup;
