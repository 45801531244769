import React, { useEffect, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from 'store';
import clsx from 'clsx';
import { Dropdown, Menu, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import ActionHistory from './ActionHistory';
import ImplicitInput from 'components/ImplicitInput';
import Checklist from 'components/Checklist';
import TaskUploads from 'components/TaskUploads';
import TaskComments from 'components/TaskComments';
import LoadingMask from 'components/LoadingMask';

import apiClient from 'utils/feathersClient';

import { startTimer, stopTimer } from 'store/timer/thunkActions';
import { patchTask, removeTask } from 'store/tasks/thunkActions';
import { closeTask } from 'store/core';
import { getIsMobileScreen } from 'store/core/selectors';

import { TasksItem } from 'types/global';

import { ReactComponent as PlayButtonIcon } from 'assets/icons/play-button.svg';
import { ReactComponent as StopButtonIcon } from 'assets/icons/stop-button.svg';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/check-mark.svg';
import { ReactComponent as ThreeDotsIcon } from 'assets/icons/three-dots.svg';
import { ReactComponent as TomatoIcon } from 'assets/icons/tomato.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as FlagIcon } from 'assets/icons/flag.svg';

type Props = {
  whenTaskDeleted?: (task: TasksItem) => void;
  isAttachedToDaypage?: boolean;
};

const TaskView: React.FC<Props> = ({ isAttachedToDaypage = false, whenTaskDeleted }) => {
  const dispatch = useTypedDispatch();
  const [t] = useTranslation();

  const { _id: id, isActive } = useTypedSelector(state => state.core.openedTask);
  const timer = useTypedSelector(state => state.timer);
  const isMobileScreen = useTypedSelector(state => getIsMobileScreen(state));

  const [task, setTask] = useState<Partial<TasksItem>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isTaskDone = task.status === 'done';
  const isTaskStarted = timer.taskId === task._id && timer.isStarted;

  const onPatchTask = (payload: Partial<TasksItem>) => dispatch(patchTask(id, payload, false));
  const onStartTimer = () => dispatch(startTimer(task));
  const onRemoveTask = () => dispatch(removeTask(task._id));
  const onCloseTask = () => dispatch(closeTask());
  const onStopTimer = () => {
    dispatch(stopTimer());
    setTask({ ...task, tomatoFact: task.tomatoFact + 1 });
  };

  const onPressFinish = () => {
    const newStatus = isTaskDone ? 'wait' : 'done';
    onPatchTask({ status: newStatus });
    setTask({ ...task, status: newStatus });
  };

  const handleTogglePriority = () => {
    onPatchTask({
      ...task,
      priority: task.priority !== 'urgent' ? 'urgent' : 'medium',
    })
    setTask({ ...task, priority: task.priority !== 'urgent' ? 'urgent' : 'medium', });
  }

  const checklistChanged = (checklist: TasksItem['checklist']) => {
    onPatchTask({
      checkListId: task.checkListId,
      checklist: checklist,
    });
    setTask({ ...task, checklist });
  };

  const onFieldChange = (field: string, value: any) => {
    if (field === 'name' && !value) return;

    onPatchTask({ [field]: value });
    setTask({ ...task, [field]: value });
  };

  const onDropdownClick = ({ key }: any) => {
    if (key === 'delete') {
      Modal.confirm({
        title: 'Are you sure you want to delete it?',
        centered: true,
        okText: 'delete',
        cancelText: 'cancel',
        okButtonProps: { className: 'ant-btn-primary ant-btn-dangerous' },
        cancelButtonProps: { className: 'ant-btn-primary' },
        onOk: () => {
          onRemoveTask();
          onCloseTask();
          if (whenTaskDeleted) whenTaskDeleted(task as TasksItem);
        },
        icon: null,
      });
    }
  };

  useEffect(() => {
    if (isActive) {
      const fetchData = async () => {
        setIsLoading(true);
        const task = await apiClient.service('tasks').get(id);
        setTask(task);
        setIsLoading(false);
      };

      fetchData();
    }
  }, [id, isActive]);

  return (
    <div className="task-view">
      <LoadingMask isLoading={isLoading} />

      {isAttachedToDaypage && (
        <CloseIcon className="task-view__close" onMouseDown={() => onCloseTask()} />
      )}

      <div className="task-view__head">
        <div className="task-view__title">
          <ImplicitInput type="name" value={task.name} onChange={onFieldChange} />
        </div>


        <Dropdown
          trigger={['click']}
          overlayClassName="task-view__dropdown"
          overlay={
            <Menu onClick={onDropdownClick}>
              <Menu.Item key="delete">{t('deleteTask')}</Menu.Item>
            </Menu>
          }
        >
          <ThreeDotsIcon className="task-view__three-dots" />
        </Dropdown>
      </div>

      <div className="task-view__urgent-wrapper" onClick={handleTogglePriority}>
        {task?.priority === 'urgent' && (
          <div className='task-view__flag'>
            <FlagIcon stroke="red" width="16px" />
          </div>
        )}
        {task?.priority !== 'urgent' && (
          <div className='task-view__flag'>
            <FlagIcon stroke="#485a63" width="16px" />
          </div>
        )}
        <div className="">
          {task?.priority !== 'urgent'
            ? t('mark_as_urgent')
            : t('remove_urgency')}
        </div>
      </div>

      <div className="task-view__description-wrapper">
        <ImplicitInput
          type="description"
          placeholder={t('description')}
          value={task.description}
          onChange={onFieldChange}
        />
      </div>

      {isMobileScreen && (
        <div className="task-view__tags task-view__mobile-tags">
          <ImplicitInput type="tags" value={task.tags} onChange={onFieldChange} />
        </div>
      )}

      <div className="task-view__info">
        <div className="task-view__tomatoes">
          <TomatoIcon />
          <ImplicitInput type="tomatoFact" value={task.tomatoFact} onChange={onFieldChange} />
        </div>
        {!!task.dueTime && (
          <div className="task-view__time">
            <ClockIcon />
            <ImplicitInput type="dueTime" value={task.dueTime} onChange={onFieldChange} />
          </div>
        )}
        {!isMobileScreen && (
          <div className="task-view__tags">
            <ImplicitInput type="tags" value={task.tags} onChange={onFieldChange} />
          </div>
        )}
        <div className="task-view__date">
          <ImplicitInput type="dueDate" value={task.dueDate} onChange={onFieldChange} />
        </div>
      </div>
      <div className="task-view__checklist">
        <Checklist checklistObj={task.checklist} onChangeChecklist={checklistChanged} />
      </div>

      <div className="task-view__uploads">
        <TaskUploads items={task.uploads} onChange={vals => onFieldChange('uploads', vals)} />
      </div>

      <div className="task-view__comments">
        <TaskComments items={task.comments} onChangeItems={vals => onFieldChange('comments', vals)} />
      </div>

      <div className="task-view__actions">
        <div className="task-view__play">
          {isTaskStarted ? (
            <StopButtonIcon
              className={clsx('stop-icon', 'task-view__stop-button')}
              onClick={onStopTimer}
            />
          ) : (
            <PlayButtonIcon
              className={clsx('play-icon', 'task-view__play-button')}
              onClick={onStartTimer}
            />
          )}
          {t('startTask')}
        </div>
        <div className="task-view__finish">
          {task.status === 'done' ? t('done') : t('markAsDone')}
          <CheckMarkIcon
            className={clsx('check-icon', 'task-view__check-mark', {
              'check-icon--done': isTaskDone,
            })}
            onClick={onPressFinish}
          />
        </div>
      </div>
      <ActionHistory items={task.tasksHistory} />
    </div>
  );
};

export default TaskView;
