import { patchTask } from 'store/tasks/thunkActions';
import { setTimer, decreaseTimer, setIsTimerPaused, resetTimer } from 'store/timer';

import { TasksItem } from 'types/global';
import { AppThunk, PromiseThunk } from 'types/store/common';

let timerInterval: NodeJS.Timeout = null;
const bellSound = new Audio(require('../../assets/sounds/timer-bell.mp3'));
const tickSound = new Audio(require('../../assets/sounds/timer-tick-short.mp3'));

export const startTimer = (task: Partial<TasksItem>): AppThunk => (dispatch, getState) => {
  const timer = getState().timer;
  const { soundSettings } = getState().authUser.user;

  if (timer.isStarted) {
    dispatch(resetTimer());
    clearInterval(timerInterval);
  }

  const payload = {
    taskId: task._id,
    tomatoPlan: task.tomatoPlan,
    tomatoFact: task.tomatoFact,
    taskName: task.name,
  };
  dispatch(setTimer(payload));

  if (soundSettings?.start) {
    tickSound.play();
  }

  timerInterval = setInterval(() => dispatch(tick()), 1000);
};

export const pauseTimer = (): AppThunk => (dispatch, getState) => {
  const timer = getState().timer;

  if (timer.isPaused) {
    timerInterval = setInterval(() => dispatch(tick()), 1000);
    dispatch(setIsTimerPaused(false));
    dispatch(tick());
  } else {
    dispatch(setIsTimerPaused(true));
    clearInterval(timerInterval);
  }
};

export const stopTimer = (): PromiseThunk => async (dispatch, getState) => {
  const { taskId, tomatoFact } = getState().timer;
  // const { soundSettings } = getState().authUser.user;

  await dispatch(patchTask(taskId, { tomatoFact: tomatoFact + 1 }, false));
  dispatch(resetTimer());
  clearInterval(timerInterval);

  bellSound.play();

  // if (soundSettings?.finish) {
  //   bellSound.play();
  // }
};

const tick = (): AppThunk => (dispatch, getState) => {
  const timeInSeconds = getState().timer.timeInSeconds;
  const { soundSettings } = getState().authUser.user;

  if (timeInSeconds <= 0) {
    dispatch(stopTimer());
  } else {
    dispatch(decreaseTimer());

    if (soundSettings?.tick) {
      tickSound.play();
    }
  }
};
