import React, { useEffect, useState, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedDispatch, useTypedSelector } from 'store';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

import GoalsItem from './Item';
import DroppableWrapper from 'components/DndWrappers/Droppable';
import LoadingMask from 'components/LoadingMask';

import { isPreviousWeek } from 'utils/date';

import { openGoal } from 'store/core';
import { fetchGoals } from 'store/goals/thunkActions';
import { getActiveGoal } from 'store/goals/selectors';

import { OpenedGoalState } from 'types/store/core';

type Props = {
  className?: string;
};

interface GoalWrapperProps {
  children?: ReactNode;
}

const GoalItemWrapper = ({ children }: GoalWrapperProps) => {
  return <div className="goals__item-wrapper">{children}</div>;
};

const GoalsWrapper: React.FC<Props> = ({ className = '' }) => {
  const [t] = useTranslation();
  const dispatch = useTypedDispatch();
  const { dayInMs } = useParams<{ dayInMs: string }>();

  const goals = useTypedSelector(state => getActiveGoal(state));

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onOpenGoal = (payload: OpenedGoalState) => dispatch(openGoal(payload));
  const onFetchGoals = async (date: number) => {
    setIsLoading(true);
    await dispatch(fetchGoals(date));
    setIsLoading(false);
  };

  useEffect(() => {
    onFetchGoals(+dayInMs);
    // eslint-disable-next-line
  }, [dayInMs]);

  return (
    <div className={clsx('goals', className)}>
      <LoadingMask isLoading={isLoading} zIndex={20} width={40} height={40} opacity={0.5} />

      <div className="goals__scroll">
        <DroppableWrapper
          type="GOALS"
          direction="horizontal"
          droppableId="goals"
          className={clsx('goals__body', { 'goals__body--filled': goals.length >= 4 })}
        >
          {!isPreviousWeek(+dayInMs) && (
            <div
              className="goals__add-item"
              onClick={() => onOpenGoal({ type: 'new', dueDate: undefined })}
            >
              <span>+ {t('addGoal')}</span>
            </div>
          )}
          {goals.map((item, index) => (
            <GoalItemWrapper>
              <GoalsItem
                key={item._id}
                item={item}
                index={index}
                onClick={() => onOpenGoal({ type: 'edit', _id: item._id })}
                onClickExtra={() => console.log('extra clicked')}
              />
            </GoalItemWrapper>
          ))}
        </DroppableWrapper>
      </div>
    </div>
  );
};

export default GoalsWrapper;
