import { useEffect, useState, useRef } from 'react';

export default function useIsClickOutside() {
  const [isClickOutside, setIsClickOutside] = useState(false);
  const ref = useRef(null);

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsClickOutside(true);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    const isIgnore = event.target && (event.target as HTMLElement).id === 'ignore-click';
    if (!isIgnore && ref.current && !ref.current.contains(event.target)) {
      setIsClickOutside(true);
    } else {
      setIsClickOutside(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, isClickOutside };
}
