import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, message, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useTypedDispatch, useTypedSelector } from 'store';

import LoadingMask from 'components/LoadingMask';

import { openTag } from 'store/core';
import { removeTag, patchTag } from 'store/tags/thunkActions';

import { OpenedTagState } from 'types/store/core';
import { TagsItem } from 'types/global';

const Projects = () => {
  const [t] = useTranslation();
  const dispatch = useTypedDispatch();

  const windowWidth = useTypedSelector(state => state.core.windowWidth);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [patchingTagId, setPatchingTagId] = useState<string>(null);
  const [isArchivedTagsVisible, setVisibleArchivedTags] = useState(true);
  const tags = useTypedSelector(state => state.tags);

  const handleShowArchived = () => {
    setVisibleArchivedTags(!isArchivedTagsVisible);
  };

  const projectColSpan = useMemo(() => {
    if (windowWidth < 576) return 24;
    if (windowWidth < 1440) return 8;
    return 6;
  }, [windowWidth]);

  const onOpenTag = (payload: OpenedTagState) => dispatch(openTag(payload));
  const onPatchTag = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    id: string,
    payload: Partial<TagsItem>
  ) => {
    e.stopPropagation();
    try {
      dispatch(patchTag(id, payload));
      setPatchingTagId(id);
    } catch (e) {
      message.error(
        <div>
          <h3>Error while editing project</h3>
          <span>{e.message}</span>
        </div>
      );
    } finally {
      setPatchingTagId(null);
    }
  };

  const handleTagRemove = (id: string) => {
    dispatch(removeTag(id));
  };

  const openDeletePopup = (e: React.MouseEvent<HTMLElement, MouseEvent>, id: string) => {
    e.stopPropagation();

    Modal.confirm({
      title: 'Are you sure you want to delete this tag?',
      centered: true,
      okText: 'delete',
      cancelText: 'cancel',
      okButtonProps: { className: 'ant-btn-primary ant-btn-dangerous' },
      cancelButtonProps: { className: 'ant-btn-primary' },
      onOk: () => handleTagRemove(id),
    });
  };

  const handleCreateProject = (params: any) => {
    dispatch(openTag(params));
  };

  return (
    <div className="settings-form settings-form--app-settings">
      <div className="settings-form__head">
        <h1 className="settings-form__title">{t('projects')}</h1>
        <Button onClick={() => handleCreateProject({ type: 'new' })}>{t('addProject')}</Button>
      </div>

      <div className="settings-form__card1">
        <LoadingMask isLoading={isLoading} zIndex={100} />

        <Row gutter={[20, 20]}>
          {tags.map(tag => (
            <Col
              span={projectColSpan}
              key={tag._id}
              onClick={() => onOpenTag({ _id: tag._id, type: 'edit' })}
              className={isArchivedTagsVisible && tag.isArchived ? 'hidden' : ''}
            >
              <div className="settings-form__project">
                <div className="settings-form__project__name">{tag.name}</div>
                <div className="settings-form__project__color" style={{ color: tag.color }}>
                  {tag.color}
                  <span
                    className="settings-form__project__color-dot"
                    style={{ backgroundColor: tag.color }}
                  />
                </div>
                <Row className="settings-form__project__buttons">
                  {tag.isArchived ? (
                    <Button
                      type="primary"
                      loading={patchingTagId === tag._id}
                      onClick={e => onPatchTag(e, tag._id, { isArchived: false })}
                    >
                      {t('unarchive')}
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      className="button-warn"
                      loading={patchingTagId === tag._id}
                      onClick={e => onPatchTag(e, tag._id, { isArchived: true })}
                    >
                      {t('archive')}
                    </Button>
                  )}
                  <Button type="primary" danger onClick={e => openDeletePopup(e, tag._id)}>
                    {t('delete')}
                  </Button>
                </Row>
              </div>
            </Col>
          ))}
        </Row>
        <Button className="settings-form__show__archived" onClick={handleShowArchived}>
          {isArchivedTagsVisible ? t('showArchived') : t('hideArchived')}
        </Button>
      </div>
    </div>
  );
};

export default Projects;
