import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import ReactLoading from 'react-loading';
import debounce from 'lodash.debounce';
import TagManager from 'react-gtm-module'

import Routes from 'routes';
import AppHelmet from './Helmet';

import store from 'store';
import { checkIsUserAuth } from 'store/user/thunkActions';
import { setWindowWidth } from 'store/core';

import 'antd/dist/antd.css';
import 'assets/styles/app.sass';
import 'assets/styles/index.scss';

const Root = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const checkUser = async () => {
    try {
      await store.dispatch(checkIsUserAuth());
      setIsLoading(false);
    } catch (e) {
      console.error('auth failed', e);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkUser();

    const onResize = (e: UIEvent) => {
      const width = (e.target as Window).innerWidth;
      store.dispatch(setWindowWidth(width));
    };
    window.addEventListener(
      'resize',
      debounce(e => onResize(e), 200)
    );

    store.dispatch(setWindowWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-5FTHXGX' });
  }, [])

  return !isLoading ? (
    <Provider store={store}>
      <AppHelmet />
      <Routes />
    </Provider>
  ) : (
    <div className="page-loading">
      <ReactLoading type="bars" color="#69C262" />
    </div>
  );
};

export default Root;
