import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { Row, Col, Input, DatePicker, Button, Modal } from 'antd';
import moment from 'moment';
import { useTypedDispatch, useTypedSelector } from 'store';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import InputMask from 'react-input-mask';

import Comments from 'components/TaskComments';
import Checklist from 'components/Checklist';
import TagsSelect from 'components/TagsSelect';
import TaskUploads from 'components/TaskUploads';
import FieldWithValidation from 'components/FieldWithValidation';

import { clampNumber } from 'utils';
import apiClient from 'utils/feathersClient';

import { closeTask } from 'store/core';
import { createTask, patchTask, removeTask } from 'store/tasks/thunkActions';
import { getIsMobileScreen } from 'store/core/selectors';

import { TasksItem } from 'types/global';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';

type Props = {
  isAttachedToDaypage?: boolean;
  whenTaskCreated?: (task: TasksItem) => void;
  whenTaskPatched?: (task: TasksItem) => void;
  whenTaskRemoved?: (task: TasksItem) => void;
};

const TaskForm: React.FC<Props> = ({
  isAttachedToDaypage = false,
  whenTaskCreated,
  whenTaskPatched,
  whenTaskRemoved,
}) => {
  const dispatch = useTypedDispatch();
  const [t] = useTranslation();

  const { isActive, _id: id, dueDate: taskDate, initialName } = useTypedSelector(
    state => state.core.openedTask
  );
  const isMobileScreen = useTypedSelector(state => getIsMobileScreen(state));

  const [task, setTask] = useState<Partial<TasksItem>>({});

  const isEdit: boolean = Boolean(id) || Boolean(task._id);

  const onCloseTask = () => dispatch(closeTask());
  const onCreateTask = async (values: Partial<TasksItem>) => {
    const createdTask = await dispatch(createTask(values, !isAttachedToDaypage));
    if (whenTaskCreated) whenTaskCreated(createdTask);
  };
  const onPatchTask = async (values: Partial<TasksItem>) => {
    const patchedTask = await dispatch(patchTask(id, values, !isAttachedToDaypage));
    if (whenTaskPatched) whenTaskPatched(patchedTask);
  };
  const onRemoveTask = async () => {
    const removedTask = await dispatch(removeTask(id, !isAttachedToDaypage));
    if (whenTaskRemoved) whenTaskRemoved(removedTask);
  };

  const openDeletePopup = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete it?',
      centered: true,
      okText: 'delete',
      cancelText: 'cancel',
      okButtonProps: { className: 'ant-btn-primary ant-btn-dangerous' },
      cancelButtonProps: { className: 'ant-btn-primary' },
      onOk: onRemoveTask,
    });
  };

  useEffect(() => {
    if (isActive) {
      const fetchData = async () => {
        const task = await apiClient.service('tasks').get(id);
        setTask(task);
      };

      if (id) {
        fetchData();
      } else {
        setTask({
          name: initialName,
          dueDate: taskDate,
        });
      }
    }
  }, [id, taskDate, initialName, isActive]);

  return (
    <Form
      onSubmit={isEdit ? onPatchTask : onCreateTask}
      initialValues={task}
      render={({ handleSubmit, valid, form: { reset } }) => (
        <div className={clsx('task-form', { 'task-form--daypage': isAttachedToDaypage })}>
          {isAttachedToDaypage ? (
            <div className="task-form__head">
              <h2 className="task-form__title">{isEdit ? t('editTask') : t('addNewTask')}</h2>
              {isEdit ? (
                <span className="task-form__head-delete" onClick={onRemoveTask}>
                  {t('deleteTask')}
                </span>
              ) : (
                <span className="task-form__head-clear" onClick={() => reset()}>
                  {t('clear')}
                </span>
              )}
            </div>
          ) : (
            <h2 className="form-title">{isEdit ? t('editTask') : t('createTask')}</h2>
          )}

          <div className="task-form__body">
            <Row className="form-margin">
              <Field name="name" validate={val => !val}>
                {({ input, meta }) => (
                  <FieldWithValidation meta={meta}>
                    <Input {...input} placeholder={t('title')} />
                  </FieldWithValidation>
                )}
              </Field>
            </Row>

            <Row className="form-margin" gutter={{ xs: 14, md: 28 }}>
              <Col span={12}>
                <Field
                  name="dueDate"
                  allowNull
                  format={val => (val ? moment(val, 'DD.MM.YYYY') : null)}
                  parse={val => (val ? moment(val).format('DD.MM.YYYY') : null)}
                >
                  {({ input }) => (
                    <DatePicker
                      {...input}
                      placeholder={t('date')}
                      dropdownClassName="calendar-picker"
                      showToday={false}
                      mode="date"
                      suffixIcon={<CalendarIcon />}
                      allowClear={false}
                      inputReadOnly={isMobileScreen}
                    />
                  )}
                </Field>
              </Col>
              <Col span={12}>
                <Field name="dueTime" validate={v => !!v && !moment(v, 'HH:mm', true).isValid()}>
                  {({ input, meta }) => (
                    <FieldWithValidation meta={meta}>
                      <InputMask mask="99:99" {...input}>
                        {(inputProps: any) => (
                          <Input
                            {...inputProps}
                            placeholder={t('timeOptional')}
                            suffix={<ClockIcon />}
                          />
                        )}
                      </InputMask>
                    </FieldWithValidation>
                  )}
                </Field>
              </Col>
            </Row>

            <Row className="form-margin" gutter={{ xs: 14, md: 28 }}>
              <Col span={12}>
                <Field name="tomatoPlan" format={v => clampNumber(0, 7, v)}>
                  {({ input }) => <Input {...input} type="number" placeholder={t('tomato')} />}
                </Field>
              </Col>
              <Col span={12}>
                <p className="task-form__minutes">/25 {t('minutes')}</p>
              </Col>
            </Row>

            <Row className="form-margin">
              <Field name="tags">
                {({ input }) => <TagsSelect {...input} placeholder={t('project')} />}
              </Field>
            </Row>

            <Row className="form-margin">
              <Field name="checklist" format={v => v}>
                {({ input }) => (
                  <Checklist checklistObj={input.value} onChangeChecklist={input.onChange} />
                )}
              </Field>
            </Row>

            <Row className="form-margin">
              <Field name="description">
                {({ input }) => (
                  <Input.TextArea
                    {...input}
                    rows={isMobileScreen ? 3 : 6}
                    placeholder={t('descriptionOptional')}
                  />
                )}
              </Field>
            </Row>

            <Row className="form-margin">
              <Field name="uploads">
                {({ input }) => <TaskUploads items={input.value} onChange={input.onChange} />}
              </Field>
            </Row>

            <Row className="form-margin">
              <Field name="comments">
                {({ input }) => <Comments items={input.value} onChangeItems={input.onChange} />}
              </Field>
            </Row>

            {isEdit ? (
              <Row justify="center" className="form-buttons-row">
                {isAttachedToDaypage ? (
                  <Button
                    className="mr25 form-button"
                    danger
                    type="primary"
                    onClick={() => onCloseTask()}
                  >
                    {t('cancel')}
                  </Button>
                ) : (
                  <Button className="mr25 form-button" danger type="primary" onClick={openDeletePopup}>
                    {t('delete')}
                  </Button>
                )}
                <Button className="form-button" type="primary" disabled={!valid} onClick={handleSubmit}>
                  {t('edit')}
                </Button>
              </Row>
            ) : (
              <Row justify="center" className="form-buttons-row">
                {!isAttachedToDaypage && (
                  <Button className="mr25 form-button" danger type="primary" onClick={onCloseTask}>
                    {t('cancel')}
                  </Button>
                )}
                <Button className="form-button" type="primary" disabled={!valid} onClick={handleSubmit}>
                  {isAttachedToDaypage ? `+${t('addTask')}` : t('create')}
                </Button>
              </Row>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default TaskForm;
