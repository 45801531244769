import React, { useEffect, useState } from 'react';
import { Col, Dropdown, Menu, Modal, Row, Switch } from 'antd';
import { useTypedDispatch, useTypedSelector } from 'store';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import ImplicitInput from 'components/ImplicitInput';
import LoadingMask from 'components/LoadingMask';
import Checklist from 'components/Checklist';
import QuickSelectBadge from 'components/UI/QuickSelectBadge';

import apiClient from 'utils/feathersClient';

import { patchNote, removeNote } from 'store/notes/thunkActions';
import { closeNote } from 'store/core';

import { NotesItem } from 'types/global';

import { ReactComponent as CheckMarkIcon } from 'assets/icons/check-mark.svg';
import { ReactComponent as ThreeDotsIcon } from 'assets/icons/three-dots.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';

const NoteView = () => {
  const dispatch = useTypedDispatch();
  const [t] = useTranslation();

  const { _id: id } = useTypedSelector(state => state.core.openedNote);

  const [note, setNote] = useState<Partial<NotesItem>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onPatchNote = (values: Partial<NotesItem>) => dispatch(patchNote(id, values, false));
  const onRemoveNote = () => dispatch(removeNote(id));
  const onCloseNote = () => dispatch(closeNote());

  const checklistChanged = (checklist: NotesItem['checklist']) => {
    onPatchNote({
      checkListId: note.checkListId,
      checklist: checklist,
    });
    setNote({ ...note, checklist });
  };

  const onFieldChange = (field: string, value: any) => {
    if (field === 'name' && !value) return;

    onPatchNote({ [field]: value });
    setNote({ ...note, [field]: value });
  };

  const onDropdownClick = ({ key }: any) => {
    if (key === 'delete') {
      Modal.confirm({
        title: 'Are you sure you want to delete it?',
        centered: true,
        okText: 'delete',
        cancelText: 'cancel',
        okButtonProps: { className: 'ant-btn-primary ant-btn-dangerous' },
        cancelButtonProps: { className: 'ant-btn-primary' },
        onOk: () => {
          onRemoveNote();
          onCloseNote();
        },
        icon: null,
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result: NotesItem = await apiClient.service('notes').get(id);
      setNote(result);
      setIsLoading(false);
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  return (
    <div className="note-view">
      <LoadingMask isLoading={isLoading} />

      <div className="task-view__head">
        <div className="task-view__title">
          <ImplicitInput type="name" value={note.title} onChange={onFieldChange} />
        </div>

        <Dropdown
          trigger={['click']}
          overlayClassName="task-view__dropdown"
          overlay={
            <Menu onClick={onDropdownClick}>
              <Menu.Item key="delete">{t('deleteNote')}</Menu.Item>
            </Menu>
          }
        >
          <ThreeDotsIcon className="task-view__three-dots" />
        </Dropdown>
      </div>

      <div className="task-view__checklist">
        <Checklist checklistObj={note.checklist} onChangeChecklist={checklistChanged} />
      </div>

      <Row className="form-margin" align="middle">
        <Col span={12}>
          <div className="note-form__remind">
            <Switch
              checked={note.isReminded}
              onChange={checked => onFieldChange('isReminded', checked)}
            />
            <span className="note-form__remind-text">{t('remindMe')}</span>
          </div>
        </Col>
        <Col span={12} className="note-form__check-wrapper">
          <CheckMarkIcon
            className={clsx('note-form__check-mark', {
              'note-form__check-mark--done': note.status === 'done',
            })}
            onClick={() => onFieldChange('status', note.status === 'done' ? 'active' : 'done')}
          />
        </Col>
      </Row>

      {note.isReminded && (
        <Row>
          <Col span={12}>
            <div className="note-view__date">
              <CalendarIcon />
              <ImplicitInput
                type="dueDate"
                placeholder={t('date')}
                value={note.dueDate}
                onChange={onFieldChange}
              />
            </div>
            <Row className="mt10">
              <QuickSelectBadge
                className="mr5"
                isActive={note.dueDate === moment().format('DD.MM.YYYY')}
                onClick={() => onFieldChange('dueDate', moment().format('DD.MM.YYYY'))}
              >
                {t('today')}
              </QuickSelectBadge>
              <QuickSelectBadge
                className="mr5"
                isActive={note.dueDate === moment().add(1, 'day').format('DD.MM.YYYY')}
                onClick={() => onFieldChange('dueDate', moment().add(1, 'day').format('DD.MM.YYYY'))}
              >
                {t('tomorrow')}
              </QuickSelectBadge>
              <QuickSelectBadge
                isActive={note.dueDate === moment().add(5, 'day').format('DD.MM.YYYY')}
                onClick={() => onFieldChange('dueDate', moment().add(5, 'day').format('DD.MM.YYYY'))}
              >
                {moment().add(5, 'day').format('DD MMM')}
              </QuickSelectBadge>
            </Row>
          </Col>

          <Col span={12}>
            <div className="note-view__time">
              <ClockIcon />
              <ImplicitInput
                type="dueTime"
                placeholder={t('time')}
                value={note.dueTime}
                onChange={onFieldChange}
              />
            </div>
            <Row className="mt10">
              <QuickSelectBadge
                className="mr5"
                isActive={note.dueTime === '12:00'}
                onClick={() => onFieldChange('dueTime', '12:00')}
              >
                12:00
              </QuickSelectBadge>
              <QuickSelectBadge
                className="mr5"
                isActive={note.dueTime === '15:00'}
                onClick={() => onFieldChange('dueTime', '15:00')}
              >
                15:00
              </QuickSelectBadge>
              <QuickSelectBadge
                isActive={note.dueTime === '18:00'}
                onClick={() => onFieldChange('dueTime', '18:00')}
              >
                18:00
              </QuickSelectBadge>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default NoteView;
