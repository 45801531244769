import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from 'store';

import WeekStats from 'components/WeekStats';

const UserForm = () => {
  const [t] = useTranslation();

  const stats = useTypedSelector(state => state.stats);

  return (
    <div className="stats">
      <div className="stats__graph">
        <h1>Graph</h1>
      </div>

      <div className="stats__numbers numbers">
        <div className="numbers__part">
          <p className="numbers__label">{t('tasksData')}</p>
          <div className="user__stat">
            <div className="user__item">
              <div className="user__number">{stats.weekClosed}</div>
              <div className="user__desc">
                <span>{t('closed')}</span>
              </div>
            </div>
            <div className="user__item">
              <div className="user__number">{stats.notClosed}</div>
              <div className="user__desc">
                <span>{t('opened')}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="numbers__part">
          <p className="numbers__label">{t('tomatoFact')}</p>
          <div className="user__stat">
            <div className="user__item">
              <div className="user__number">{stats.tomatoFact}</div>
              <div className="user__desc">
                <span>помидоров</span>
              </div>
            </div>
          </div>
        </div>
        <div className="numbers__part">
          <p className="numbers__label">{t('workedOut')}</p>
          <div className="user__stat">
            <div className="user__item">
              <div className="user__number">{12}</div>
              <div className="user__desc">
                <span>часов</span>
              </div>
            </div>
          </div>
        </div>

        <div className="numbers__part">
          <p className="numbers__label">{t('weekStats')}</p>
          <WeekStats />
        </div>
      </div>
    </div>
  );
};

export default UserForm;
