import React, { useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { Button, Input, Row, Switch, Tooltip, Modal, Col, DatePicker } from 'antd';
import { useTypedDispatch, useTypedSelector } from 'store';
import clsx from 'clsx';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import EmojiPicker from 'components/EmojiPicker';
import FieldWithValidation from 'components/FieldWithValidation';
import LoadingMask from 'components/LoadingMask';

import apiClient from 'utils/feathersClient';

import { createGoal, patchGoal, removeGoal } from 'store/goals/thunkActions';
import { closeGoal } from 'store/core';
import { getIsMobileScreen } from 'store/core/selectors';

import { GoalsItem } from 'types/global';

import { ReactComponent as CheckMarkIcon } from 'assets/icons/check-mark.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';

const GoalForm = () => {
  const dispatch = useTypedDispatch();
  const [t] = useTranslation();

  const { isActive, _id: id, dueDate: goalDate } = useTypedSelector(state => state.core.openedGoal);
  const isMobileScreen = useTypedSelector(state => getIsMobileScreen(state));

  const [goal, setGoal] = useState<Partial<GoalsItem>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isEdit: boolean = Boolean(id) || Boolean(goal._id);

  const onCreateGoal = (values: Partial<GoalsItem>) => {
    const data = { ...values, dueDate: values.dueDate || goalDate };
    dispatch(createGoal(data));
  };
  const onPatchGoal = (values: Partial<GoalsItem>) => dispatch(patchGoal(id, values));
  const onRemoveGoal = () => dispatch(removeGoal(id));
  const onCloseGoal = () => dispatch(closeGoal());

  const openDeletePopup = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete it?',
      centered: true,
      okText: 'delete',
      cancelText: 'cancel',
      okButtonProps: { className: 'ant-btn-primary ant-btn-dangerous' },
      cancelButtonProps: { className: 'ant-btn-primary' },
      onOk: onRemoveGoal,
    });
  };

  useEffect(() => {
    if (isActive) {
      const fetchData = async () => {
        setIsLoading(true);
        const result: GoalsItem = await apiClient.service('goals').get(id);
        setGoal(result);
        setIsLoading(false);
      };

      if (id) {
        fetchData();
      } else {
        // setGoal({ dueDate: goalDate });
      }
    }
  }, [isActive, id, goalDate]);

  return (
    <Form
      onSubmit={isEdit ? onPatchGoal : onCreateGoal}
      initialValues={goal}
      render={({ handleSubmit, valid, values, form: { change } }) => (
        <div className="goal-form">
          <LoadingMask isLoading={isLoading} zIndex={100} />

          <div className="goal-form__head">
            <h2 className="form-title goal-form__title">{isEdit ? t('editGoal') : t('createGoal')}</h2>
            {/* <p className="goal-form__info">{t('only4Goals')}</p> */}
          </div>

          <div className="goal-form__body">
            <Row className="form-margin">
              <Field name="title" validate={val => !val}>
                {({ input, meta }) => (
                  <FieldWithValidation meta={meta}>
                    <Input {...input} placeholder={t('title')} />
                  </FieldWithValidation>
                )}
              </Field>
            </Row>

            <Row className="form-margin">
              <Field name="emoji">{({ input }) => <EmojiPicker {...input} />}</Field>
            </Row>

            <Row className="form-margin">
              <Field name="description">
                {({ input }) => (
                  <Input.TextArea
                    {...input}
                    rows={isMobileScreen ? 3 : 6}
                    placeholder={t('descriptionOptional')}
                  />
                )}
              </Field>
            </Row>

            <Row justify="center" align="middle" className="form-margin">
              {/*               <Col span={12}>
                <div className="goal-form__monthly">
                  <Field name="isMonthly">
                    {({ input }) => <Switch checked={input.value} onChange={input.onChange} />}
                  </Field>
                  <span className="goal-form__monthly-text">
                    {values.isMonthly ? t('monthlyGoal') : t('weeklyGoal')}
                  </span>
                  <Tooltip title="Additional info" className="goal-form__monthly-icon">
                    <InfoIcon />
                  </Tooltip>
                </div>
              </Col> */}
              <Col span={12} className="goal-form__check-wrapper">
                {values.status === 'done' ? t('done') : t('markGoalAsDone')}
                <CheckMarkIcon
                  className={clsx('goal-form__check-mark', {
                    'goal-form__check-mark--done': values.status === 'done',
                  })}
                  onClick={() => {
                    onPatchGoal({
                      ...values,
                      status: values.status === 'active' ? 'done' : 'active',
                    });
                  }}
                />
              </Col>
            </Row>

            <Row className="form-margin">
              <Field
                name="dueDate"
                allowNull
                format={val => (val ? moment(val, 'DD.MM.YYYY') : null)}
                parse={val => (val ? moment(val).format('DD.MM.YYYY') : null)}
              >
                {({ input, meta }) => (
                  <FieldWithValidation meta={meta}>
                    <DatePicker
                      {...input}
                      placeholder={t('finish_date')}
                      dropdownClassName="calendar-picker"
                      showToday={false}
                      mode="date"
                      suffixIcon={<CalendarIcon />}
                      allowClear={false}
                      inputReadOnly={isMobileScreen}
                    />
                  </FieldWithValidation>
                )}
              </Field>
            </Row>

            {isEdit ? (
              <Row justify="center" className="form-buttons-row">
                <Button className="mr20 form-button" danger type="primary" onClick={openDeletePopup}>
                  {t('delete')}
                </Button>
                <Button className="form-button" type="primary" disabled={!valid} onClick={handleSubmit}>
                  {t('edit')}
                </Button>
              </Row>
            ) : (
              <Row justify="center" className="form-buttons-row">
                <Button className="mr20 form-button" danger type="primary" onClick={onCloseGoal}>
                  {t('cancel')}
                </Button>
                <Button className="form-button" type="primary" disabled={!valid} onClick={handleSubmit}>
                  {t('create')}
                </Button>
              </Row>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default GoalForm;
