import { createSelector } from 'reselect';

import { RootState } from 'types/store/common';

export const getWeekStats = createSelector(
  (state: RootState) => state.tasks.allTasks,
  tasks => {
    const doneTasks = tasks.filter(el => el.status === 'done').length;
    const tomatoFacts = tasks.reduce((acc, curr) => acc + curr.tomatoFact, 0);
    const planningQuality = tasks.length ? Math.round((doneTasks / tasks.length) * 100) : 0;

    return {
      doneTasks,
      tomatoFacts,
      planningQuality,
    };
  }
);
