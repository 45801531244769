import React, { useEffect, useRef, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from 'store';
import clsx from 'clsx';
import moment from 'moment';
import { useRouteMatch } from 'react-router-dom';

import Column from './Column';
import LoadingMask from 'components/LoadingMask';

import { isCurrentWeek, isTodayCol } from 'utils/date';

import { fetchWeekTasks } from 'store/tasks/thunkActions';

import { ReactComponent as GotoLeftIcon } from 'assets/icons/goto-left.svg';
import { ReactComponent as GotoRightIcon } from 'assets/icons/goto-right.svg';

const WeekTasks = () => {
  const {
    params: { dayInMs },
  } = useRouteMatch<{ dayInMs: string }>();
  const dispatch = useTypedDispatch();

  const scrollContainer = useRef<HTMLDivElement>(null);

  const currentWeek = useTypedSelector(state => state.dateData.currentWeek);
  const isSidebarCollapsed = useTypedSelector(state => state.core.isSidebarCollapsed);

  const [containerPosition, setContainerPosition] = useState<'left' | 'right'>('left');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const weekDays = currentWeek.slice(0, 5);
  const weekEnds = currentWeek.slice(5, 7);

  const onFetchWeekTasks = async (date: number) => {
    setIsLoading(true);
    await dispatch(fetchWeekTasks(date));
    setIsLoading(false);
  };

  const goToDirection = (direction: 'right' | 'left') => {
    const containerWidth = scrollContainer.current.scrollWidth;
    const moveTo = direction === 'right' ? containerWidth : 0;
    scrollContainer.current.scrollTo({
      top: 0,
      left: moveTo,
      behavior: 'smooth',
    });
    setContainerPosition(direction);
  };

  useEffect(() => {
    if (isCurrentWeek(+dayInMs)) {
      const currentDayNumber = moment().isoWeekday();
      if (currentDayNumber > 5 && !isSidebarCollapsed) {
        setTimeout(() => goToDirection('right'), 200);
      } else if (isSidebarCollapsed) {
        goToDirection('left');
      }
    } else {
      goToDirection('left');
    }
  }, [isSidebarCollapsed, dayInMs]);

  useEffect(() => {
    onFetchWeekTasks(+dayInMs);
    // eslint-disable-next-line
  }, [dayInMs]);

  return (
    <div ref={scrollContainer} className="column-scroll-wrapper">
      <LoadingMask isLoading={isLoading} zIndex={20} />

      {containerPosition === 'right' && !isSidebarCollapsed && (
        <GotoLeftIcon
          className="column-scroll-wrapper__goto-left"
          onClick={() => goToDirection('left')}
        />
      )}
      <div className="columns-wrapper">
        {weekDays.map((day, index) => (
          <Column
            key={index}
            columnDate={day}
            isToday={isTodayCol(day)}
            maxTasks={10}
            // maxTasks={getMaxTasksInColumn(day, currentWeek)}
          />
        ))}
        <div className="columns-wrapper__weekends">
          {weekEnds.map((day, index) => (
            <Column
              key={index + 5}
              className="column--weekend"
              columnDate={day}
              isToday={isTodayCol(day)}
              maxTasks={10}
              // maxTasks={getMaxTasksInColumn(day, currentWeek)}
            />
          ))}
        </div>
      </div>

      {containerPosition === 'left' && !isSidebarCollapsed && (
        <GotoRightIcon
          className="column-scroll-wrapper__goto-right"
          onClick={() => goToDirection('right')}
        />
      )}

      {!isSidebarCollapsed && (
        <div className="column-scroll-wrapper__slider-dots">
          <div
            className={clsx('column-scroll-wrapper__slider-dots-item', {
              'column-scroll-wrapper__slider-dots-item--active': containerPosition === 'left',
            })}
            onClick={() => containerPosition === 'right' && goToDirection('left')}
          />
          <div
            className={clsx('column-scroll-wrapper__slider-dots-item', {
              'column-scroll-wrapper__slider-dots-item--active': containerPosition === 'right',
            })}
            onClick={() => containerPosition === 'left' && goToDirection('right')}
          />
        </div>
      )}
    </div>
  );
};

export default WeekTasks;
