import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import apiClient from 'utils/feathersClient';

import { ReactComponent as CheckIcon } from 'assets/icons/check-mark.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const Verify: React.FC<{}> = () => {
  const [t] = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [message, setMessage] = useState<string>(t('yourAccountVerified'));

  const verify = async () => {
    try {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
      if (!token) {
        throw new Error('Token is required');
      }
      await apiClient.service('verify').find({ query: { token } });

      setIsSuccess(true);
      setMessage(t('yourAccountVerified'));
    } catch (e) {
      setIsSuccess(false);
      setMessage(e.message);
    }
  };

  useEffect(() => {
    verify();
    // eslint-disable-next-line
  }, [location]);

  return (
    <div className="auth-form auth-form--verify">
      {isSuccess ? (
        <CheckIcon className="auth-form__check-icon" />
      ) : (
        <CloseIcon className="auth-form__close-icon" />
      )}

      <div className="auth-form__title">
        {isSuccess ? t('areYouRegistered') : t('somethingGoesWrong')}
      </div>
      <div className="auth-form__error">{message}</div>

      <Button className="auth-form__button" type="primary" onClick={() => history.push('/login')}>
        Login
      </Button>
    </div>
  );
};

export default Verify;
