import React from 'react';
import ReactDOM from 'react-dom';
import Root from 'Root';
import moment from 'moment';
import { registerLocale, setDefaultLocale } from 'react-datepicker';

import { getLanguage } from 'utils/userStorage';
import { momentLocales } from 'utils';

import './i18n';
import 'moment/locale/ru';
import enUs from 'date-fns/locale/en-US';
import ru from 'date-fns/locale/ru';
import ukr from 'date-fns/locale/uk';

const lang = getLanguage() || 'en';
// const momentLocales: { [key: string]: string } = {
//   en: 'en',
//   ru: 'ru',
//   ukr: 'uk',
// };
const dateFnsLocales: { [key: string]: { name: string; var: Locale } } = {
  en: {
    name: 'en-US',
    var: enUs,
  },
  ru: {
    name: 'ru',
    var: ru,
  },
  ukr: {
    name: 'uk',
    var: ukr,
  },
};
moment.locale(momentLocales[lang]);
if (dateFnsLocales[lang]) {
  registerLocale(dateFnsLocales[lang].name, dateFnsLocales[lang].var);
  setDefaultLocale(dateFnsLocales[lang].name);
}

ReactDOM.render(<Root />, document.getElementById('root'));

function registerServiceWorker() {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    return navigator.serviceWorker.register('/sw.js');
  }
}

registerServiceWorker();
