import { Paginated } from '@feathersjs/feathers';
import { closeTag } from 'store/core';
import { setTags, updateTag, addTag, deleteTag } from 'store/tags';
import { TagsItem } from 'types/global';
import { PromiseThunk } from 'types/store/common';

import apiClient from 'utils/feathersClient';

export const fetchTags = (): PromiseThunk<TagsItem[]> => async dispatch => {
  const { data: tags }: Paginated<TagsItem> = await apiClient.service('tags').find({
    query: {
      $limit: 500,
      /* isArchived: { $ne: true }, */
    },
  });

  dispatch(setTags(tags));
  return tags;
};

export const createTag =
  (values: Partial<TagsItem>, closeOnDone: boolean = true): PromiseThunk<TagsItem> =>
  async dispatch => {
    const tag: TagsItem = await apiClient.service('tags').create(values);
    dispatch(addTag(tag));

    if (closeOnDone) dispatch(closeTag());

    return tag;
  };

export const patchTag =
  (id: string, values: Partial<TagsItem>, closeOnDone: boolean = true): PromiseThunk<TagsItem> =>
  async dispatch => {
    const tag: TagsItem = await apiClient.service('tags').patch(id, values);
    if (tag.isArchived) {
      dispatch(updateTag(tag));
    } else {
      dispatch(updateTag(tag));
    }

    if (closeOnDone) dispatch(closeTag());

    return tag;
  };

export const removeTag =
  (id: string, closeOnDone: boolean = true): PromiseThunk<TagsItem> =>
  async dispatch => {
    const tag: TagsItem = await apiClient.service('tags').remove(id);
    dispatch(deleteTag(tag._id));

    if (closeOnDone) dispatch(closeTag());

    return tag;
  };
