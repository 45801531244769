import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const PageNotFound = () => {
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <div className="page-not-found">
      <h1>404</h1>
      <p>{t('pageNotFound')}</p>

      <button onClick={() => history.push('/')}>Home</button>
    </div>
  );
};

export default PageNotFound;
