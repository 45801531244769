import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { DebounceInput } from 'react-debounce-input';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTypedDispatch } from 'store';
import { PulseLoader } from 'react-spinners';
import { SearchOutlined } from '@ant-design/icons';

import useIsClickOutside from 'components/useIsClickOutside';
import apiClient from 'utils/feathersClient';

import { openTask } from 'store/core';

import { TasksItem } from 'types/global';
import { OpenedTaskState } from 'types/store/core';
import { Paginated, Query } from '@feathersjs/feathers';

import { ReactComponent as TomatoIcon } from 'assets/icons/tomato.svg';

const Search = () => {
  const [t] = useTranslation();
  const dispatch = useTypedDispatch();
  const { ref, isClickOutside } = useIsClickOutside();

  const [items, setItems] = useState<TasksItem[]>([]);
  const [search, setSearch] = useState<string>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const onOpenTask = (payload: OpenedTaskState) => dispatch(openTask(payload));

  const fetchItems = async () => {
    const query: Query = {
      search,
      $skip: items.length,
      $limit: 10,
    };
    const response: Paginated<TasksItem> = await apiClient.service('task-search').find({ query });

    const newItems = items.concat(response.data);
    setItems(newItems);

    if (newItems.length >= response.total) {
      setHasMore(false);
    }
  };

  const changeState = (searchValue: string) => {
    setItems([]);
    setHasMore(true);
    setSearch(searchValue);
  };

  const onTaskClick = (task: TasksItem) => {
    onOpenTask({ type: 'edit', _id: task._id });
    changeState(null);
  };

  useEffect(() => {
    if (search) {
      fetchItems();
    }
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if (isClickOutside) {
      changeState(null);
    }
  }, [isClickOutside]);

  return (
    <div className="header__search">
      <DebounceInput
        // @ts-ignore
        element={Input}
        suffix={<SearchOutlined />}
        className="header__search-input"
        placeholder={t('search')}
        debounceTimeout={300}
        value={search}
        onChange={e => changeState(e.target.value)}
      />

      {search ? (
        <div ref={ref} id="scrollableSearchContainer" className="header__search-items">
          <InfiniteScroll
            dataLength={items.length}
            next={fetchItems}
            hasMore={hasMore}
            loader={
              <div style={{ textAlign: 'center' }}>
                <PulseLoader size={7} color="#69C262" />
              </div>
            }
            endMessage={
              !hasMore && items.length === 0 && <p className="header__search-no-items">Nothing</p>
            }
            scrollableTarget="scrollableSearchContainer"
          >
            {items.map(task => (
              <div key={task._id} className="header__search-item" onClick={() => onTaskClick(task)}>
                <div className="header__search-item__left">
                  <span className="header__search-item__name">{task.name}</span>
                  <span className="header__search-item__date">
                    {task.dueDate} {task.dueTime ? `- ${task.dueTime}` : ''}
                  </span>
                </div>
                {!!task.tomatoFact && (
                  <div className="header__search-item__right">
                    <TomatoIcon />
                    {task.tomatoFact}
                  </div>
                )}
              </div>
            ))}
          </InfiniteScroll>
        </div>
      ) : null}
    </div>
  );
};

export default Search;
