import React, { useEffect, useState } from 'react';
import { DatePicker, Input } from 'antd';
import clsx from 'clsx';
import moment from 'moment';
import TextareaAutosize from 'react-textarea-autosize';
import { useTypedSelector } from 'store';
import ReactInputMask from 'react-input-mask';

import TagsSelect from 'components/TagsSelect';

import { getIsMobileScreen } from 'store/core/selectors';

type Props = {
  value: any;
  type: 'name' | 'description' | 'dueDate' | 'dueTime' | 'tags' | 'tomatoFact';
  onChange: (
    field: 'name' | 'description' | 'dueDate' | 'dueTime' | 'tags' | 'tomatoFact',
    val: any
  ) => void;
  placeholder?: string;
};

const ImplicitInput: React.FC<Props> = ({ value, type, onChange, placeholder }) => {
  const isMobileScreen = useTypedSelector(state => getIsMobileScreen(state));

  const [intermediateValue, setIntermediateValue] = useState<any>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [maxRows, setMaxRows] = useState<number>(3);

  const onChangeValue = (
    field: 'name' | 'description' | 'dueDate' | 'dueTime' | 'tags' | 'tomatoFact',
    val: any
  ) => {
    if (field !== 'tags') {
      setIsFocused(false);
    }
    if (val === value) return; 
    if (field === 'dueTime' && !moment(val, 'HH:mm', true).isValid()) return;
    onChange(field, val);
  };

  useEffect(() => {
    if (!isFocused) {
      setIntermediateValue(value);
    }
  }, [isFocused, value, type]);

  useEffect(() => {
    setTimeout(() => {
      setMaxRows(6);
    }, 300);
  }, []);

  return (
    <>
      {type === 'name' && (
        <TextareaAutosize
          minRows={1}
          maxRows={maxRows}
          className={clsx('task-view__name', {
            'task-view__name--inactive': !isFocused,
          })}
          onFocus={() => setIsFocused(true)}
          value={intermediateValue}
          onChange={e => setIntermediateValue(e.target.value)}
          onBlur={() => onChangeValue('name', intermediateValue)}
        />
      )}

      {type === 'description' && (
        <TextareaAutosize
          minRows={1}
          maxRows={maxRows}
          className={clsx('task-view__description', {
            'task-view__description--inactive': !isFocused,
          })}
          placeholder={placeholder}
          onFocus={() => setIsFocused(true)}
          value={intermediateValue || ''}
          onChange={e => setIntermediateValue(e.target.value)}
          onBlur={() => onChangeValue('description', intermediateValue)}
        />
      )}

      {type === 'dueTime' && (
        <ReactInputMask
          mask="99:99"
          className={clsx('task-view__duetime', {
            'task-view__duetime--inactive': !isFocused,
          })}
          placeholder={placeholder}
          onFocus={() => setIsFocused(true)}
          value={intermediateValue}
          onChange={e => setIntermediateValue(e.target.value)}
          onBlur={() => onChangeValue('dueTime', intermediateValue)}
        >
          {(inputProps: any) => <Input {...inputProps} placeholder={placeholder} />}
        </ReactInputMask>
      )}

      {type === 'dueDate' && (
        <DatePicker
          className={clsx('task-view__datepicker', {
            'task-view__datepicker--inactive': !isFocused,
          })}
          placeholder={placeholder}
          dropdownClassName="calendar-picker"
          showToday={false}
          mode="date"
          suffixIcon={<span />}
          allowClear={false}
          format="DD.MM.YYYY"
          onFocus={() => setIsFocused(true)}
          value={intermediateValue ? moment(intermediateValue, 'DD.MM.YYYY') : null}
          onChange={val => onChangeValue('dueDate', moment(val).format('DD.MM.YYYY'))}
          onBlur={() => setIsFocused(false)}
          inputReadOnly={isMobileScreen}
        />
      )}

      {type === 'tags' && (
        <TagsSelect
          className={clsx('task-view__tags-select', {
            'task-view__tags-select--inactive': !isFocused,
          })}
          isFromTaskView
          placeholder="Project"
          onFocus={() => setIsFocused(true)}
          value={value}
          onChange={val => onChangeValue('tags', val)}
          onBlur={() => setIsFocused(false)}
        />
      )}
      {type === 'tomatoFact' && (
        <TextareaAutosize
          className={clsx('task-view__tomato', {
            'task-view__tomato--inactive': !isFocused,
          })}
          placeholder="0"
          onFocus={() => setIsFocused(true)}
          value={intermediateValue}
          onChange={e => setIntermediateValue(e.target.value)}
          onBlur={() => onChangeValue('tomatoFact', intermediateValue)}
        >
          {(inputProps: any) => <Input {...inputProps} placeholder={placeholder} />}
        </TextareaAutosize>
      )}
    </>
  );
};

export default ImplicitInput;
