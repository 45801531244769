import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TagsItem } from 'types/global';

const tagsSlice = createSlice({
  name: 'tags',
  initialState: [] as TagsItem[],
  reducers: {
    setTags: (state, { payload }: PayloadAction<TagsItem[]>) => {
      return payload;
    },

    updateTag: (state, { payload }: PayloadAction<TagsItem>) => {
      return state.map(tag => {
        if (tag._id === payload._id) {
          return payload;
        }
        return tag;
      });
    },

    addTag: (state, { payload }: PayloadAction<TagsItem>) => {
      state.push(payload);
    },

    deleteTag: (state, { payload }: PayloadAction<string>) => {
      return state.filter(tag => tag._id !== payload);
    },
  },
});

export const { setTags, updateTag, addTag, deleteTag } = tagsSlice.actions;

export default tagsSlice.reducer;
