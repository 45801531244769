import React from 'react';
import { useTypedSelector } from 'store';

import Sidebar from 'layouts/Settings/Sidebar';
import Header from 'layouts/Main/Header';
import Modals from 'layouts/Main/Modals';
import MobileHeader from 'components/Mobile/Header';

import { getIsMobileScreen } from 'store/core/selectors';

type Props = {
  children: React.ReactNode;
};

const SettingsLayout: React.FC<Props> = ({ children }) => {
  const isMobileScreen = useTypedSelector(getIsMobileScreen);

  return (
    <div className="settings-layout">
      {!isMobileScreen ? (
        <>
          <Header />
          <Sidebar />
        </>
      ) : (
        <MobileHeader />
      )}

      <div className="settings-layout__body">
        <div className="settings-layout__wrapper">{children}</div>
      </div>

      <Modals />
    </div>
  );
};

export default SettingsLayout;
