import React from 'react';
import { useTypedDispatch, useTypedSelector } from 'store';
import clsx from 'clsx';

import DraggableWrapper from 'components/DndWrappers/Draggable';

import { patchTask } from 'store/tasks/thunkActions';
import { startTimer, stopTimer } from 'store/timer/thunkActions';
import { openTask } from 'store/core';
import { getIsMobileScreen } from 'store/core/selectors';

import { OpenedTaskState } from 'types/store/core';
import { TasksItem } from 'types/global';

import { ReactComponent as PlayButtonIcon } from 'assets/icons/play-button.svg';
import { ReactComponent as StopButtonIcon } from 'assets/icons/stop-button.svg';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/check-mark.svg';
import { ReactComponent as TomatoIcon } from 'assets/icons/tomato.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as FlagIcon } from 'assets/icons/flag.svg';

type Props = {
  item: TasksItem;
  columnDate: string;
  index: number;
  isTransferred: boolean;
  isSelected: boolean;
};

const BodyItem: React.FC<Props> = ({ item, columnDate, index, isTransferred, isSelected }) => {
  const dispatch = useTypedDispatch();

  const timer = useTypedSelector(state => state.timer);
  const openedTask = useTypedSelector(state => state.core.openedTask);
  const isMobileScreen = useTypedSelector(state => getIsMobileScreen(state));

  const isTaskDone = item.status === 'done';
  const isTaskStarted = timer.taskId === item._id && timer.isStarted;

  const onOpenTask = (payload: OpenedTaskState) => dispatch(openTask(payload));
  const onStartTimer = () => dispatch(startTimer(item));
  const onStopTimer = () => dispatch(stopTimer());
  const onPressFinish = () => {
    dispatch(patchTask(item._id, { status: isTaskDone ? 'wait' : 'done' }, false));
  };

  const onWrapperClick = () => {
    if (openedTask._id === item._id) return;

    onOpenTask({ type: 'edit', _id: item._id, showInModal: isMobileScreen });
    // onOpenTask({ type: 'edit', _id: item._id, showInModal: false });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <DraggableWrapper
      className={clsx('day-task', {
        'day-task--transferred': isTransferred,
        'day-task--selected': isSelected,
        'day-task--done': isTaskDone,
        'day-task--active': isTaskStarted,
      })}
      index={index}
      draggableId={isTransferred ? `${item._id}_${columnDate}` : item._id}
      isDragDisabled={isTransferred}
    >
      <div className="day-task__time">{item.dueTime}</div>

      <div className="day-task__wrapper" onClick={onWrapperClick}>
        <div className="day-task__borders">
          {item.tags.map(tag => (
            <div key={tag._id} style={{ backgroundColor: tag.color }} />
          ))}
        </div>

        <div className="day-task__main">
          <div className="day-task__left">
            <div className="day-task__head">
              {item?.priority === 'urgent' && (
                <div className='task-view__flag'>
                  <FlagIcon stroke="red" width="16px" />
                </div>
              )}
              <h2 className="day-task__title">{item.name}</h2>
            </div>
            <div className="day-task__foot">
              {!isMobileScreen && (
                <div className="day-task__tomatoes">
                  <TomatoIcon /> {item.tomatoFact}
                </div>
              )}
              <div className="day-task__tags">
                {item.tags.map((tag, i) => (
                  <span
                    key={i}
                    className="day-task__tags-item"
                    style={{ color: tag.color || '#485a63' }}
                  >
                    {tag.name}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="day-task__right" onClick={e => e.stopPropagation()}>
            {isTaskStarted ? (
              <StopButtonIcon className="day-task__stop-button" onClick={onStopTimer} />
            ) : (
              <PlayButtonIcon className="day-task__play-button" onClick={onStartTimer} />
            )}

            {isMobileScreen && (
              <div className="day-task__right-center">
                <div className="day-task__tomatoes">
                  <TomatoIcon /> {item.tomatoFact}
                </div>
                {!!item.dueTime && (
                  <div className="day-task__small-time">
                    <ClockIcon /> {item.dueTime}
                  </div>
                )}
              </div>
            )}

            <CheckMarkIcon
              className={clsx('day-task__check-mark', 'check-icon', { 'check-icon--done': isTaskDone })}
              onClick={onPressFinish}
            />
          </div>
        </div>
      </div>
    </DraggableWrapper>
  );
};

export default BodyItem;
