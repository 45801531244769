import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'antd';
import { FORM_ERROR } from 'final-form';

import FieldWithValidation from 'components/FieldWithValidation';

import apiClient from 'utils/feathersClient';

import { ValidationErrorsObject } from 'types/global';

const ChangePassword = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const onSubmit = async (values: { email: string; password: string }) => {
    try {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
      const { password } = values;

      await apiClient.service('new-password').create({ password }, { query: { token } });
      history.push('/login');
    } catch (e) {
      return { [FORM_ERROR]: e.message };
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, submitError, submitting }) => (
        <form className="auth-form auth-form--change-password" onSubmit={handleSubmit}>
          <div className="auth-form__title">{t('changePassword')}</div>

          {submitError && <div className="auth-form__error">{submitError}</div>}

          <div className="auth-form__fields">
            <Field name="password">
              {({ input, meta }) => (
                <FieldWithValidation meta={meta} errorText={t(meta.error)}>
                  <Input.Password {...input} placeholder={t('newPassword')} />
                </FieldWithValidation>
              )}
            </Field>
            <Field name="confirmPassword">
              {({ input, meta }) => (
                <FieldWithValidation meta={meta} errorText={t(meta.error)}>
                  <Input.Password {...input} placeholder={t('confirmPassword')} />
                </FieldWithValidation>
              )}
            </Field>
          </div>

          <Button className="auth-form__button" type="primary" htmlType="submit" loading={submitting}>
            {t('change')}
          </Button>
        </form>
      )}
    />
  );
};

type FormState = {
  password: string;
  confirmPassword: string;
};

const validate = (values: Partial<FormState>) => {
  const errors: ValidationErrorsObject<FormState> = {};

  if (!values.password || values.password.trim().length < 3) {
    errors.password = 'minLength';
  }
  if (!values.confirmPassword || values.confirmPassword.trim().length < 3) {
    errors.confirmPassword = 'minLength';
  }
  if (values.password && values.confirmPassword && values.password !== values.confirmPassword) {
    errors.confirmPassword = 'passwordsDontMatch';
  }

  return errors;
};

export default ChangePassword;
