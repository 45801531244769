import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Dropdown, Badge } from 'antd';

import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as NotificationsIcon } from 'assets/icons/notifications.svg';

const Notifications = () => {
  const [t] = useTranslation();

  const onItemClick = () => {
    console.log('click on notification');
  };

  const overlay = (
    <div className="notifications">
      <div className="notifications__title">{t('notifications')}</div>

      <div className="notifications__body">
        <Menu onClick={onItemClick}>
          {notificationsList.map(item => (
            <Menu.Item key={item._id} className="notifications__item">
              <ClockIcon className="notifications__item-icon" />
              <span className="notifications__item-text">
                The task "<span style={{ color: '#69c262' }}>{item.name}</span>" expires today in 12:20
                AM.
              </span>
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </div>
  );

  return (
    <Dropdown trigger={['click']} overlay={overlay}>
      <Badge
        dot={!!notificationsList.length}
        color={notificationsList.length ? '#69C262' : null}
        offset={[-8, 5]}
        size="default"
      >
        <NotificationsIcon onClick={() => console.log('notification icon')} />
      </Badge>
    </Dropdown>
  );
};

const notificationsList = [
  {
    _id: '1',
    name: '#Landing page',
  },
  {
    _id: '2',
    name: '#Landing page',
  },
];

export default Notifications;
