import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserState } from 'types/store/user';

const initialState: UserState = {
  token: null,
  isAuth: false,
  user: {},
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthData: (state, { payload }: PayloadAction<UserState>) => {
      return payload;
    },
    resetAuthData: () => {
      return initialState;
    },
    setUser: (state, { payload }: PayloadAction<UserState['user']>) => {
      state.user = payload;
    },
  },
});

export const { setAuthData, resetAuthData, setUser } = userSlice.actions;

export default userSlice.reducer;
