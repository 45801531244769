import React, { useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { Button, Input, Row, Modal } from 'antd';
import { useTypedDispatch, useTypedSelector } from 'store';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import FieldWithValidation from 'components/FieldWithValidation';
import TagsSelect from 'components/TagsSelect';
import LoadingMask from 'components/LoadingMask';

import apiClient from 'utils/feathersClient';

import { createTask, patchTask, removeTask } from 'store/tasks/thunkActions';
import { closeBacklog } from 'store/core';
import { getIsMobileScreen } from 'store/core/selectors';

import { TasksItem } from 'types/global';

const HoldedTaskForm = () => {
  const dispatch = useTypedDispatch();
  const [t] = useTranslation();

  const { _id: id } = useTypedSelector(state => state.core.openedBacklog);
  const isMobileScreen = useTypedSelector(state => getIsMobileScreen(state));

  const [holdedTask, setHoldedTask] = useState<Partial<TasksItem>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isEdit: boolean = Boolean(id) || Boolean(holdedTask._id);

  const onCreateHoldedTask = (values: Partial<TasksItem>) =>
    dispatch(createTask({ ...values, dueDate: undefined }));

  const onPatchHoldedTask = (values: Partial<TasksItem>) =>
    dispatch(patchTask(id, { ...values}));

  const onRemoveHoldedTask = () => dispatch(removeTask(id));
  const onCloseBacklog = () => dispatch(closeBacklog());

  const openDeletePopup = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete it?',
      centered: true,
      okText: 'delete',
      cancelText: 'cancel',
      okButtonProps: { className: 'ant-btn-primary ant-btn-dangerous' },
      cancelButtonProps: { className: 'ant-btn-primary' },
      onOk: onRemoveHoldedTask,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result: TasksItem = await apiClient.service('tasks').get(id);
      setHoldedTask(result);
      setIsLoading(false);
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  return (
    <Form
      onSubmit={isEdit ? onPatchHoldedTask : onCreateHoldedTask}
      initialValues={holdedTask}
      render={({ handleSubmit, valid }) => (
        <div className="backlog-form">
          <LoadingMask isLoading={isLoading} zIndex={100} />

          <div className="backlog-form__head">
            <h2 className="form-title">{isEdit ? t('editBacklog') : t('addBacklog')}</h2>
          </div>
          <div className="backlog-form__body">
            <Row className="form-margin">
              <Field name="name" validate={val => !val}>
                {({ input, meta }) => (
                  <FieldWithValidation meta={meta}>
                    <Input {...input} placeholder={t('title')} />
                  </FieldWithValidation>
                )}
              </Field>
            </Row>

            <Row className="form-margin">
              <Field name="tags">
                {({ input }) => <TagsSelect {...input} placeholder={t('project')} />}
              </Field>
            </Row>

            <Row className="form-margin">
              <Field name="description">
                {({ input }) => (
                  <Input.TextArea
                    {...input}
                    rows={isMobileScreen ? 3 : 6}
                    placeholder={t('description')}
                  />
                )}
              </Field>
            </Row>

            {isEdit ? (
              <Row justify="center" className="form-buttons-row">
                <Button className="mr20 form-button" danger type="primary" onClick={openDeletePopup}>
                  {t('delete')}
                </Button>
                <Button className="form-button" type="primary" disabled={!valid} onClick={handleSubmit}>
                  {t('edit')}
                </Button>
              </Row>
            ) : (
              <Row justify="center" className="form-buttons-row">
                <Button className="mr20 form-button" danger type="primary" onClick={onCloseBacklog}>
                  {t('cancel')}
                </Button>
                <Button className="form-button" type="primary" disabled={!valid} onClick={handleSubmit}>
                  {t('add')}
                </Button>
              </Row>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default HoldedTaskForm;
