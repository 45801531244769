import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { getLanguage } from 'utils/userStorage';

import en from 'translations/en.json';
import ru from 'translations/ru.json';
import ukr from 'translations/ukr.json';

const resources = {
  en: { translation: en },
  ru: { translation: ru },
  ukr: { translation: ukr },
};

const lang = getLanguage() || 'en';

i18n
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'en',
    lng: lang,
    debug: process.env.NODE_ENV !== 'production',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
