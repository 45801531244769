import React, { useState, useRef } from 'react';
import FloatingLabel from 'floating-label-react';
import { Form, Field } from 'react-final-form';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { isEmail, splitFullName } from 'utils';

import { patchUser, logOut } from 'store/user/thunkActions';
import { useTypedDispatch, useTypedSelector } from 'store';
import { closeSettings } from 'store/core';

import { Logo, ValidationErrorsObject } from 'types/global';

import 'floating-label-react/styles.css';

const UserForm = () => {
  const [t] = useTranslation();
  const dispatch = useTypedDispatch();

  const fileInput = useRef<HTMLInputElement>(null);

  const { user, token } = useTypedSelector(state => state.authUser);

  const [errorMessage, setErrorMessage] = useState<string>('');

  const onFileUpload = async (event: React.ChangeEvent<HTMLInputElement>, changeCallback: Function) => {
    const file = event.target.files[0];
    const data = new FormData();
    data.append('file', file);
    try {
      const response = (
        await axios({
          method: 'post',
          url: process.env.REACT_APP_FILE_URL,
          data,
          headers: { Authorization: `Bearer ${token}` },
        })
      ).data[0];

      changeCallback(response);
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  const saveSettings = async (values: {
    name: string;
    email: string;
    description: string;
    logo: Logo;
  }) => {
    const [f, l] = splitFullName(values.name);

    const data = {
      _id: user._id,
      description: values.description,
      logoId: values.logo ? values.logo._id : null,
      firstName: f,
      lastName: l,
    };

    try {
      await dispatch(patchUser(data));
      return {};
    } catch (e) {
      setErrorMessage(e.message);
      return e.errors;
    }
  };

  const onLogout = async () => {
    try {
      await dispatch(logOut());
      dispatch(closeSettings());
    } catch (e) {
      message.error('Error while logging out');
    }
  };

  const initialValues = { ...user, name: `${user.firstName} ${user.lastName}` };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={saveSettings}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="settings">
          <div className="settings__row settings__row_error">
            <div className="settings__error">
              <p>{errorMessage}</p>
            </div>
          </div>
          <div className="settings__row">
            <div className="settings__box settings__box_image">
              <Field name="logo">
                {({ input }) => (
                  <div>
                    <div
                      className="logo"
                      style={{
                        backgroundImage: input.value?.path ? `url(${input.value.path})` : 'none',
                      }}
                      onClick={() => fileInput.current.click()}
                      role="presentation"
                    />
                    <input
                      ref={fileInput}
                      type="file"
                      name="file"
                      className="input-file"
                      onChange={e => onFileUpload(e, input.onChange)}
                    />
                  </div>
                )}
              </Field>
            </div>
            <div className="settings__box">
              <div className="settings__field settings__field_padding">
                <Field name="name">
                  {({ input, meta }) => (
                    <FloatingLabel
                      {...input}
                      type="text"
                      placeholder="Name"
                      className={(meta.error || meta.submitError) && meta.touched ? 'field_error' : ''}
                    />
                  )}
                </Field>
              </div>
              <div className="settings__field">
                <Field name="email">
                  {({ input, meta }) => (
                    <FloatingLabel
                      {...input}
                      type="email"
                      placeholder="Email"
                      disabled
                      className={(meta.error || meta.submitError) && meta.touched ? 'field_error' : ''}
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className="settings__box">
              <div className="settings__field settings__field_full">
                <Field name="description">
                  {({ input }) => (
                    <FloatingLabel
                      {...input}
                      component="textarea"
                      type="text"
                      placeholder="Description"
                    />
                  )}
                </Field>
              </div>
            </div>
          </div>
          <div className="settings__row settings__row_justify-between">
            <button className="settings__save" type="submit">
              {t('save')}
            </button>
            <button className="settings__save" onClick={onLogout} type="button">
              {t('logout')}
            </button>
          </div>
        </form>
      )}
    />
  );
};

type FormState = {
  email: string;
  name: string;
};

const validate = (values: Partial<FormState>) => {
  const errors: ValidationErrorsObject<FormState> = {};

  if (!values.email) {
    errors.email = 'Required';
  }
  if (values.email && !isEmail(values.email)) {
    errors.email = 'Not valid email';
  }
  if (!values.name) {
    errors.name = 'Required';
  }
  if (!values.name || values.name.split(' ').length < 2) {
    errors.name = 'Введите полное имя';
  }

  return errors;
};

export default UserForm;
