import React from 'react';
import { Form } from 'react-final-form';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useTypedDispatch, useTypedSelector } from 'store';
import { Checkbox } from 'antd';
import moment from 'moment';

import { setLanguage } from 'utils/userStorage';
import { momentLocales } from 'utils';

import { patchUser } from 'store/user/thunkActions';

const UserForm = () => {
  const [t] = useTranslation();
  const dispatch = useTypedDispatch();

  const { language: activeLanguage, soundSettings } = useTypedSelector(state => state.authUser.user);

  const submit = async () => {};

  const onChangeLanguage = async (language: string) => {
    moment.locale(momentLocales[language]);
    await dispatch(patchUser({ language }));
    setLanguage(language);
    i18next.changeLanguage(language);
  };

  const onCheckboxClick = (field: 'start' | 'finish' | 'tick') => {
    dispatch(
      patchUser({
        soundSettings: { ...soundSettings, [field]: soundSettings ? !soundSettings[field] : true },
      })
    );
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="settings notifications">
          <div className="settings__row settings__row_error"></div>
          <div className="language-select">
            <span>{t('language')}:</span>
            <select
              value={activeLanguage}
              onChange={event => onChangeLanguage(event.currentTarget.value)}
            >
              <option value="en">EN</option>
              <option value="ru">RU</option>
              <option value="ukr">UA</option>
            </select>
          </div>

          <div className="settings__temporary">
            <Checkbox
              className="checkbox mb10"
              checked={soundSettings?.tick}
              onChange={() => onCheckboxClick('tick')}
            >
              <span className="checkbox__label">{t('tickSound')}</span>
            </Checkbox>
            <Checkbox
              className="checkbox mb10"
              checked={soundSettings?.start}
              onChange={() => onCheckboxClick('start')}
            >
              <span className="checkbox__label">{t('startSound')}</span>
            </Checkbox>
            <Checkbox
              className="checkbox mb10"
              checked={soundSettings?.finish}
              onChange={() => onCheckboxClick('finish')}
            >
              <span className="checkbox__label">{t('finishSound')}</span>
            </Checkbox>
          </div>

          {/* <div className="settings__row">
            <div className="settings__box settings__box_right-border">
              <div className="settings__field settings__field_padding">
                <Field name="pushNotification">
                  {({ input, meta }) => (
                    <label htmlFor="pushNotification">
                      <input
                        id="pushNotification"
                        {...input}
                        type="checkbox"
                        className={(meta.error || meta.submitError) && meta.touched ? 'field_error' : ''}
                      />
                      <span>{t('pushNotifications')}</span>
                    </label>
                  )}
                </Field>
              </div>
              <div className="settings__field settings__field_padding">
                <Field name="timerSound">
                  {({ input, meta }) => (
                    <label htmlFor="timerSound">
                      <input
                        id="timerSound"
                        {...input}
                        type="checkbox"
                        className={(meta.error || meta.submitError) && meta.touched ? 'field_error' : ''}
                      />
                      <span>{t('timerSound')}</span>
                    </label>
                  )}
                </Field>
              </div>
            </div>
          </div> */}
        </form>
      )}
    />
  );
};

const validate = () => {
  const errors = {};

  return errors;
};

export default UserForm;
