import React, { useEffect, useState, useMemo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedDispatch, useTypedSelector } from 'store';
import clsx from 'clsx';
import { openGoal } from '../../store/core';
import { fetchGoals } from '../../store/goals/thunkActions';

import { getSortedGoals } from '../../store/goals/selectors';

import { Button, Modal, Row } from 'antd';
import LoadingMask from '../../components/LoadingMask';
import GoalCard from '../../components/Goals/GoalCard';

import { removeGoal, patchGoal } from '../../store/goals/thunkActions';

import { OpenedGoalState } from '../../types/store/core';
import { GoalsItem } from '../../types/global';

type Props = {
  className?: string;
};

interface GoalsSettingsProps {
  children?: ReactNode;
}

const GoalsWrapper = ({ children }: GoalsSettingsProps) => {
  return <div className={'goals__items-card-wrapper'}>{children}</div>;
};

const GoalsSettings: React.FC<Props> = ({ className = '' }) => {
  const windowWidth = useTypedSelector(state => state.core.windowWidth);
  const goals = useTypedSelector(state => getSortedGoals(state));
  const dispatch = useTypedDispatch();
  const [t] = useTranslation();
  const [isAchievedGoalsVisible, setVisibleAchievedGoals] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleShowAchieved = () => setVisibleAchievedGoals(!isAchievedGoalsVisible);

  const onOpenGoal = (payload: OpenedGoalState) => dispatch(openGoal(payload));
  
  const onFetchGoals = async (date?: number) => {
    setIsLoading(true);
    await dispatch(fetchGoals(date));
    setIsLoading(false);
  };
  const handleGoalPatch = (id: string, values: any) => dispatch(patchGoal(id, values));

  const handleRemoveGoal = (id: string) => dispatch(removeGoal(id));

  const handlePopupOpen = (e: any, id: string) => {
    e.preventDefault();
    Modal.confirm({
      title: 'Are you sure you want to delete it?',
      centered: true,
      okText: 'delete',
      cancelText: 'cancel',
      okButtonProps: { className: 'ant-btn-primary ant-btn-dangerous' },
      cancelButtonProps: { className: 'ant-btn-primary' },
      onOk: () => handleRemoveGoal(id),
    });
  };

  const projectColSpan = useMemo(() => {
    if (windowWidth < 576) return 24;
    if (windowWidth < 1440) return 8;
    return 6;
  }, [windowWidth]);

  useEffect(() => {
    onFetchGoals();
    // eslint-disable-next-line
  }, []);


  const filteredGoals = useMemo(() => {
    let goalsCopy = [...goals].sort((a: GoalsItem, b: GoalsItem) => (a.doneAt ? 1 : -1));
    if (isAchievedGoalsVisible) {
      return goalsCopy;
    }
    return goalsCopy.filter(item => !item.doneAt);
  }, [goals, isAchievedGoalsVisible]);

  return (
    <div className={clsx('goals', className)}>
      <div className="settings-form__head">
        <h1 className="settings-form__title">{t('Goals')}</h1>
      </div>
      <LoadingMask isLoading={isLoading} zIndex={20} width={40} height={40} opacity={0.5} />
      <GoalsWrapper>
        <Row gutter={[20, 20]}>
          {filteredGoals.map((item, index) => (
            <GoalCard
              projectColSpan={projectColSpan}
              item={item}
              onPatchGoal={handleGoalPatch}
              onDeletePopupOpen={(e: React.MouseEvent<HTMLElement>) => handlePopupOpen(e, item._id)}
              onClick={() => onOpenGoal({ type: 'edit', _id: item._id })}
              onClickExtra={() => console.log('extra clicked')}
            />
          ))}
        </Row>
        <Button className="settings-form__show__archived" onClick={handleShowAchieved}>
          {isAchievedGoalsVisible ? t('hideAchieved') : t('showAchieved')}
        </Button>
      </GoalsWrapper>
    </div>
  );
};

export default GoalsSettings;
