import { setGoals, updateGoal, addGoal, deleteGoal } from 'store/goals';
import { closeGoal } from 'store/core';
import { Paginated } from '@feathersjs/feathers';

import { getNewPosition } from 'utils';
import { getWeek } from 'utils/date';
import apiClient from 'utils/feathersClient';

import { GoalsItem } from 'types/global';
import { PromiseThunk } from 'types/store/common';
import { ChangeGoalPositionArgs } from 'types/store/goals';

export const fetchGoals =
  (currentWeek?: number): PromiseThunk<GoalsItem[]> =>
  async dispatch => {
/*     if (!currentWeek) return; */

    const startOfWeek = getWeek('current', currentWeek)[0];
    const endOfWeek = getWeek('current', currentWeek)[6];

    const query = {
      // $or: [
      //   { isMonthly: true },
      //   {
      //     isMonthly: false,
      //     dueDate: { $gte: startOfWeek, $lte: endOfWeek },
      //   },
      // ],
      $limit: 100,
    };
    const { data: goals }: Paginated<GoalsItem> = await apiClient.service('goals').find({ query });
    dispatch(setGoals(goals));

    return goals;
  };

export const patchGoal =
  (id: string, values: Partial<GoalsItem>, closeOnDone: boolean = true): PromiseThunk<GoalsItem> =>
  async dispatch => {
    const goal: GoalsItem = await apiClient.service('goals').patch(id, values);
    dispatch(updateGoal(goal));

    if (closeOnDone) dispatch(closeGoal());

    return goal;
  };

export const createGoal =
  (values: Partial<GoalsItem>, closeOnDone: boolean = true): PromiseThunk<GoalsItem> =>
  async dispatch => {
    const goal: GoalsItem = await apiClient.service('goals').create(values);
    dispatch(addGoal(goal));

    if (closeOnDone) dispatch(closeGoal());

    return goal;
  };

export const removeGoal =
  (id: string, closeOnDone: boolean = true): PromiseThunk<GoalsItem> =>
  async dispatch => {
    const goal: GoalsItem = await apiClient.service('goals').remove(id);
    dispatch(deleteGoal(goal._id));

    if (closeOnDone) dispatch(closeGoal());

    return goal;
  };

export const changeGoalPosition =
  ({ goalId, destinationIndex, sourceIndex }: ChangeGoalPositionArgs): PromiseThunk<void> =>
  async (dispatch, getState) => {
    const goals = getState().goals;
    const currentGoal = goals.find(el => el._id === goalId);

    const newGoalPosition = getNewPosition({
      items: goals,
      currentItem: currentGoal,
      type: 'reorder',
      destinationIndex,
      sourceIndex,
    });

    dispatch(updateGoal({ ...currentGoal, position: newGoalPosition }));

    await apiClient.service('goals').patch(goalId, { position: newGoalPosition });
  };
