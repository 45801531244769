import React from 'react';
import ReactSelect from 'react-select';
import { capitalize, getListOfValues } from 'utils';

import colorNames from 'utils/colorNames.json';

const colorNamesList = getListOfValues(colorNames);

type Props = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
};

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':after': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginLeft: 8,
    height: 20,
    width: 20,
  },
});

const ColorSelect: React.FC<Props> = ({ value, onChange, ...rest }) => {
  const colorStyles = {
    singleValue: (styles: any, { data }: { data: any }) => ({ ...styles, ...dot(data.key) }),
    option: (styles: any, { data }: { data: any }) => ({ ...styles, ...dot(data.key) }),
  };

  const fakeValue = value ? { value, key: value } : null;

  return (
    // @ts-ignore
    <ReactSelect
      {...rest}
      options={colorNamesList}
      formatOptionLabel={item => capitalize(item.key)}
      getOptionValue={item => item.key}
      styles={colorStyles}
      className="react-select"
      classNamePrefix="react-select"
      value={fakeValue}
      // @ts-ignore
      onChange={item => onChange(item.key)}
    />
  );
};

export default ColorSelect;
