import React from 'react';
import ReactLoading, { LoadingProps } from 'react-loading';
import { CSSTransition } from 'react-transition-group';

type Props = LoadingProps & {
  isLoading: boolean;
  zIndex?: number;
  opacity?: number;
  position?: 'relative' | 'absolute' | 'static';
};

const LoadingMask: React.FC<Props> = ({
  isLoading,
  zIndex,
  position = 'absolute',
  opacity = 0.4,
  ...rest
}) => {
  return (
    <CSSTransition
      in={isLoading}
      timeout={200}
      classNames="loading-transition"
      unmountOnExit
      mountOnEnter
    >
      <div
        className="loading-mask"
        style={{ zIndex, position, backgroundColor: `rgba(255, 255, 255, ${opacity})` }}
      >
        <ReactLoading
          type="bars"
          color="#69C262"
          width={60}
          height={60}
          className="loading-mask__loader"
          {...rest}
        />
      </div>
    </CSSTransition>
  );
};

export default LoadingMask;
