import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CoreState,
  DraggingTaskState,
  OpenedGoalState,
  OpenedTaskState,
  OpenedNoteState,
  OpenedTagState,
  OpenedBacklogState,
} from 'types/store/core';

const initialState: CoreState = {
  openedTask: {
    isActive: false,
    showInModal: false,
    type: null,
    dueDate: '',
    initialName: '',
    _id: null,
  },
  openedGoal: {
    isActive: false,
    type: 'new',
    dueDate: '',
    _id: null,
  },
  settingsModal: {
    isActive: false,
  },
  openedNote: {
    isActive: false,
    type: 'new',
  },
  openedTag: {
    isActive: false,
    type: 'new',
  },
  openedBacklog: {
    isActive: false,
    _id: null,
    dueDate: '',
    type: 'new',
  },
  draggingTask: {
    id: null,
    index: null,
    droppableId: null,
  },
  isSidebarCollapsed: false,
  windowWidth: 1920,
  isDrawerOpened: false,
};

const coreSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    openTask: (state, { payload }: PayloadAction<OpenedTaskState>) => {
      const { showInModal = true, type, dueDate = undefined, initialName = '', _id = null } = payload;

      if (type === 'edit' && !_id) {
        console.error('Provide id(open task)!!');
        return;
      }

      state.openedTask = {
        showInModal,
        type,
        dueDate,
        initialName,
        _id,
        isActive: true,
      };
    },

    closeTask: state => {
      state.openedTask = initialState.openedTask;
    },

    openGoal: (
      state,
      { payload: { type = 'new', dueDate = undefined, _id = null } }: PayloadAction<OpenedGoalState>
    ) => {
      if (type === 'edit' && !_id) {
        console.error('Provide id(open goal)!!');
        return;
      }

      const data = {
        type,
        dueDate,
        _id,
        isActive: true,
      };
      state.openedGoal = data;
    },

    closeGoal: state => {
      state.openedGoal = initialState.openedGoal;
    },

    openSettings: state => {
      state.settingsModal = { isActive: true };
    },

    closeSettings: state => {
      state.settingsModal = initialState.settingsModal;
    },
    openTag: (state, { payload }: PayloadAction<OpenedTagState>) => {
      state.openedTag = { ...payload, isActive: true };
    },

    closeTag: state => {
      state.openedTag = initialState.openedTag;
    },

    openNote: (state, { payload }: PayloadAction<OpenedNoteState>) => {
      state.openedNote = { ...payload, isActive: true };
    },

    closeNote: state => {
      state.openedNote = initialState.openedNote;
    },

    openBacklog: (state, { payload }: PayloadAction<OpenedBacklogState>) => {
      state.openedBacklog = { ...payload, isActive: true };
    },

    closeBacklog: state => {
      state.openedBacklog = initialState.openedBacklog;
    },

    setDraggingTask: (state, { payload }: PayloadAction<DraggingTaskState>) => {
      state.draggingTask = payload;
    },

    resetDraggingTask: state => {
      state.draggingTask = initialState.draggingTask;
    },

    setIsSidebarCollapsed: (state, { payload }: PayloadAction<boolean>) => {
      state.isSidebarCollapsed = payload;
    },

    setWindowWidth: (state, { payload }: PayloadAction<CoreState['windowWidth']>) => {
      state.windowWidth = payload;
    },

    setIsDrawerOpened: (state, { payload }: PayloadAction<boolean>) => {
      state.isDrawerOpened = payload;
    },
  },
});

export const {
  openTask,
  closeTask,
  openGoal,
  closeGoal,
  openSettings,
  closeSettings,
  setDraggingTask,
  resetDraggingTask,
  openTag,
  closeTag,
  openNote,
  closeNote,
  openBacklog,
  closeBacklog,
  setIsSidebarCollapsed,
  setWindowWidth,
  setIsDrawerOpened,
} = coreSlice.actions;

export default coreSlice.reducer;
