import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import axios from 'axios';
import { useTypedDispatch, useTypedSelector } from 'store';
import { Button, Col, Input, Row, message } from 'antd';

import FieldWithValidation from 'components/FieldWithValidation';

import { splitFullName, isEmail } from 'utils';

import { patchUser } from 'store/user/thunkActions';

import { Logo, ValidationErrorsObject } from 'types/global';

const AccountDetails = () => {
  const [t] = useTranslation();
  const dispatch = useTypedDispatch();

  const fileInput = useRef<HTMLInputElement>(null);

  const { user, token } = useTypedSelector(state => state.authUser);

  const onFileUpload = async (event: React.ChangeEvent<HTMLInputElement>, changeCallback: Function) => {
    const file = event.target.files[0];
    const data = new FormData();
    data.append('file', file);
    try {
      const response = (
        await axios({
          method: 'post',
          url: process.env.REACT_APP_FILE_URL,
          data,
          headers: { Authorization: `Bearer ${token}` },
        })
      ).data[0];

      changeCallback(response);
    } catch (e) {
      message.error(
        <div>
          <h3>Error while uploading image</h3>
          <span>{e.message}</span>
        </div>,
        4
      );
    }
  };

  const saveSettings = async (values: {
    name: string;
    email: string;
    description: string;
    logo: Logo;
  }) => {
    const [f, l] = splitFullName(values.name);

    const data = {
      _id: user._id,
      description: values.description,
      logoId: values.logo ? values.logo._id : null,
      firstName: f,
      lastName: l,
    };

    try {
      await dispatch(patchUser(data));
      message.success('Saved successfully', 2);
    } catch (e) {
      message.error(
        <div>
          <h3>Error while saving</h3>
          <span>{e.message}</span>
        </div>,
        5
      );
    }
  };

  const initialValues = { ...user, name: `${user.firstName} ${user.lastName}` };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={saveSettings}
      validate={validate}
      render={({ handleSubmit }) => (
        <div className="settings-form">
          <div className="settings-form__head">
            <h1 className="settings-form__title">{t('accountDetails')}</h1>
            <Button type="primary" onClick={handleSubmit}>
              Save
            </Button>
          </div>

          <div className="settings-form__card">
            <Row gutter={24} className="settings-form__row">
              <Col span={12} className="settings-form__item-name">
                <p>Avatar</p>
              </Col>
              <Col span={12} className="settings-form__item-input">
                <Field name="logo">
                  {({ input }) => (
                    <div className="settings-form__logo">
                      <img src={input.value?.path} alt="ava" onClick={() => fileInput.current.click()} />
                      <input
                        ref={fileInput}
                        type="file"
                        name="file"
                        className="settings-form__input"
                        onChange={e => onFileUpload(e, input.onChange)}
                      />
                    </div>
                  )}
                </Field>
              </Col>
            </Row>
            <Row gutter={24} className="settings-form__row">
              <Col span={12} className="settings-form__item-name">
                <p>Name</p>
              </Col>
              <Col span={12} className="settings-form__item-input">
                <Field name="name">
                  {({ input, meta }) => (
                    <FieldWithValidation meta={meta}>
                      <Input {...input} placeholder="Name" />
                    </FieldWithValidation>
                  )}
                </Field>
              </Col>
            </Row>
            <Row gutter={24} className="settings-form__row">
              <Col span={12} className="settings-form__item-name">
                <p>Email</p>
              </Col>
              <Col span={12} className="settings-form__item-input">
                <Field name="email">
                  {({ input }) => <Input {...input} type="email" placeholder="Email" disabled />}
                </Field>
              </Col>
            </Row>
            <Row gutter={24} className="settings-form__row">
              <Col span={12} className="settings-form__item-name">
                <p>Description</p>
              </Col>
              <Col span={12} className="settings-form__item-input">
                <Field name="description">
                  {({ input }) => <Input.TextArea {...input} rows={4} placeholder="Description" />}
                </Field>
              </Col>
            </Row>
          </div>
        </div>
      )}
    />
  );
};

type FormState = {
  email: string;
  name: string;
};

const validate = (values: Partial<FormState>) => {
  const errors: ValidationErrorsObject<FormState> = {};

  if (!values.email) {
    errors.email = 'Required';
  }
  if (values.email && !isEmail(values.email)) {
    errors.email = 'Not valid email';
  }
  if (!values.name) {
    errors.name = 'Required';
  }
  if (!values.name || values.name.split(' ').length < 2) {
    errors.name = 'Введите полное имя';
  }

  return errors;
};

export default AccountDetails;
