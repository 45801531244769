import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import UserForm from './UserForm';
import UpdatePassword from './UpdatePassword';
import Notifications from './Notifications';
import Stats from './Stats';
import Invoicing from './Invoicing';

import 'react-tabs/style/react-tabs.css';

const Settings = () => {
  const [t] = useTranslation();

  return (
    <div className="stat">
      <Tabs>
        <TabList>
          <Tab>{t('main')}</Tab>
          <Tab>{t('passwordSettings')}</Tab>
          <Tab>{t('settings')}</Tab>
          <Tab>{t('stats')}</Tab>
          <Tab>It is free now</Tab>
        </TabList>

        <TabPanel>
          <UserForm />
        </TabPanel>

        <TabPanel>
          <UpdatePassword />
        </TabPanel>

        <TabPanel>
          <Notifications />
        </TabPanel>

        <TabPanel>
          <Stats />
        </TabPanel>

        <TabPanel>
          <Invoicing />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Settings;
