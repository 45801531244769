import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GoalsItem } from 'types/global';

const goalsSlice = createSlice({
  name: 'goals',
  initialState: [] as GoalsItem[],
  reducers: {
    setGoals: (state, { payload }: PayloadAction<GoalsItem[]>) => {
      return payload;
    },

    updateGoal: (state, { payload }: PayloadAction<GoalsItem>) => {
      return state.map(goal => {
        if (goal._id === payload._id) {
          return payload;
        }
        return goal;
      });
    },

    addGoal: (state, { payload }: PayloadAction<GoalsItem>) => {
      state.push(payload);
    },

    deleteGoal: (state, { payload }: PayloadAction<string>) => {
      return state.filter(goal => goal._id !== payload);
    },
  },
});

export const { setGoals, updateGoal, addGoal, deleteGoal } = goalsSlice.actions;

export default goalsSlice.reducer;
