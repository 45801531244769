import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useTypedDispatch, useTypedSelector } from 'store';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import clsx from 'clsx';

import DroppableWrapper from 'components/DndWrappers/Droppable';
import TasksColumnItem from './Item';
import TasksColumnRow from './Row';

import { randomInteger } from 'utils';
import { changeFormat, isPreviosDate } from 'utils/date';

import { openTask } from 'store/core';
import { getTasksForColumn } from 'store/tasks/selectors';

import { OpenedTaskState } from 'types/store/core';

type Props = {
  columnDate: string;
  maxTasks: number;
  isToday: boolean;
  className?: string;
};

const TasksColumn: React.FC<Props> = ({ columnDate, maxTasks, isToday, className = '' }) => {
  const history = useHistory();
  const [t] = useTranslation();
  const dispatch = useTypedDispatch();

  const columnRef = useRef<HTMLDivElement>(null);

  const tasks = useTypedSelector(state => getTasksForColumn(state, columnDate));
  const draggingTask = useTypedSelector(state => state.core.draggingTask);

  const [isOverflowNeeded, setIsOverflowNeeded] = useState<boolean>(false);

  const emptyRows = useMemo(() => {
    return Array.from({ length: maxTasks - tasks.length - 1 }).map(() => randomInteger());
    // eslint-disable-next-line
  }, [tasks.length]);

  const isAbleToAddTasks = !isPreviosDate(columnDate);
  const isDropDisabled = isPreviosDate(columnDate);

  const onOpenTask = (payload: OpenedTaskState) => dispatch(openTask(payload));

  const goToToday = () => {
    history.push(`/day/${moment(columnDate, 'DD.MM.YYYY').valueOf()}`);
  };

  useEffect(() => {
    const columnBody = columnRef.current.querySelector('.column__body');
    const columnTitle = columnRef.current.querySelector('.column__title');
    const columnRow = columnRef.current.querySelector('.column__row');
    const columnTasks = columnRef.current.querySelectorAll('.column-task');

    const bodyHeight = columnBody.clientHeight - columnTitle.clientHeight;
    const combinedColumnTasksHeight = Array.from(columnTasks).reduce(
      (acc, el) => acc + el.clientHeight,
      0
    );
    const isTasksHeightBiggerThanBodyHeight =
      combinedColumnTasksHeight + (isAbleToAddTasks ? columnRow.clientHeight : 0) > bodyHeight;

    if (isOverflowNeeded !== isTasksHeightBiggerThanBodyHeight) {
      setIsOverflowNeeded(isTasksHeightBiggerThanBodyHeight);
    }
    // eslint-disable-next-line
  }, [tasks]);

  return (
    <div
      ref={columnRef}
      className={clsx('column', className, {
        'column--while-task-dragged': draggingTask.id,
        'column--drop-enabled': draggingTask.id && !isDropDisabled,
        'column--overflowed': isOverflowNeeded,
      })}
    >
      <div className={clsx('column__title', { 'column__title--active': isToday })}>
        <span onClick={goToToday}>{changeFormat(columnDate, 'DD.MM.YYYY', 'MMM, ddd DD')}</span>
      </div>

      <DroppableWrapper
        droppableId={columnDate}
        isDropDisabled={isDropDisabled}
        className="column__body"
      >
        {tasks.map((item, idx) => (
          <TasksColumnItem
            key={item._id + idx}
            index={idx}
            item={item}
            columnDate={columnDate}
            isTransferred={columnDate !== item.dueDate}
          />
        ))}
        {isAbleToAddTasks && (
          <TasksColumnRow
            id={`${columnDate}_add-task`}
            index={tasks.length}
            text={`+ ${t('addTask')}`}
            onClick={() => onOpenTask({ type: 'new', dueDate: columnDate })}
          />
        )}
        {emptyRows.map((key, idx) => (
          <TasksColumnRow
            key={key}
            id={key.toString()}
            index={tasks.length + idx + (isAbleToAddTasks ? 1 : 0)}
          />
        ))}
      </DroppableWrapper>
    </div>
  );
};

export default TasksColumn;
