import React, { useRef, useState } from 'react';
import { Input, Progress } from 'antd';
import { useTranslation } from 'react-i18next';

import Item from './ChecklistItem';

import { Checklist } from 'types/global';

import { ReactComponent as XIcon } from 'assets/icons/close.svg';

type Props = {
  checklistObj: Partial<Checklist>;
  onChangeChecklist?: (val: Partial<Checklist>) => void;
  isFullyControlable?: boolean;
};

const ChecklistWrapper: React.FC<Props> = ({
  checklistObj = null,
  onChangeChecklist,
  isFullyControlable = true,
}) => {
  const [t] = useTranslation();
  const addInputRef = useRef<Input>(null);

  const [addText, setAddText] = useState<string>('');

  const percentProgress: number =
    checklistObj && checklistObj.items
      ? Math.round(
          (100 / checklistObj.items.length) * checklistObj.items.filter(item => item.isChecked).length
        )
      : 0;

  const addItem = () => {
    if (addText === '') return;

    const items = checklistObj.items || [];
    const itemsNew = [...items, { title: addText, isChecked: false }];
    onChangeChecklist({ ...checklistObj, items: itemsNew });
    setAddText('');
  };

  const updateItem = async (index: number) => {
    const items = checklistObj.items || [];
    const itemsNew = items.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          isChecked: !item.isChecked,
        };
      }
      return item;
    });
    onChangeChecklist({ ...checklistObj, items: itemsNew });
  };

  const deleteItem = async (index: number) => {
    const items = checklistObj.items || [];
    const itemsNew = items.filter((_, i) => i !== index);
    onChangeChecklist({ ...checklistObj, items: itemsNew });
  };

  const onClickAddButton = () => {
    onChangeChecklist({ name: 'Check list', items: [] });
    setImmediate(() => {
      addInputRef.current.focus();
    });
  };

  return checklistObj ? (
    <div className="checklist">
      <div className="checklist__head">
        <div className="checklist__name">
          <span>{t('checklist')}</span>
          {isFullyControlable && (
            <XIcon className="checklist__item-remove" onClick={() => onChangeChecklist(undefined)} />
          )}
        </div>
        <Progress
          percent={percentProgress}
          trailColor="#CDD4D9"
          strokeColor="#69C262"
          className="checklist__progress"
          format={pc => `${pc}%`}
        />
      </div>

      {isFullyControlable && (
        <Input
          ref={addInputRef}
          placeholder={t('checklistPlaceholder')}
          className="checklist__input"
          value={addText}
          onChange={e => setAddText(e.target.value)}
          onPressEnter={addItem}
        />
      )}

      <div className="checklist__list">
        {checklistObj.items.map((item, index) => (
          <Item
            key={index}
            item={item}
            onUpdateItem={() => updateItem(index)}
            onDeleteItem={() => deleteItem(index)}
          />
        ))}
      </div>
    </div>
  ) : (
    <div className="checklist__add" onClick={onClickAddButton}>
      + {t('addChecklist')}
    </div>
  );
};

export default ChecklistWrapper;
