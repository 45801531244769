import React from 'react';

import DraggableWrapper from 'components/DndWrappers/Draggable';

type Props = {
  id: string;
  index: number;
  text?: string;
  onClick?: () => void;
};

const TasksColumnRow: React.FC<Props> = ({ id, index, text, onClick }) => (
  <DraggableWrapper draggableId={id} index={index} isDragDisabled className="column__row">
    {text ? (
      <span className="column__row-text" onClick={onClick}>
        {text}
      </span>
    ) : null}
  </DraggableWrapper>
);

export default TasksColumnRow;
