import moment from 'moment';

import {
  setTasks,
  updateTask,
  addTask,
  deleteTask,
  setHoldedTasks,
  updateHoldedTask,
  addHoldedTask,
  deleteHoldedTask,
} from 'store/tasks';
import { closeBacklog, closeTask, openTask } from 'store/core';

import apiClient from 'utils/feathersClient';
import { getWeek, getDay } from 'utils/date';
import { getNewPosition } from 'utils';

import { TasksItem } from 'types/global';
import { AppThunk, PromiseThunk } from 'types/store/common';
import { ChangeTasksPositionArgs, ChangeHoldedTasksPositionArgs } from 'types/store/tasks';
import { Paginated } from '@feathersjs/feathers';

export const fetchWeekTasks = (currentWeek: number): PromiseThunk<TasksItem[]> => async dispatch => {
  if (!currentWeek) return;

  const week = getWeek('current', currentWeek);
  const [startOfWeek, , , , , , endOfWeek] = week;
  const weekDays = week.map(day => day.format('DD.MM.YYYY'));

  const query = {
    $or: [{ dueDate: { $gte: startOfWeek, $lte: endOfWeek } }, { dueDateHistory: { $in: weekDays } }],
    status: { $ne: 'hold' },
    $limit: 100,
  };
  const { data: tasks }: Paginated<TasksItem> = await apiClient.service('tasks').find({ query });
  dispatch(setTasks(tasks));

  return tasks;
};

export const fetchDayTasks = (day: number): PromiseThunk<TasksItem[]> => async dispatch => {
  if (!day) return;

  const currentDay = getDay('current', day);
  const dayTimestamp = moment(day).format('DD.MM.YYYY');

  const query = {
    $or: [
      { dueDate: { $gte: currentDay, $lte: currentDay } },
      { dueDateHistory: { $in: [dayTimestamp] } },
    ],
    status: { $ne: 'hold' },
    $limit: 100,
  };
  const { data: tasks }: Paginated<TasksItem> = await apiClient.service('tasks').find({ query });
  dispatch(setTasks(tasks));

  return tasks;
};

export const fetchHoldedTasks = (): PromiseThunk<TasksItem[]> => async dispatch => {
  const query = {
    $limit: -1,
    //@ts-ignore
    dueDate: null
  };
  const tasks: TasksItem[] = await apiClient.service('tasks').find({ query });
  dispatch(setHoldedTasks(tasks));

  return tasks;
};

export const createTask = (
  values: Partial<TasksItem>,
  closeOnDone: boolean = true
): PromiseThunk<TasksItem> => async dispatch => {

  if (values.dueTime && values.dueTime.length === 5) {
    values.notificationTime = moment(`${values.dueDate} ${values.dueTime}`, 'DD.MM.YYYY HH:mm').toDate()
  }

  const task: TasksItem = await apiClient.service('tasks').create(values);
  if (!task.dueDate) {
    dispatch(addHoldedTask(task));
    if (closeOnDone) dispatch(closeBacklog());
  } else {
    dispatch(addTask(task));
    if (closeOnDone) dispatch(closeTask());
  }

  return task;
};

export const patchTask = (
  id: string,
  values: Partial<TasksItem>,
  closeOnDone: boolean = true
): PromiseThunk<TasksItem> => async dispatch => {
  if (values.dueTime && values.dueTime.length === 5) {
    values.notificationTime = moment(`${values.dueDate} ${values.dueTime}`, 'DD.MM.YYYY HH:mm').toDate()
  }

  const task: TasksItem = await apiClient.service('tasks').patch(id, values);

  if (!task.dueDate) {
    dispatch(updateHoldedTask(task));
    if (closeOnDone) dispatch(closeBacklog());
  } else {
    dispatch(updateTask(task));
    if (closeOnDone) dispatch(closeTask());
  }

  return task;
};

export const removeTask = (
  id: string,
  closeOnDone: boolean = true
): PromiseThunk<TasksItem> => async dispatch => {
  const task: TasksItem = await apiClient.service('tasks').remove(id);
  if (!task.dueDate) {
    dispatch(deleteHoldedTask(task._id));
    if (closeOnDone) dispatch(closeBacklog());
  } else {
    dispatch(deleteTask(task._id));
    if (closeOnDone) dispatch(closeTask());
  }

  return task;
};

export const changeTaskPosition = ({
  taskId,
  isHolded = false,
  destinationColumnDate,
  destinationIndex,
  sourceIndex,
  type,
  extraValuesForChange = {},
}: ChangeTasksPositionArgs): AppThunk => async (dispatch, getState) => {
  const allTasks = getState().tasks.allTasks;
  const holdedTasks = getState().tasks.holdedTasks;
  const currentTask = isHolded
    ? holdedTasks.find(el => el._id === taskId)
    : allTasks.find(el => el._id === taskId);
  const columnTasks = allTasks.filter(el => el.dueDate === destinationColumnDate);

  const newTaskPosition = getNewPosition({
    items: columnTasks,
    currentItem: currentTask,
    type,
    destinationIndex,
    sourceIndex,
  });

  const values = {
    ...currentTask,
    ...extraValuesForChange,
    position: newTaskPosition,
    dueDate: type === 'move' ? destinationColumnDate : currentTask.dueDate,
    dueDateHistory:
      type === 'move'
        ? currentTask.dueDateHistory.concat(destinationColumnDate)
        : currentTask.dueDateHistory,
  };

  // :: with positive attitude and smile on the face
  if (!isHolded) {
    dispatch(updateTask(values));
  }

  await apiClient.service('tasks').patch(taskId, values);
  if (isHolded) {
    dispatch(deleteHoldedTask(values._id));
    dispatch(addTask(values));
    dispatch(openTask({ type: 'edit', _id: taskId }));
  }
};

export const changeHoldedTaskPosition = ({
  taskId,
  destinationIndex,
  sourceIndex,
}: ChangeHoldedTasksPositionArgs): AppThunk => async (dispatch, getState) => {
  const holdedTasks = getState().tasks.holdedTasks;
  const currentTask = holdedTasks.find(el => el._id === taskId);

  const newPosition = getNewPosition({
    items: holdedTasks,
    currentItem: currentTask,
    destinationIndex,
    sourceIndex,
    type: 'reorder',
  });

  const values = { ...currentTask, position: newPosition };
  dispatch(updateHoldedTask(values));

  await apiClient.service('tasks').patch(taskId, values);
};
