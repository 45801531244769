import React, { useState } from 'react';
import { Picker, BaseEmoji, PickerProps } from 'emoji-mart';
import useOnclickOutside from 'react-cool-onclickoutside';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { GoalsItem } from 'types/global';

import 'emoji-mart/css/emoji-mart.css';
import { ReactComponent as CaretIcon } from 'assets/icons/caret-up.svg';

type Props = PickerProps & {
  value: GoalsItem['emoji'];
  onChange: (val: GoalsItem['emoji']) => void;
  placeholder?: string;
};

const EmojiPicker: React.FC<Props> = ({ value, onChange, placeholder, ...rest }) => {
  const [t] = useTranslation();

  const [isPickerOpened, setIsPickerOpened] = useState<boolean>(false);

  const onPickEmoji = (e: BaseEmoji) => {
    onChange({ icon: e.native, name: e.name });
    setIsPickerOpened(false);
  };

  const ref = useOnclickOutside(
    () => {
      if (isPickerOpened) {
        setIsPickerOpened(false);
      }
    },
    { eventTypes: ['mouseup'] }
  );

  return (
    <div className="emoji-picker" ref={ref}>
      <div
        className={clsx('emoji-picker__input', { 'emoji-picker__input--focused': isPickerOpened })}
        onClick={() => setIsPickerOpened(v => !v)}
      >
        {value ? (
          <span className="emoji-picker__input-value">
            {value.icon} {value.name}
          </span>
        ) : (
          <span className="emoji-picker__input-placeholder">{placeholder || t('emoji')}</span>
        )}
        <CaretIcon className="emoji-picker__input-icon" />
      </div>
      {isPickerOpened && (
        <Picker
          {...rest}
          exclude={['recent']}
          showSkinTones={false}
          showPreview={false}
          onSelect={(e: BaseEmoji) => onPickEmoji(e)}
        />
      )}
    </div>
  );
};

export default EmojiPicker;
