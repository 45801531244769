import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateState } from 'types/store/date';

import { getDay, getWeek } from 'utils/date';

const initialState: DateState = {
  currentWeek: [],
  currentDay: '',
  currentPage: 'week',
};

const dateSlice = createSlice({
  name: 'currentWeek',
  initialState,
  reducers: {
    setCurrentWeek: (state, { payload }: PayloadAction<number>) => {
      const week = getWeek('current', payload).map(day => day.format('DD.MM.YYYY'));
      state.currentWeek = week;
    },
    setCurrentDay: (state, { payload }: PayloadAction<number>) => {
      const currentDay = getDay('current', payload).format('DD.MM.YYYY');
      state.currentDay = currentDay;
    },
    setCurrentPage: (state, { payload }: PayloadAction<DateState['currentPage']>) => {
      state.currentPage = payload;
    },
  },
});

export const { setCurrentWeek, setCurrentDay, setCurrentPage } = dateSlice.actions;

export default dateSlice.reducer;
