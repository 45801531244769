import React, { useEffect, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from 'store';
import { Button } from 'antd';
import moment from 'moment';
import clsx from 'clsx';

import DroppableWrapper from 'components/DndWrappers/Droppable';
import DraggableWrapper from 'components/DndWrappers/Draggable';
import LoadingMask from 'components/LoadingMask';

import { openBacklog, setIsDrawerOpened } from 'store/core';
import { fetchHoldedTasks } from 'store/tasks/thunkActions';
import { getHoldedTasks } from 'store/tasks/selectors';

import { OpenedBacklogState } from 'types/store/core';

import { ReactComponent as ThreeDotsIcon } from 'assets/icons/three-dots.svg';
import { useTranslation } from 'react-i18next';

const HoldedTasks = () => {
  const dispatch = useTypedDispatch();
  const [t] = useTranslation();

  const holdedTasks = useTypedSelector(getHoldedTasks);
  const draggingTask = useTypedSelector(state => state.core.draggingTask);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onFetchHoldedTasks = async () => {
    setIsLoading(true);
    await dispatch(fetchHoldedTasks());
    setIsLoading(false);
  };
  const onOpenBacklog = (payload: OpenedBacklogState) => {
    dispatch(setIsDrawerOpened(false));
    dispatch(openBacklog(payload));
  };

  useEffect(() => {
    onFetchHoldedTasks();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="tags">
        <LoadingMask isLoading={isLoading} zIndex={20} width={40} height={40} />

        <DroppableWrapper droppableId="holdedTasks" className="tags__body">
          {holdedTasks.map((item, index) => (
            <DraggableWrapper
              draggableId={item._id}
              index={index}
              className={clsx('tags__item', {
                'tags__item--while-dragged': draggingTask.index === index,
              })}
              key={item._id}
            >
              <div className="tags__color-borders">
                {item.tags
                  ?.filter(el => el.color)
                  .map(tag => (
                    <div key={tag._id} style={{ backgroundColor: tag.color }} />
                  ))}
              </div>
              <span className="tags__item-name" title={item.name}>
                {item.name}
              </span>
              <ThreeDotsIcon
                className="tags__item-dots"
                onClick={() => onOpenBacklog({ type: 'edit', _id: item._id })}
              />
            </DraggableWrapper>
          ))}
        </DroppableWrapper>

        <div className="tags__actions">
          <Button
            type="primary"
            className="tags__add-button"
            onClick={() => onOpenBacklog({ type: 'new', dueDate: moment().format('DD.MM.YYYY') })}
          >
            + {t('addBacklog')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HoldedTasks;
