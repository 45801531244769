import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';

type Props = {
  path: string;
};

const DownloadFile: React.FC<Props> = ({ path }) => {
  const installFile = async () => {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);

    axios({
      method: 'get',
      url: path, // blob url eg. blob:http://127.0.0.1:8000/e89c5d87-a634-4540-974c-30dc476825cc
      responseType: 'blob',
    }).then(response => {
      a.href = window.URL.createObjectURL(response.data);
      // Use download attribute to set set desired file name
      a.setAttribute('download', path);
      a.setAttribute('type', 'audio/opus');
      a.id = 'ignore-click';

      // Trigger the download by simulating click
      a.click();

      // Cleanup
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
    });
  };
  return <DownloadOutlined className="task-uploads__download" onClick={installFile} />;
};

export default DownloadFile;
