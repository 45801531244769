import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';

import apiClient from 'utils/feathersClient';

import { TagsItem } from 'types/global';
import { Paginated } from '@feathersjs/feathers';
import clsx from 'clsx';

type Props = {
  value: TagsItem[];
  placeholder?: string;
  className?: string;
  onChange: (val: TagsItem[]) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  isFromTaskView?: boolean;
};

const styles = {
  multiValueLabel: (styles1: any, { data }: { data: TagsItem }) => ({
    ...styles1,
    color: data.color,
  }),
};

const TagsSelect: React.FC<Props> = ({
  className,
  value,
  onChange,
  isFromTaskView = false,
  ...rest
}) => {
  const [tagsOptions, setTagsOptions] = useState<Partial<TagsItem>[]>([]);

  const fetchTags = async () => {
    const result: Paginated<TagsItem> = await apiClient.service('tags').find({
      query: {
        $limit: 50,
        isArchived: { $ne: true },
      },
    });
    setTagsOptions(result.data);
  };

  useEffect(() => {
    fetchTags();
  }, []);

  return (
    // @ts-ignore
    <CreatableSelect
      {...rest}
      value={value}
      onChange={onChange}
      isMulti
      className={clsx('react-select', className)}
      classNamePrefix="react-select"
      maxMenuHeight={250}
      options={tagsOptions}
      getOptionLabel={item => item.name}
      getOptionValue={item => item.name}
      getNewOptionData={inputValue =>
        tagsOptions.every(tag => tag.name !== inputValue) ? { name: inputValue } : false
      }
      // closeMenuOnSelect={false}
      isClearable={false}
      styles={isFromTaskView ? styles : {}}
    />
  );
};

export default TagsSelect;
