import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotesItem } from 'types/global';

const notesSlice = createSlice({
  name: 'notes',
  initialState: [] as NotesItem[],
  reducers: {
    setNotes: (state, { payload }: PayloadAction<NotesItem[]>) => {
      return payload;
    },

    updateNote: (state, { payload }: PayloadAction<NotesItem>) => {
      return state.map(note => {
        if (note._id === payload._id) {
          return payload;
        }
        return note;
      });
    },

    addNote: (state, { payload }: PayloadAction<NotesItem>) => {
      state.push(payload);
    },

    deleteNote: (state, { payload }: PayloadAction<string>) => {
      return state.filter(note => note._id !== payload);
    },
  },
});

export const { setNotes, updateNote, addNote, deleteNote } = notesSlice.actions;

export default notesSlice.reducer;
