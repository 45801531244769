import React, { useRef, useState } from 'react';
import { Modal } from 'antd';
import TextareaAutosize from 'react-textarea-autosize';

import { toStringFormat } from 'utils/date';

import { CommentsItem } from 'types/global';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as XIcon } from 'assets/icons/close.svg';
import useOnclickOutside from 'react-cool-onclickoutside';

type Props = {
  item: Partial<CommentsItem>;
  onPatchItem: (comment: Partial<CommentsItem>) => void;
  onRemoveItem: () => void;
};

const TaskCommentsItem: React.FC<Props> = ({ item, onPatchItem, onRemoveItem }) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  const openEditInput = () => {
    setInputValue(item.text);
    setIsEdit(true);
    setImmediate(() => inputRef.current.focus());
  };

  const handlePatchComment = () => {
    onPatchItem({ text: inputValue });
    setIsEdit(false);
    setInputValue('');
  };

  const onPressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      handlePatchComment();
    }
  };

  const openDeletePopup = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this comment?',
      centered: true,
      icon: null,
      okText: 'delete',
      cancelText: 'cancel',
      okButtonProps: { className: 'ant-btn-primary ant-btn-dangerous' },
      cancelButtonProps: { className: 'ant-btn-primary' },
      onOk: () => onRemoveItem(),
    });
  };

  const ref = useOnclickOutside(
    () => {
      if (isEdit) {
        setIsEdit(false);
        setInputValue(item.text);
      }
    },
    { eventTypes: ['mouseup'] }
  );

  return isEdit ? (
    <div ref={ref} className="task-comments__input-wrapper">
      <TextareaAutosize
        ref={inputRef}
        className="task-comments__edit-input"
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        onKeyDown={onPressEnter}
      />
      <EditIcon className="task-comments__edit-suffix" onClick={handlePatchComment} />
    </div>
  ) : (
    <div className="task-comments__item">
      <div className="task-comments__item__left">
        <div className="task-comments__item__date">{toStringFormat(item.createdAt, 'DD.MM.YYYY')}</div>
        <div className="task-comments__item__text">{item.text}</div>
      </div>
      <div className="task-comments__item__right">
        <XIcon className="task-comments__item__remove-icon" onClick={openDeletePopup} />
        <EditIcon className="task-comments__item__edit-icon" onClick={openEditInput} />
      </div>
    </div>
  );
};

export default TaskCommentsItem;
