import React, { ReactElement, useEffect } from 'react';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { useTypedSelector } from 'store';
import { getIsMobileScreen } from 'store/core/selectors';

type Props = {
  component: ReactElement;
  onClose: () => void;
  zIndex?: number;
};

const Modal: React.FC<Props> = ({ component, onClose, zIndex = 570 }) => {
  const isMobileScreen = useTypedSelector(state => getIsMobileScreen(state));

  useEffect(() => {
    const onPressEsc = (e: KeyboardEvent) => {
      const pressedKey = e.key;
      if (pressedKey === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keyup', onPressEsc);
    return () => {
      window.removeEventListener('keyup', onPressEsc);
    };
  }, [onClose]);

  return (
    <div
      className="modal"
      style={{ zIndex }}
      onMouseDown={isMobileScreen ? null : onClose}
      onClick={isMobileScreen ? onClose : null}
    >
      <div
        className="modal__wrapper"
        onMouseDown={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
      >
        <div className="modal__body">{component}</div>
        <CloseIcon className="modal__close" onMouseDown={onClose} />
      </div>
    </div>
  );
};

export default Modal;
