import { Modal, Upload } from 'antd';
import React, { useMemo, useState } from 'react';
import { useTypedSelector } from 'store';
import axios from 'axios';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import DownloadFile from './DownloadFile';

import { UploadFile } from 'antd/lib/upload/interface';
import { UploadsItem } from 'types/global';

import { ReactComponent as XIcon } from 'assets/icons/close.svg';

type Props = {
  items: UploadsItem[];
  onChange: (items: UploadsItem[]) => void;
};

const TaskUploads: React.FC<Props> = ({ items, onChange }) => {
  const [t] = useTranslation();

  const token = useTypedSelector(state => state.authUser.token);

  const [openedFile, setOpenedFile] = useState<Partial<UploadFile>>({});
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const files = useMemo(() => {
    return items
      ? items.map(file => ({
          uid: file._id,
          name: file.originalname,
          size: 50,
          type: file.mimetype,
          url: file.path,
        }))
      : [];
  }, [items]);

  const uploadFile = async (fileInfo: any) => {
    const file = fileInfo.file;
    const data = new FormData();
    data.append('file', file);

    try {
      const response = (
        await axios({
          method: 'post',
          url: process.env.REACT_APP_FILE_URL,
          data,
          headers: { Authorization: `Bearer ${token}` },
        })
      ).data[0];

      onChange([...items, response]);
    } catch (e) {
      console.error('error in uploading', e);
    }
  };

  const onRemove = (file: UploadFile) => {
    const newFiles = items.filter(item => item._id !== file.uid);
    onChange(newFiles);
  };

  const onOpenModal = (file: UploadFile) => {
    setIsModalVisible(true);
    setOpenedFile(file);
  };

  const onCloseModal = () => {
    setIsModalVisible(false);
    setTimeout(() => {
      setOpenedFile({});
    }, 300);
  };

  return (
    <div className="task-uploads">
      <h2 className="task-uploads__title">{t('attachments')}</h2>

      <Upload
        customRequest={uploadFile}
        listType="picture-card"
        fileList={files}
        onRemove={onRemove}
        onPreview={onOpenModal}
      >
        {items?.length >= 5 ? null : (
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>{t('upload')}</div>
          </div>
        )}
      </Upload>

      <Modal
        className="task-uploads__modal"
        visible={isModalVisible}
        title={
          <div className="task-uploads__modal-title">
            {openedFile.name}
            <DownloadFile path={openedFile.url} />
          </div>
        }
        footer={null}
        closeIcon={<XIcon />}
        onCancel={onCloseModal}
      >
        {openedFile.type?.includes('image') ? (
          <img alt={openedFile.name} src={openedFile.url} width="100%" height="100%" />
        ) : openedFile.type?.includes('video') ? (
          <video src={openedFile.url} width="100%" height="100%" controls />
        ) : (
          <span className="task-uploads__modal-no">{t('noPreview')}</span>
        )}
      </Modal>
    </div>
  );
};

export default TaskUploads;
