const langKey = 'language';

export function getLanguage() {
  const value = localStorage.getItem(langKey);
  return value === 'undefined' ? undefined : value;
}

export function setLanguage(language: string) {
  return localStorage.setItem(langKey, language);
}

export function removeLanguage() {
  return localStorage.removeItem(langKey);
}
