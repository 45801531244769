import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { ReactComponent as CheckIcon } from 'assets/icons/check-mark.svg';

const CheckVerify: React.FC<{}> = () => {
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <div className="auth-form auth-form--check-verify">
      <CheckIcon className="auth-form__check-icon" />
      <div className="auth-form__title">
        {t('checkMail')}!
        <br />
        {t('thereIsLink')}
      </div>

      <Button className="auth-form__button" type="primary" onClick={() => history.push('/login')}>
        {t('backToLogin')}
      </Button>
    </div>
  );
};

export default CheckVerify;
