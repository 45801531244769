import { createSelector } from 'reselect';

import { RootState } from 'types/store/common';

export const getSortedGoals = createSelector(
  (state: RootState) => state.goals,
  goals =>
    goals.slice().sort((a, b) => {
      return a.position - b.position;
    })
);

export const getActiveGoal = createSelector(
  (state: RootState) => state.goals,
  goals =>
    goals.slice().sort((a, b) => {
      return a.position - b.position;
    }).filter(goal => goal.status !== 'done'),
);
