import React, { useEffect } from 'react';
import { DragDropContext, DragStart, DropResult } from 'react-beautiful-dnd';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from 'store';
import clsx from 'clsx';
import { message } from 'antd';

import Sidebar from './Sidebar';
import Header from './Header';
import Modals from 'layouts/Main/Modals';
import MobileHeader from 'components/Mobile/Header';

import { openTask, setDraggingTask, resetDraggingTask } from 'store/core';
import { setCurrentDay, setCurrentWeek, setCurrentPage } from 'store/date';
import { changeTaskPosition, changeHoldedTaskPosition, createTask } from 'store/tasks/thunkActions';
import { fetchStats } from 'store/stats/thunkActions';
import { getIsMobileScreen } from 'store/core/selectors';

import { DraggingTaskState, OpenedTaskState } from 'types/store/core';
import { ChangeHoldedTasksPositionArgs, ChangeTasksPositionArgs } from 'types/store/tasks';
import { changeGoalPosition } from 'store/goals/thunkActions';
import { ChangeGoalPositionArgs } from 'types/store/goals';
import { TasksItem } from 'types/global';
import { removeNote } from 'store/notes/thunkActions';
import { subscribeToNotifications } from 'utils/pushNotifications';
import { DateState } from 'types/store/date';
// import apiClient from 'utils/feathersClient';

type Props = {
  children: React.ReactNode;
};

const MainLayout: React.FC<Props> = ({ children }) => {
  const {
    params: { dayInMs },
  } = useRouteMatch<{ dayInMs: string }>();
  const { pathname } = useLocation();
  const dispatch = useTypedDispatch();

  const isSidebarCollapsed = useTypedSelector(state => state.core.isSidebarCollapsed);
  const isMobileScreen = useTypedSelector(state => getIsMobileScreen(state));

  const onSetDraggingTask = (payload: DraggingTaskState) => dispatch(setDraggingTask(payload));
  const onResetDraggingTask = () => dispatch(resetDraggingTask());
  const onCreateTask = (payload: Partial<TasksItem>) => dispatch(createTask(payload));
  const onRemoveNote = (id: string) => dispatch(removeNote(id));
  const onOpenTask = (payload: OpenedTaskState) => dispatch(openTask(payload));
  const onChangeTaskPosition = (payload: ChangeTasksPositionArgs) =>
    dispatch(changeTaskPosition(payload));
  const onChangeHoldedTaskPosition = (payload: ChangeHoldedTasksPositionArgs) =>
    dispatch(changeHoldedTaskPosition(payload));
  const onChangeGoalPosition = (payload: ChangeGoalPositionArgs) =>
    dispatch(changeGoalPosition(payload));

  const onDragStart = (start: DragStart) => {
    const { draggableId, source, type } = start;
    if (type === 'GOALS') return;

    onSetDraggingTask({
      id: draggableId,
      index: source.index,
      droppableId: source.droppableId,
    });
  };

  const onDragEnd = async (result: DropResult) => {
    onResetDraggingTask();

    const { draggableId, destination, source } = result;

    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    if (result.type === 'GOALS') {
      try {
        await onChangeGoalPosition({
          goalId: draggableId,
          destinationIndex: destination.index,
          sourceIndex: source.index,
        });
      } catch (e) {
        message.error("Sorry, you can't reposition old goals, delete them and create new ones", 7);
      }
      return;
    }

    if (source.droppableId === 'notes') {
      if (destination.droppableId === 'holdedTasks') {
        message.error("No, you can't", 4);
        return;
      }

      const [noteId, initialName] = draggableId.split('-');
      const createdTask = await onCreateTask({ name: initialName, dueDate: destination.droppableId });
      onRemoveNote(noteId);
      onOpenTask({ type: 'edit', _id: createdTask._id });
      return;
    }

    if (source.droppableId === 'holdedTasks' && destination.droppableId === 'holdedTasks') {
      await onChangeHoldedTaskPosition({
        taskId: draggableId,
        destinationIndex: destination.index,
        sourceIndex: source.index,
      });
    } else {
      if (destination.droppableId === 'holdedTasks') {
        message.error("No, you can't", 4);
        return;
      }

      await onChangeTaskPosition({
        taskId: draggableId,
        destinationColumnDate: destination.droppableId,
        destinationIndex: destination.index,
        sourceIndex: source.index,
        type: destination.droppableId === source.droppableId ? 'reorder' : 'move',
        isHolded: source.droppableId === 'holdedTasks',
        extraValuesForChange:
          source.droppableId === 'holdedTasks' && destination.droppableId !== 'holdedTasks'
            ? { status: 'wait' }
            : {},
      });
    }
    fetchStats(+dayInMs);
  };

  useEffect(() => {
    const currentPage = pathname.split('/')[1] as DateState['currentPage'];

    dispatch(setCurrentWeek(+dayInMs));
    dispatch(setCurrentDay(+dayInMs));
    dispatch(setCurrentPage(currentPage));
    dispatch(fetchStats(+dayInMs));

    // eslint-disable-next-line
  }, [dayInMs]);

  useEffect(() => {
    setTimeout(subscribeToNotifications, 2000);
  }, []);

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      {!isMobileScreen ? (
        <>
          <Header />
          <Sidebar />
        </>
      ) : (
        <MobileHeader />
      )}
      <div className={clsx('main', { 'main--fullsize': isSidebarCollapsed })}>
        <div className="main__wrapper">{children}</div>
      </div>

      <Modals />
    </DragDropContext>
  );
};

export default MainLayout;
