import React, { useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { Button, Input, Row, Switch, Modal, Col, DatePicker } from 'antd';
import { useTypedDispatch, useTypedSelector } from 'store';
import moment from 'moment';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';

import QuickSelectBadge from 'components/UI/QuickSelectBadge';
import FieldWithValidation from 'components/FieldWithValidation';
import LoadingMask from 'components/LoadingMask';

import apiClient from 'utils/feathersClient';

import { createNote, patchNote, removeNote } from 'store/notes/thunkActions';
import { closeNote } from 'store/core';
import { getIsMobileScreen } from 'store/core/selectors';

import { NotesItem } from 'types/global';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/check-mark.svg';
import Checklist from 'components/Checklist';

const GoalForm = () => {
  const dispatch = useTypedDispatch();
  const [t] = useTranslation();

  const { _id: id } = useTypedSelector(state => state.core.openedNote);
  const isMobileScreen = useTypedSelector(state => getIsMobileScreen(state));

  const [note, setNote] = useState<Partial<NotesItem>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isEdit: boolean = Boolean(id) || Boolean(note._id);

  const onCreateNote = (values: Partial<NotesItem>) => dispatch(createNote(values));
  const onPatchNote = (values: Partial<NotesItem>) => dispatch(patchNote(id, values));
  const onRemoveNote = () => dispatch(removeNote(id));
  const onCloseNote = () => dispatch(closeNote());

  const openDeletePopup = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete it?',
      centered: true,
      okText: 'delete',
      cancelText: 'cancel',
      okButtonProps: { className: 'ant-btn-primary ant-btn-dangerous' },
      cancelButtonProps: { className: 'ant-btn-primary' },
      onOk: onRemoveNote,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result: NotesItem = await apiClient.service('notes').get(id);
      setNote(result);
      setIsLoading(false);
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  return (
    <Form
      onSubmit={isEdit ? onPatchNote : onCreateNote}
      initialValues={note}
      render={({ handleSubmit, values, valid, form: { change } }) => (
        <div className="note-form">
          <LoadingMask isLoading={isLoading} zIndex={100} />

          <div className="note-form__head">
            <h2 className="form-title">{isEdit ? t('editNote') : t('createNote')}</h2>
          </div>

          <div className="note-form__body">
            <Row className="form-margin">
              <Field name="title" validate={val => !val}>
                {({ input, meta }) => (
                  <FieldWithValidation meta={meta}>
                    <Input {...input} placeholder={t('title')} />
                  </FieldWithValidation>
                )}
              </Field>
            </Row>

            <Row className="form-margin">
              <Field name="checklist" format={v => v}>
                {({ input }) => (
                  <Checklist checklistObj={input.value} onChangeChecklist={input.onChange} />
                )}
              </Field>
            </Row>

            <Row className="form-margin" align="middle">
              <Col span={12}>
                <div className="note-form__remind">
                  <Field name="isReminded">
                    {({ input }) => <Switch checked={input.value} onChange={input.onChange} />}
                  </Field>
                  <span className="note-form__remind-text">{t('remindMe')}</span>
                </div>
              </Col>
              <Col span={12} className="note-form__check-wrapper">
                <CheckMarkIcon
                  className={clsx('note-form__check-mark', {
                    'note-form__check-mark--done': values.status === 'done',
                  })}
                  onClick={() => change('status', values.status === 'done' ? 'active' : 'done')}
                />
              </Col>
            </Row>

            {values.isReminded && (
              <Row className="form-margin" gutter={{ xs: 14, md: 28 }}>
                <Col span={12}>
                  <Field
                    name="dueDate"
                    allowNull
                    format={val => (val ? moment(val, 'DD.MM.YYYY') : null)}
                    parse={val => (val ? moment(val).format('DD.MM.YYYY') : null)}
                  >
                    {({ input }) => (
                      <DatePicker
                        {...input}
                        placeholder={t('date')}
                        dropdownClassName="calendar-picker"
                        showToday={false}
                        mode="date"
                        suffixIcon={<CalendarIcon />}
                        inputReadOnly={isMobileScreen}
                      />
                    )}
                  </Field>
                  <Row className="mt10">
                    <QuickSelectBadge
                      className="mr5"
                      isActive={values.dueDate === moment().format('DD.MM.YYYY')}
                      onClick={() => change('dueDate', moment().format('DD.MM.YYYY'))}
                    >
                      {t('today')}
                    </QuickSelectBadge>
                    <QuickSelectBadge
                      className="mr5"
                      isActive={values.dueDate === moment().add(1, 'day').format('DD.MM.YYYY')}
                      onClick={() => change('dueDate', moment().add(1, 'day').format('DD.MM.YYYY'))}
                    >
                      {t('tomorrow')}
                    </QuickSelectBadge>
                    <QuickSelectBadge
                      isActive={values.dueDate === moment().add(5, 'day').format('DD.MM.YYYY')}
                      onClick={() => change('dueDate', moment().add(5, 'day').format('DD.MM.YYYY'))}
                    >
                      {moment().add(5, 'day').format('DD MMM')}
                    </QuickSelectBadge>
                  </Row>
                </Col>
                <Col span={12}>
                  <Field name="dueTime" validate={v => !!v && !moment(v, 'HH:mm', true).isValid()}>
                    {({ input, meta }) => (
                      <FieldWithValidation meta={meta}>
                        <InputMask mask="99:99" {...input}>
                          {(inputProps: any) => (
                            <Input {...inputProps} placeholder={t('time')} suffix={<ClockIcon />} />
                          )}
                        </InputMask>
                      </FieldWithValidation>
                    )}
                  </Field>

                  <Row className="mt10">
                    <QuickSelectBadge
                      className="mr5"
                      isActive={values.dueTime === '12:00'}
                      onClick={() => change('dueTime', '12:00')}
                    >
                      12:00
                    </QuickSelectBadge>
                    <QuickSelectBadge
                      className="mr5"
                      isActive={values.dueTime === '15:00'}
                      onClick={() => change('dueTime', '15:00')}
                    >
                      15:00
                    </QuickSelectBadge>
                    <QuickSelectBadge
                      isActive={values.dueTime === '18:00'}
                      onClick={() => change('dueTime', '18:00')}
                    >
                      18:00
                    </QuickSelectBadge>
                  </Row>
                </Col>
              </Row>
            )}

            {isEdit ? (
              <Row justify="center" className="form-buttons-row">
                <Button className="mr20 form-button" danger type="primary" onClick={openDeletePopup}>
                  {t('delete')}
                </Button>
                <Button className="form-button" type="primary" disabled={!valid} onClick={handleSubmit}>
                  {t('edit')}
                </Button>
              </Row>
            ) : (
              <Row justify="center" className="form-buttons-row">
                <Button className="mr20 form-button" danger type="primary" onClick={onCloseNote}>
                  {t('cancel')}
                </Button>
                <Button className="form-button" type="primary" disabled={!valid} onClick={handleSubmit}>
                  {t('create')}
                </Button>
              </Row>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default GoalForm;
